import React from 'react';
import ReactTable from '../../components/ReactTable/tablev8';
import NoDataAvailable from '../../components/Loader/NoDataAvailable';
const FilteredTransactions = ({ filterBy, data, column, excelFilename }) => {
  let transaction = filterBy
    ? data.filter(transaction => transaction.transactionType === filterBy)
    : data;
  return (
    <>
      {transaction?.length > 0 ? (
        <ReactTable
          TableUtility={{
            search: true,
            pagination: true,
            excel: true,
            h: ['40vh', '52vh', '64vh', '65vh', '65vh', '67vh'],
            excelFilename:
              excelFilename ?? 'Quixgo_All_TransactionInfo_List.csv',
          }}
          columns={column()}
          data={transaction}
        />
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
};

export default FilteredTransactions;
