import {
  CategorySelect,
  CustomSelect,
  SearchInCategory,
} from '../../components/CustomTable/CustomFilterComponent';
import DateRangePickerComponent from '../../components/CustomTable/DateRangePickerComponent';
import { ActionButton } from '../Shipment/ShipmentColumns/ActionButton';
import {
  Deliv_To,
  CurrentStatus,
  TrackingDetails,
  BookingChannelDetails,
  Payment,
  PackgaeDetails,
  BookingDate,
  updatedAtDate,
  CustomerIdDetails,
} from './B2cAllshipmentListColumnData';

export const TableHeader = [
  { label: 'Deliv To', sortByTerm: 'customerName' },
  { label: 'Status', sortByTerm: 'currentStatus' },
  { label: 'TRACKER', sortByTerm: 'shipmentPartner' },
  { label: 'CHANNEL', sortByTerm: 'bookingChannel' },
  { label: 'Payment', sortByTerm: 'paymentMode' },
  { label: 'Product_Details', sortByTerm: 'productDetails' },
  { label: 'Booking AT', sortByTerm: 'bookingDate' },
  { label: 'UPDATED AT', sortByTerm: 'updatedAt' },
  { label: 'CUSTOMER ID', sortByTerm: 'customerId' },
  { label: 'Action', sortByTerm: '' },
];
export const TableBody = [
  Deliv_To,
  CurrentStatus,
  TrackingDetails,
  BookingChannelDetails,
  Payment,
  PackgaeDetails,
  BookingDate,
  updatedAtDate,
  CustomerIdDetails,
  ActionButton,
];

export const isInsured = [
  { label: 'True', value: true, default: true },
  { label: 'False', value: false, default: false },
];
export const searchCategoryOption = [
  { label: 'awbNumber', value: 'awbNumber', default: true },
  { label: 'Order Id', value: 'orderId', default: false },
];
export const PaymentMode = [
  { label: 'COD', value: 'COD', default: false },
  { label: 'Prepaid', value: 'Prepaid', default: false },
];
export const ServiceMode = [
  { label: 'FORWARD', value: 'FORWARD', default: false },
  { label: 'Express', value: 'express', default: false },
];
export const CustomSelectOption = [
  {
    label: 'All',
    value: [],
    default: true,
  },
  {
    label: 'booked',
    value: ['booked', 'manifested', 'Out For Pickup'],
    default: true,
  },
  {
    label: 'Out For Delivery',
    value: ['Out For Delivery', 'out for delivery', 'dispatched'],
    default: true,
  },

  {
    label: 'In Transit',
    value: ['return in transit', 'In Transit'],
    default: true,
  },
  {
    label: 'Return',
    value: ['return Delivered', 'RTD', 'return Booked', 'rto_received'],
    default: true,
  },
  {
    label: 'Cancelled',
    value: ['canceled', 'cancelled'],
    default: true,
  },
  {
    label: 'Delivered',
    value: ['delivered'],
    default: true,
  },
  {
    label: 'Reverse',
    value: ['reverse', 'sent_to_rev'],
    default: true,
  },
  {
    label: 'NDR',
    value: ['ndr', 'pending', '2405'],
    default: true,
  },
  {
    label: 'Error',
    value: ['error'],
    default: true,
  },
];
export const ServiceProviderList = [
  {
    label: 'XPRESSBEES',
    value: 'XBS',
  },
  {
    label: 'DELHIVERY',
    value: 'DLV',
  },
  {
    label: 'SHADOWFAX',
    value: 'SFX',
  },
  {
    label: 'MARUTI',
    value: 'SMC',
  },
  {
    label: 'EKART',
    value: 'EKT',
  },
  {
    label: 'ECOM',
    value: 'ECM',
  },
];
export const B2c_AllShipmetnList_Filter_Component = [
  {
    component: CategorySelect,
    props: {
      searchCategoryOption: searchCategoryOption,
    },
  },
  {
    component: SearchInCategory,
  },

  {
    component: DateRangePickerComponent,
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: CustomSelectOption,
      placeholder: 'Select Status',
      valueType: 'currentStatus',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: ServiceProviderList,
      placeholder: 'Shipment Partner',
      valueType: 'shipmentPartne',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: isInsured,
      placeholder: 'Is Insured',
      valueType: 'isInsured',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: PaymentMode,
      placeholder: 'Payment Mode',
      valueType: 'paymentMode',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: ServiceMode,
      placeholder: 'Service Mode',
      valueType: 'serviceMode',
    },
  },
];
