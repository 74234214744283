import React, { useState, useEffect } from 'react';
import { useTable, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Input,
  Flex,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import { rankItem } from '@tanstack/match-sorter-utils';
import TableFoot from './TableFoot';
import Exceldata from '../../../Excel_Export_Data/Exceldata';
import {
  objectToFlattenObject,
  jsonStringToFlattenObject,
} from '../../../utils/utilsFunction';
import useDebounce from '../../../utils/Debaunce';
const ReactTable = ({
  TableUtility,
  columns,
  data,
  pages,
  hidePagination,
  startPageIndex,
  initialPageSize,
  onSelect,
  Unformatdata,
}) => {
  const JsonData = jsonStringToFlattenObject(data);
  const ObjData = Unformatdata ?? objectToFlattenObject(JsonData);
  const [inputValue, setInputValue] = useState('');
  const [searchData, setSearchData] = useState([ObjData]);
  const debouncedInputValue = useDebounce(inputValue, 1000);
  const [defaultColumns] = useState([...columns]);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([]);
  const pageSizeCurrent = pages || [10, 20, 50, 100];
  const [globalFilter, setGlobalFilter] = useState('');

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const filteredData = ObjData?.filter(item => {
      for (const key in item) {
        const value = item[key];
        if (
          typeof value === 'string' &&
          value.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          return true;
        }
        if (
          typeof value === 'number' &&
          value.toString().includes(inputValue)
        ) {
          return true;
        }
      }
      return false;
    });

    setSearchData([...filteredData]);
  }, [debouncedInputValue]);

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };
  //-----------------------
  const rerender = React.useReducer(() => ({}), {})[1];
  useEffect(() => {
    rerender();
    setSearchData(ObjData);
  }, [data]);
  useEffect(() => {
    if (onSelect) {
      const rows = [];
      Object.keys(rowSelection).forEach(row => {
        rows.push(data[row]);
      });
      onSelect(rows);
    }
  }, [rowSelection]);

  const tableInstance = useReactTable({
    data: searchData, //defaultData,
    columns: defaultColumns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },

    state: {
      globalFilter,
      sorting,
      rowSelection,
      pageIndex: startPageIndex || 0,
      pageSize: hidePagination ? data.length || initialPageSize || 10 : 10,
    },
    manualPagination: hidePagination || false,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    debugTable: true,
    useSticky,
    useBlockLayout,
  });
  return (
    <Box>
      <Flex
        gap={[2, 0]}
        justifyContent={['center', 'flex-end']}
        alignItems={['center']}
        flexDir={['column', 'column', 'row']}
        py={2}
      >
        <Flex placeItems={'center'}>
          {TableUtility?.excel && (
            <Box>
              <Exceldata data={data} filename={TableUtility?.excelFilename} />
            </Box>
          )}
          {TableUtility?.search && (
            <Box>
              <Input
                w={['full', 240]}
                placeholder="search..."
                value={inputValue}
                onChange={handleChange}
              />
            </Box>
          )}
        </Flex>

        {TableUtility?.pagination && (
          <TableFoot
            tableInstance={tableInstance}
            pageSizeCurrent={pageSizeCurrent}
            data={data}
          />
        )}
      </Flex>
      <TableContainer
        h={TableUtility?.h ? TableUtility?.h : ['calc(100vh - 180px)']}
        overflowY={'auto'}
        pb={10}
        w={
          TableUtility?.w
            ? TableUtility?.w
            : ['100vw', '100vw', '100vw', 'calc(100vw - 100px)']
        }
      >
        <Table variant="striped" colorScheme="gray" pos={'relative'}>
          <Thead bgColor="blue.500" color="whiteAlpha.400">
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id} pl="0" pr="0" pos={'relative'}>
                {headerGroup.headers.map(header => (
                  <Th
                    top={0}
                    key={header.id}
                    color="white"
                    // py={4}
                    textAlign="center"
                    pos={'sticky'}
                    bg={'#3182CE'}
                    zIndex={9}
                  >
                    {header.isPlaceholder ? null : (
                      <span
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <MdOutlineArrowDropDown />,
                          desc: <MdOutlineArrowDropUp />,
                        }[header.column.getIsSorted()] ?? null}
                      </span>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {tableInstance.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id} textAlign="center" p="2px 0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ReactTable;
