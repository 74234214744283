import React from 'react';
import { Flex, VStack } from '@chakra-ui/react';
import CustomTable from '../../components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllShipmentList,
  getShipmentLoader,
} from '../../redux/slices/ShipmentDetailsSlice';
import {
  B2c_AllShipmetnList_Filter_Component,
  TableBody,
  TableHeader,
} from './B2cAllshipmentList_Constant';
import Pagination from '../../components/CustomTable/Pagination';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import FilterContainer from '../../components/CustomTable/FilterContainer';
const B2cAllshipmentList = ({ CustomerType }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const AllshipmentListData = useSelector(getAllShipmentList);
  const isShipmentListLoading = useSelector(getShipmentLoader);
  const { count, rows } = AllshipmentListData?.[0] || {};
  const getData = (limit, page, filterFormData) => {
    dispatch({
      type: 'GET_ALL_SHIPMENT_LIST',
      CustomerType: CustomerType,
      payload: {
        empId: loggedUser?.empid,
        limit: limit,
        page: page,
        filter: filterFormData,
      },
    });
  };
  return (
    <VStack gap={2} p={5}>
      <FilterContainer
        mainComponent={B2c_AllShipmetnList_Filter_Component}
        styleprops={{
          templateColumns: [
            'auto',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
          ],
        }}
      />
      <Flex w={'100%'} justifyContent={'flex-end'}>
        <Pagination
          paginationProps={{
            count: count,
            getData: getData,
          }}
        />
      </Flex>
      <CustomTable
        tableData={{
          PropsData: rows,
          Header: TableHeader,
          Body: TableBody,
          isDataLoading: isShipmentListLoading,
        }}
        styleprops={{
          w: ['80vw', '80vw', '80vw', '88vw', '90vw'],
          h: ['90vh', '50vh', '50vh', '55vh'],
          overflowY: 'auto',
        }}
      />
    </VStack>
  );
};

export default B2cAllshipmentList;
