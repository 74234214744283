import React from 'react';
import {
  Flex,
  Text,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import {
  BiSkipNext,
  BiSkipPrevious,
  BiCaretLeft,
  BiCaretRight,
} from 'react-icons/bi';
const TableFoot = ({ tableInstance, pageSizeCurrent, data }) => {
  const [isLargerThan800] = useMediaQuery('(min-width: 480px)');
  return (
    <>
      <Flex
        gap={5}
        flexDir={['column', 'row']}
        justifyContent={'flex-end'}
        minW={['100vw', '30rem']}
        align={'center'}
      >
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {!isLargerThan800 && (
            <Text fontSize={18} fontWeight="bold" flexShrink="0">
              item :{' '}
            </Text>
          )}
          <Select
            mx={2}
            w={'4.5rem'}
            h={'2rem'}
            value={tableInstance.getState().pagination.pageSize}
            onChange={e => {
              tableInstance.setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeCurrent.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
          <Text flexShrink="0">
            Out of{' '}
            <Text fontWeight="bold" as="span">
              {data.length}
            </Text>
          </Text>
        </Box>
        <Box>
          <HStack borderRadius={5}>
            <Tooltip label="First Page">
              <Box>
                <IconButton
                  variant="none"
                  p={0}
                  m={0}
                  fontSize="25px"
                  onClick={() => tableInstance.setPageIndex(0)}
                  isDisabled={!tableInstance.getCanPreviousPage()}
                  icon={<BiSkipPrevious />}
                  color={'black'}
                />
              </Box>
            </Tooltip>

            <Tooltip label="Previous Page">
              <Box>
                <IconButton
                  variant="none"
                  p={0}
                  m={0}
                  fontSize="25px"
                  onClick={() => tableInstance.previousPage()}
                  isDisabled={!tableInstance.getCanPreviousPage()}
                  icon={<BiCaretLeft />}
                  color={'black'}
                />
              </Box>
            </Tooltip>
            <NumberInput
              w={16}
              min={1}
              className={'page-number-input'}
              max={tableInstance.getPageCount()}
              onChange={value => {
                const page = value ? value - 1 : 0;
                tableInstance.setPageIndex(page);
              }}
              value={tableInstance.getState().pagination.pageIndex + 1}
              defaultValue={tableInstance.getState().pagination.pageIndex + 1}
            >
              <NumberInputField m={0} p={0} pl={5} minW={15} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text>
              of{' '}
              <Text fontWeight="bold" as="span">
                {tableInstance.getPageCount()}
              </Text>
            </Text>
            <Tooltip label="Next Page">
              <Box>
                <IconButton
                  variant="none"
                  p={0}
                  m={0}
                  fontSize="25px"
                  onClick={() => tableInstance.nextPage()}
                  isDisabled={!tableInstance.getCanNextPage()}
                  icon={<BiCaretRight />}
                  color={'black'}
                />
              </Box>
            </Tooltip>
            <Tooltip label="Last Page">
              <Box>
                <IconButton
                  variant="none"
                  p={0}
                  m={0}
                  fontSize="25px"
                  onClick={() =>
                    tableInstance.setPageIndex(tableInstance.getPageCount() - 1)
                  }
                  isDisabled={!tableInstance.getCanNextPage()}
                  icon={<BiSkipNext />}
                  color={'black'}
                />
              </Box>
            </Tooltip>
          </HStack>
        </Box>
      </Flex>
    </>
  );
};

export default TableFoot;
