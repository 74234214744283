/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';

import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { COURIER_SERVICE_LOGIN } from '../utils/constant';
import {
  setLogedUserDetails,
  getLoggedUser,
  getUserInfoLoader,
} from '../redux/slices/UserDetailsSlice';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

export default function LoginSplitScreen() {
  const dispatch = useDispatch();
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState();
  const from = location.state?.from?.pathname || '/';

  const loggedUser = useSelector(getLoggedUser);
  const isloggedUser = useSelector(getUserInfoLoader);
  useEffect(() => {
    const isLoggedIn = JSON.parse(
      sessionStorage.getItem(COURIER_SERVICE_LOGIN)
    );
    if (isLoggedIn) {
      dispatch(setLogedUserDetails(isLoggedIn));
    }
  }, []);
  useEffect(() => {
    if (loggedUser && loggedUser?.token) {
      const { token, ...rest } = loggedUser;
      setAuth({ user: rest, token });
      navigate(from, { replace: true });
    }
  }, [loggedUser, setAuth, navigate]);

  const initialValues = {
    email: '',
    password: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^[a-z0-9+_.-]+@[a-z]+\.[a-z]{2,3}/, 'Invilid Email address')
      .required('Required'),
    password: Yup.string().required('No password provided.'),
  });
  const onSubmit = data => {
    // console.log(data);
    let userParams = {
      email: data.email,
      password: data.password,
    };
    dispatch({ type: 'LOGIN_USER', postData: userParams });
  };
  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Image
            // boxSize='100px'
            w={'150px'}
            objectFit="cover"
            src="/quixgo.jpg"
            alt="Quixgo"
            mb={'50px'}
          />
          {/* <Box>
              <Text>Welcome To <Text >QU</Text><Text>IX</Text><Text>GO</Text> Admin Panel </Text>
            </Box> */}
          <Heading fontSize={'2xl'}>Login to your QUIXGO Admin Account</Heading>
          <Formik
            initialValues={initialValues}
            //   onSubmit={(values)=>onSubmit(values)}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              onSubmit(values);
            }}
          >
            {props => (
              <Form>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      id="email"
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel>
                        Email address
                        <Text as="span" color="red">
                          *
                        </Text>
                      </FormLabel>
                      <Input
                        placeholder="Your email address"
                        type="email"
                        {...field}
                        autoComplete={'username'}
                        tabIndex="0"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password" mt="10px">
                  {({ field, form }) => (
                    <FormControl
                      id="password"
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel>
                        Password
                        <Text as="span" color="red">
                          *
                        </Text>
                      </FormLabel>
                      <InputGroup size="md" {...field}>
                        <Input
                          pr="4.5rem"
                          type={show ? 'text' : 'password'}
                          autoComplete={'current-password'}
                          tabIndex="1"
                          placeholder="Enter password"
                        />
                        <InputRightElement
                          onClick={() => setShow(!show)}
                          children={
                            <Icon
                              as={show ? AiFillEye : AiFillEyeInvisible}
                              w={5}
                              h={5}
                              color="blue.500"
                            />
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack spacing={6}>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    align={'start'}
                    justify={'space-between'}
                  >
                    <Checkbox tabIndex="2">Remember me</Checkbox>
                    <Link
                      as={NavLink}
                      to="/resetPassword"
                      color={'blue.500'}
                      tabIndex="4"
                    >
                      Forgot password?
                    </Link>
                  </Stack>
                  <Button
                    colorScheme={'blue'}
                    variant={'solid'}
                    type="submit"
                    tabIndex="3"
                    isLoading={isloggedUser}
                  >
                    Sign in
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
          }
        />
      </Flex>
    </Stack>
  );
}
