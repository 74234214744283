import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Skeleton,
} from '@chakra-ui/react';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
const pageColor = '#274a69';
const CustomTable = ({ tableData, styleprops = {} }) => {
  let { PropsData, Header, Body, isDataLoading } = tableData;
  const [sortBy, setSortBy] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState('asc');
  if (sortBy) {
    PropsData = PropsData ? PropsData.slice() : [];
    PropsData.sort((a, b) => {
      const valueA = a?.[sortBy];
      const valueB = b?.[sortBy];
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return sortOrder === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        return sortOrder === 'asc'
          ? String(valueA).localeCompare(String(valueB))
          : String(valueB).localeCompare(String(valueA));
      }
    });
  }
  const handleSort = header => {
    if (sortBy === header) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(header);
      setSortOrder('asc');
    }
  };
  return (
    <TableContainer pos={'relative'} {...styleprops}>
      <Table variant="simple">
        <Thead pos={'sticky'} top={0} bg={pageColor} color={'white'}>
          <Tr>
            {Header?.map(item => (
              <Th
                onClick={() => handleSort(item?.sortByTerm)}
                key={item?.label}
                color={'whiteAlpha.800'}
              >
                {item?.label}
                {sortBy === item?.sortByTerm && (
                  <Icon
                    as={
                      sortOrder === 'asc'
                        ? AiOutlineArrowUp
                        : AiOutlineArrowDown
                    }
                    ml={1}
                    boxSize={3}
                    color="white"
                  />
                )}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isDataLoading ? (
            <Tr>
              <Td colSpan={Header.length}>
                <Skeleton height={'20px'} />
              </Td>
            </Tr>
          ) : (
            PropsData?.map((item, index) => (
              <Tr key={index}>
                {Body?.map((Component, idx) => (
                  <Td key={idx}>{<Component key={idx} row={item} />}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(CustomTable);
