import deliveryChartImg from '../assets/images/delhiveryChart.png';
import shadofaxChartImg from '../assets/images/shadowfaxChart.png';
import xpressbeesChartImg from '../assets/images/expressbeesChart.png';
import ekartChartImg from '../assets/images/EkartChart.png';
import marutiChartImg from '../assets/images/marutChart.png';
import ecomChartImage from '../assets/images/ecomChart.png';
export const CustomerType = {
  NORMAL: 'Normal',
  BUSINESS: 'Business',
  FRANCHISE: 'Franchise',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
  Other: 'Other',
  GST: 'Gst',
};

export const ProductType = [
  {
    value: 'Documents',
    label: 'Documents',
  },
  {
    value: 'Books',
    label: 'Books',
  },
  {
    value: 'Cloths',
    label: 'Cloths',
  },
  {
    value: 'Gifts',
    label: 'Gifts',
  },
  {
    value: 'Electronics',
    label: 'Electronics',
  },
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Jewelry',
    label: 'Jewelry',
  },
  {
    value: 'Medicines',
    label: 'Medicines',
  },
  {
    value: 'Household',
    label: 'Household',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
export const PackagingType = [
  {
    value: 'Envelope',
    label: 'Envelope',
  },
  {
    value: 'Parcel Bag',
    label: 'Parcel Bag',
  },
  {
    value: 'Parcel Box',
    label: 'Parcel Box',
  },
  {
    value: 'Not Packed',
    label: 'Not Packed',
  },
];
export const ParcelQty = [
  {
    value: '1 Parcel',
    label: '1 Parcel',
  },
  {
    value: '2 Parcel',
    label: '2 Parcel',
  },
  {
    value: '3 Parcel',
    label: '3 Parcel',
  },
  {
    value: '4 Parcel',
    label: '4 Parcel',
  },
  {
    value: '5 Parcel',
    label: '5 Parcel',
  },
  {
    value: '6 Parcel',
    label: '6 Parcel',
  },
  {
    value: '7 Parcel',
    label: '7 Parcel',
  },
  {
    value: '8 Parcel',
    label: '8 Parcel',
  },
  {
    value: '9 Parcel',
    label: '9 Parcel',
  },
  {
    value: '10 Parcel',
    label: '10 Parcel',
  },
  {
    value: 'Bulk Parcel',
    label: 'Bulk Parcel',
  },
];

export const USER_ROLES = {
  AGENT: 'agent',
  NORMAL: 'normal',
  ADMIN: 'admin',
  MANAGER: 'manager',
  SUPPORT: 'support',
  SUPER: 'super',
};

export const MERCHANTS_FILTER = {
  VERIFIED: true,
  UNVERIFIED: false,
  GST: 'gst',
  NORMAL: 'normal',
  INFANT: 'infinite',
  NEW: 'new',
};
export const SERVICETYPES = {
  SURFACE: 'SURFACE',
  EXPRESS: 'EXPRESS',
};

export const ServiceProviderList = [
  {
    name: 'XPRESSBEES',
    value: 'XBS',
    service: [
      {
        label: 'SURFACE',
        value: 'SF',
      },
    ],
  },
  {
    name: 'DELHIVERY',
    value: 'DLV',
    service: [
      {
        label: 'EXPRESS',
        value: 'EXP',
      },
      {
        label: 'SURFACE',
        value: 'SF',
      },
    ],
  },
  {
    name: 'SHADOWFAX',
    value: 'SFX',
    service: [
      {
        label: 'SURFACE',
        value: 'SF',
      },
    ],
  },
  {
    name: 'MARUTI',
    value: 'SMC',
    service: [
      {
        label: 'EXPRESS',
        value: 'EXP',
      },
      {
        label: 'SURFACE',
        value: 'SF',
      },
    ],
  },
  {
    name: 'EKART',
    value: 'EKT',
    service: [
      {
        label: 'EXPRESS',
        value: 'EXP',
      },
      {
        label: 'SURFACE',
        value: 'SF',
      },
    ],
  },
  {
    name: 'ECOM',
    value: 'ECM',
    service: [
      {
        label: 'EXPRESS',
        value: 'EXP',
      },
      {
        label: 'SURFACE',
        value: 'SF',
      },
    ],
  },
];

export const SERVICE_PROVIDERS = {
  DLV: {
    name: 'delhivery',
    value: 'DLV',
    pics: deliveryChartImg,
  },
  SFX: {
    name: 'shadowfax',
    value: 'SFX',
    pics: shadofaxChartImg,
  },
  XBS: {
    name: 'xpressbees',
    value: 'XBS',
    pics: xpressbeesChartImg,
  },
  SMC: {
    name: 'maruti',
    value: 'SMC',
    pics: marutiChartImg,
  },
  EKT: {
    name: 'ekart',
    value: 'EKT',
    pics: ekartChartImg,
  },
  ECM: {
    name: 'ecom',
    value: 'ECM',
    pics: ecomChartImage,
  },
};

export const SERVICEMODE = {
  FW: 'Forward',
  RV: 'Reverse',
  RT: 'Return',
};

export const CREDIT_REASON = {
  cancelled: 'Cancelled',
  recharged: 'Recharged',
  wrongCharged: 'Wrong Charge',
  waiver: 'waiver',
  lost: 'Lost',
  damage: 'Damage',
};
export const DEBIT_REASON = {
  Fine: 'Fine charged',
  wrongCharge: 'Wrong Charged',
  overWeightCharge: 'Over Weight Adjustment',
};
export const PAYMENT_MODE = {
  debit: 'Debit Card',
  credit: 'Credit Card',
  ebt: 'EBT',
  pCheck: 'P Check',
  eCheck: 'E Check',
  upi: 'UPI',
  qrCode: 'QR Code',
  mobileWallet: 'Mobile Wallet',
};

export const TRANSFER_MODE = {
  CREDIT: 'Credit Note',
  BANK: 'Bank Transfer',
};
export const CHARGED_MODE = {
  Quixgo: 'Quixgo',
  Government: 'Government',
  ShippingPartner: 'Shipping Partner',
};

export const defautShipmentPartnerValue = [
  {
    name: 'delhivery',
    value: 'DLV',
  },
  {
    name: 'shadowfax',
    value: 'SFX',
  },
  {
    name: 'xpressbees',
    value: 'XBS',
  },
  {
    name: 'maruti',
    value: 'SMC',
  },
  {
    name: 'ekart',
    value: 'EKT',
  },
  {
    name: 'ecom',
    value: 'ECM',
  },
  {
    name: 'EXPRESS',
    value: 'EXP',
  },
  {
    name: 'SURFACE',
    value: 'SF',
  },
  {
    name: 'The Cheapest Courier First',
    value: 'cheapest',
  },
  {
    name: 'Quixgo Priority',
    value: 'quixgo',
  },
  {
    name: 'Fastest Service',
    value: 'fastest',
  },
];
export const manifestSettingData = [
  {
    name: 'quixgoLogo',
    title: 'Hide Quixgo logo',
    description: 'Hide Quixgo Logo',
  },
  {
    name: 'customerLogo',
    title: 'Hide Display Logo',
    description: 'Hide Display Logo',
  },
  {
    name: 'invoiceValue',
    title: 'Hide Invoice Value',
    description: 'Hide Invoice Value',
  },
  {
    name: 'returnAddress',
    title: 'Hide Return Address',
    description: 'Hide Return Address',
  },
  {
    name: 'sellerContactDetails',
    title: 'Hide Seller Contact Details',
    description: 'Hide Seller Contact Details',
  },
  {
    name: 'products',
    title: 'Hide Products',
    description: 'Hide Products',
  },
  {
    name: 'weight',
    title: 'Hide Weight',
    description: 'Hide Weight',
  },
  {
    name: 'consigneeMobile',
    title: 'Hide consignee Mobile',
    description: 'Hide consignee Mobile',
  },
];
export const Zone = [
  { label: 'Within City', value: 'city' },
  { label: 'Metro to Metro', value: 'metro' },
  { label: 'Within Zone', value: 'zone' },
  { label: 'Rest of India', value: 'india' },
  { label: 'Extended Location', value: 'special' },
];
