import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { AWBStatus } from '../ShipmentColumns/ColumnData';
import { Flex, Icon, Link, Text, Tooltip } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaMinus } from 'react-icons/fa';
const RenderLabelValue = ({ value }) => {
  if (value) {
    return <Text>{value}</Text>;
  } else {
    return (
      <Tooltip placement="bottom-start" label={`Not Available`} rounded="md">
        <Flex alignItems="center" justifyContent={'center'}>
          <Icon boxSize={4} as={FaMinus} />
        </Flex>
      </Tooltip>
    );
  }
};
const TransNumber = ({ row }) => {
  return (
    <>
      <RenderLabelValue
        value={row?.office_transaction_detail_transactionNumber}
      />
    </>
  );
};

const AccTo = ({ row }) => {
  return (
    <>
      <RenderLabelValue value={row.office_transaction_detail_accountNumberTo} />
    </>
  );
};
const TranDate = ({ row }) => {
  return (
    <>
      <RenderLabelValue
        value={row?.office_transaction_detail_transactionDate}
      />
    </>
  );
};
const PymtMode = ({ row }) => {
  return (
    <>
      <RenderLabelValue value={row?.office_transaction_detail_paymentMode} />
    </>
  );
};

const CodAmount = ({ row }) => {
  let amount = row?.productDetails_cod ? row?.productDetails_cod : 0;
  return (
    <>
      <RenderLabelValue value={amount} />
    </>
  );
};
const IdDetails = ({ row }) => {
  const location = useLocation();
  return (
    <>
      <Link
        as={NavLink}
        to={`/customerdetails/${row.customerId}`}
        params={{ merchantId: row.customerId }}
        color="blue.500"
        state={{ from: location.pathname }}
      >
        {row.customerId}
      </Link>
    </>
  );
};
export const REMITTANCE_PAID_COLUMNS = () => {
  const columnHelper = createColumnHelper();

  return React.useMemo(() => [
    columnHelper.accessor('customerId', {
      cell: info => <IdDetails row={info.row.original} />,
      header: 'Seller Id',
    }),
    columnHelper.accessor('awbNumber', {
      cell: info => <AWBStatus row={info.row.original} />,
      header: 'awbNumber',
    }),

    columnHelper.accessor('transactionNumber', {
      cell: info => <TransNumber row={info.row.original} />,
      header: 'Trans. Num',
    }),
    columnHelper.accessor('accountNumberTo', {
      cell: info => <AccTo row={info.row.original} />,
      header: 'Acc. To',
    }),
    columnHelper.accessor('paymentMode', {
      cell: info => <PymtMode row={info.row.original} />,
      header: 'Pay. Mode',
    }),

    columnHelper.accessor('shipmentPartner', {
      header: () => 'Courier',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),

    columnHelper.accessor('cod', {
      header: 'Trans. Amount',
      cell: info => <CodAmount row={info.row.original} />,
      // footer: info => info.column.id,
      // Filter:ColumnFilter
    }),
    columnHelper.accessor('createdAt', {
      cell: info => <TranDate row={info.row.original} />,
      header: 'Transaction Date',
    }),
  ]);
};
