import React, { useEffect } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './pages.scss';
import CustomerContainer from '../containers/Customer';
import TransactionContainer from '../containers/Transaction';
import ShipmentContainer from '../containers/Shipment';
import PickupContainer from '../containers/PickupPoints.jsx/PickupContainer';
import Remittance from '../containers/Shipment/Remittance/index';
import CustomerSettingPage from '../containers/Customer/setting';
const CustomerDetails = () => {
  let { customerId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      id: customerId,
    };
    dispatch({ type: 'GET_CUSTOMER_INFO', payload: params });
    dispatch({ type: 'GET_KYC_DOCS', postData: { customerId: customerId } });
  });
  return (
    <Tabs isLazy={true} pos={'relative'} defaultIndex={2}>
      <TabList flexFlow={'wrap'} py={2}>
        <Tab>Customer Details</Tab>
        <Tab>Transaction Details</Tab>
        <Tab>Shipments</Tab>
        <Tab>Remittance</Tab>
        <Tab>Pickup Points</Tab>
        <Tab>Settings</Tab>
      </TabList>
      <TabPanels className="tabPanel" overflow="auto">
        <TabPanel p={0}>
          <CustomerContainer />
        </TabPanel>
        <TabPanel p={0}>
          <TransactionContainer />
        </TabPanel>
        <TabPanel p={0}>
          <ShipmentContainer />
        </TabPanel>
        <TabPanel p={0}>
          <Remittance />
        </TabPanel>
        <TabPanel p={0}>
          <PickupContainer />
        </TabPanel>
        <TabPanel p={0}>
          <CustomerSettingPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default CustomerDetails;
