import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  useDisclosure,
  Heading,
  Tabs,
  TabList,
  Tab,
} from '@chakra-ui/react';
import CalculateForm from './CalculatorForm';
import B2BcalculatorForm from './B2BcalculatorForm';
import { BsFillCalculatorFill } from 'react-icons/bs';
import { getLayoutType } from '../../redux/slices/ComponentFunctionSlice';
import { useSelector } from 'react-redux';
import { memo } from 'react';
const Calculatedrawer = () => {
  const layOutType = useSelector(getLayoutType);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Type, setType] = useState('B2B');
  const handleOnclose = () => {
    setType('B2B');
    onClose();
  };
  return (
    <>
      <Icon
        as={BsFillCalculatorFill}
        w={[6, 7]}
        h={[6, 7]}
        color="white"
        _hover={{ cursor: 'pointer' }}
        mr={1}
        onClick={onOpen}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            bg="lightgrey"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-around'}
          >
            <Heading as="h1" size="md" color={'blackAlpha.700'}>
              Calculator
            </Heading>
            <Tabs variant={'enclosed'}>
              <TabList w={40}>
                <Tab
                  _selected={{ color: 'white', bg: 'blue.500' }}
                  onClick={() => setType('B2B')}
                  fontWeight={'extrabold'}
                  fontSize={20}
                >
                  B2B
                </Tab>
                <Tab
                  _selected={{ color: 'white', bg: 'blue.500' }}
                  onClick={() => setType('B2C')}
                  fontWeight={'extrabold'}
                  fontSize={20}
                >
                  B2C
                </Tab>
              </TabList>
            </Tabs>
          </DrawerHeader>
          <DrawerBody>
            {layOutType === 'b2b' ? (
              <B2BcalculatorForm close={onClose} />
            ) : (
              <CalculateForm close={onClose} />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default memo(Calculatedrawer);
