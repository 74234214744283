import React, { useState } from 'react';

import VolumetricTable from './ValumetricTable';
import { Select } from '@chakra-ui/react';
import Card from '../../../components/card';
import { IoMdArrowDropdown } from 'react-icons/io';
import ManifestSetting from './ManifestSetting';
import DefaultPartner from './DefaultShipmentPartner';

const Index = () => {
  const [selectedValue, setSelectedValue] = useState('Shipment Partner');
  const settingDropDownData = [
    { label: 'Shipment Partner', component: <DefaultPartner /> },
    { label: 'Package Volume', component: <VolumetricTable /> },
    { label: 'Label Setting', component: <ManifestSetting /> },
  ];

  const handleSelectChange = e => {
    setSelectedValue(e.target.value);
  };

  return (
    <Card>
      <Select
        bg="blue.500"
        borderColor="blue.500"
        color="white"
        defaultValue={selectedValue}
        icon={<IoMdArrowDropdown />}
        w={[200]}
        onChange={handleSelectChange}
        textTransform="capitalize"
      >
        {settingDropDownData.map(item => (
          <option
            style={{ color: 'black' }}
            key={item.label}
            value={item.label}
          >
            {item.label}
          </option>
        ))}
      </Select>
      {settingDropDownData.map(item => {
        if (selectedValue === item?.label) {
          return (
            <React.Fragment key={item.label}>{item?.component}</React.Fragment>
          );
        }
        return null;
      })}
    </Card>
  );
};

export default Index;
