import React from 'react';
import ReactTable from '../../../../components/ReactTable/tablev8';
import { Default_Shipment_PartnerList_Column } from './Default_Shipment_PartnerList_Column';
import {
  getCustomerSetting,
  getIsCustomerSettingLoading,
} from '../../../../redux/slices/CustomerSettingSlice';
import { useSelector } from 'react-redux';
import TableLoader from '../../../../components/Loader/TableLoader';
import NoDataAvailable from '../../../../components/Loader/NoDataAvailable';

const DefaultShipmentPartnerList = () => {
  const customerSetting = useSelector(getCustomerSetting);
  const isCustomerSettingLoading = useSelector(getIsCustomerSettingLoading);
  const data = customerSetting?.courierPartner;
  const dataArray = [];
  for (const key in data) {
    if (key !== 'undefined') {
      dataArray.push({ [key]: data[key] });
    }
  }
  const isDataAvailable =
    dataArray !== null && dataArray !== undefined && dataArray.length !== 0;

  return (
    <>
      {isCustomerSettingLoading ? (
        <TableLoader />
      ) : isDataAvailable ? (
        <ReactTable
          TableUtility={{ h: ['40vh', '45vh', '53vh', '60vh', '65vh'] }}
          Unformatdata={dataArray}
          data={dataArray}
          columns={Default_Shipment_PartnerList_Column()}
        />
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
};

export default DefaultShipmentPartnerList;
