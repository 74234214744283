import React from 'react';
import { useSelector } from 'react-redux';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { getLoggedUser } from '../redux/slices/UserDetailsSlice';
import './pages.scss';
import MainNavbar from '../components/card/shared/MainNavbar';
import AllMerchantList from '../containers/AllMerchant/AllmerchantList';
import { Link } from 'react-router-dom';
const AllMerchantsPage = () => {
  const loggedUser = useSelector(getLoggedUser);
  return (
    <Flex h={'100vh'} flexDir={'column'} gap={2}>
      <MainNavbar loggedUser={loggedUser} />
      <Heading textAlign={'center'} fontSize={24}>
        All Merchant List
      </Heading>
      <Breadcrumb mx={'auto'} w={'80vw'} fontWeight={'bold'}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} href="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} href="/allMerchant">
            allMerchant
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <AllMerchantList />
    </Flex>
  );
};

export default AllMerchantsPage;
