import React, { useState } from 'react';
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  Stack,
} from '@chakra-ui/react';
import { MdEdit, MdDelete } from 'react-icons/md';
import DrawerFormData from './DrawerFormData';
import { useDispatch, useSelector } from 'react-redux';
import { getPickupPageSelectedDate } from '../../redux/slices/PickupPointsDetailsSlice';
import PickupModal from './PickupModal';
import { AiFillEye } from 'react-icons/ai';
const PickupActionButtton = ({ row }) => {
  const dispatch = useDispatch();
  const [defaultAdd, setDefaultAdd] = useState(false);
  const [value, setValue] = React.useState('pickupAddress');
  const PickupPageSelectdDate = useSelector(getPickupPageSelectedDate);
  function handleSubmit() {
    const params = {
      id: row?.addressId,
    };
    dispatch({
      type: 'HANDLE_DELETE',
      postData: params,
      allFetchLocationParam: {
        startDate: PickupPageSelectdDate[0]?.startDate,
        endDate: PickupPageSelectdDate[1]?.endDate,
        customerId: PickupPageSelectdDate[2]?.customerId,
      },
    });
    deleteModelClose();
  }
  function handleDefaultSubmit() {
    const params = {
      type: value,
      id: row?.addressId,
      customerId: row?.customerId,
    };

    dispatch({
      type: 'PUT_DEFAULT_ADDRESS',
      postData: params,
    });
    if (row?.customerId) {
      dispatch({
        type: 'GET_CUSTOMER_SETTING',
        postData: {
          id: row?.customerId,
        },
      });
    }
    defaultModelClose();
  }
  const defaultFormValue = {
    Id: row?.addressId,
    cpName: row?.cpPerson,
    mobileNumber: row?.cpMobile,
    email: row?.email,
    address1: row?.address1,
    address2: row?.address2,
    landmark: row?.landmark,
    city: row?.city,
    state: row?.state,
    pincode: row?.pincode,
    addressType: row?.addressType,
    alternateNumber: row?.alternateNumber,
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: deleteModelisOpen,
    onOpen: deleteModelOpen,
    onClose: deleteModelClose,
  } = useDisclosure();
  const {
    isOpen: defaultModelisOpen,
    onOpen: defaultModelOpen,
    onClose: defaultModelClose,
  } = useDisclosure();
  const setDefaultAddress = row => {
    defaultModelOpen();
    setDefaultAdd(!defaultAdd);
  };
  return (
    <>
      <Menu placement="bottom">
        <MenuButton fontWeight={900} rounded={'full'} fontSize={20}>
          ...
        </MenuButton>
        <MenuList>
          <MenuItem
            py={3}
            fontWeight={'bold'}
            display={'flex'}
            justifyContent={'center'}
            onClick={onOpen}
          >
            <Icon color={'green.600'} boxSize={6} as={MdEdit} />
            <Text color={'green.600'} fontSize={'md'}>
              Edit
            </Text>
          </MenuItem>
          <MenuItem
            py={3}
            fontWeight={'bold'}
            display={'flex'}
            justifyContent={'center'}
            onClick={deleteModelOpen}
          >
            <Icon color={'red.500'} boxSize={6} as={MdDelete} />
            <Text color={'red.500'} fontSize={'md'}>
              Delete
            </Text>
          </MenuItem>
          <MenuItem
            py={3}
            fontWeight={'bold'}
            display={'flex'}
            justifyContent={'center'}
            onClick={() => setDefaultAddress(row)}
          >
            <Icon color={'#3182ce'} boxSize={6} as={AiFillEye} />
            <Text color={'#3182ce'} fontSize={'md'}>
              Default
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <PickupModal
        heading={'Do you want to Delete'}
        isOpen={deleteModelisOpen}
        onClose={deleteModelClose}
        handleFunction={handleSubmit}
      />
      <PickupModal
        heading={' Default Adress Type'}
        isOpen={defaultModelisOpen}
        onClose={defaultModelClose}
        handleFunction={handleDefaultSubmit}
        modalBody={
          <RadioGroup onChange={setValue} value={value}>
            <Stack direction="row">
              <Radio value="pickupAddress">Pickup</Radio>
              <Radio value="returnAddress">Return</Radio>
            </Stack>
          </RadioGroup>
        }
      />
      <DrawerFormData
        isOpen={isOpen}
        onClose={onClose}
        defaultFormValue={defaultFormValue}
      />
    </>
  );
};

export default PickupActionButtton;
