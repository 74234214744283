import {
  Container,
  Flex,
  HStack,
  Skeleton,
  VStack,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import { COLOR } from '../utils/constant';
import MobileMenu from '../components/sidebar/MobileMenu';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
const PrivateLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isGreterThan768] = useMediaQuery('(min-width: 769px)');
  const btnRef = useRef();
  const { auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth) {
      navigate('/login');
    }
  }, []);

  return (
    <Container
      minW={'100vw'}
      m={0}
      p={0}
      display={'Flex'}
      // overflow={'hidden'}
    >
      <Navbar onOpen={onOpen} btnref={btnRef} />
      <HStack
        margin={isGreterThan768 && '-5px'}
        spacing={1}
        marginTop="60px"
        width="100vw"
        h="calc(100vh - 60px)"
        alignItems="flex-start"
      >
        {isGreterThan768 && <Sidebar />}
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent bgColor={COLOR.blue}>
            <DrawerCloseButton />
            <DrawerHeader>
              <Text as={'h2'} ml={10} fontSize={'2xl'} color="white">
                <Text color={'orange.300'} as="span">
                  QU
                </Text>
                <Text as="span">IX</Text>
                <Text color="green.400" as="span">
                  GO
                </Text>
              </Text>
            </DrawerHeader>

            <DrawerBody>
              <MobileMenu onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Flex pt={!isGreterThan768 && 6}>
          <Outlet />
        </Flex>
      </HStack>
    </Container>
  );
};

export default PrivateLayout;
