import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Heading,
  DrawerBody,
} from '@chakra-ui/react';
import PickupForm from './PickupForm';

function DrawerFormData({ defaultFormValue, isOpen, onClose, isEdit, data }) {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={'lg'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader bg="lightgrey">
          <Heading as="h1" size="md" color={'blackAlpha.700'}>
            Pickup Address
          </Heading>
        </DrawerHeader>
        <DrawerBody>
          <PickupForm
            onClose={onClose}
            isEdit={isEdit}
            pickupData={data}
            defaultFormValue={defaultFormValue}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerFormData;
