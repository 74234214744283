import React, { useEffect, useState } from 'react';
import CancelButton from './CancleButton';
import ReverseButton from './ReverseButton';
import { BsFillPencilFill } from 'react-icons/bs';
import {
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Heading,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';
import { ServiceProviderList } from '../../../utils/utilityConstants';
import BookingForm from '../../BookShipments/BookingForm';
import ManifestModel from '../Manifest/ManifestModel';
import { setManifestPageSize } from '../../../redux/slices/ComponentFunctionSlice';
import { useDispatch } from 'react-redux';

export const ActionButton = ({ row }) => {
  const dispatch = useDispatch();
  const [defaultserviceProviderName, setDefaultserviceProviderName] =
    useState();
  const defaultFormValue = {
    pickupPoint: row?.address_addressId,
    name: row?.deliveryAddress_name,
    mobileNumber: row?.deliveryAddress_mobile,
    email: row?.deliveryAddress_email,
    address1: row?.deliveryAddress_add1,
    address2: row?.deliveryAddress_add2,
    landmark: row?.deliveryAddress_lmk,
    city: row?.deliveryAddress_city,
    state: row?.deliveryAddress_state,
    pincode: row?.deliveryAddress_pincode,
    ewayBill: row?.productDetails_ewayBill,
    skuNumber: row?.productDetails_skuNum,
    orderNumber: row?.productDetails_orderNum,
    productName: row?.productDetails_pName,
    quantity: row?.productDetails_quantity,
    weight: row?.productDetails_weight,
    length: row?.productDetails_length,
    width: row?.productDetails_width,
    height: row?.productDetails_height,
    invoice: row?.productDetails_invoice,
    productType: row?.productDetails_pType,
    addressType: row?.deliveryAddress_addType,
    alternateNumber: row?.alternateNumber,
    paymentMode: row?.paymentMode,
    serviceType:
      row?.serviceTypes === 'express'
        ? 'EXP'
        : row?.serviceTypes === 'surface'
        ? 'SF'
        : null,
    serviceProvider: defaultserviceProviderName,
    codAmount: row?.codAmount,
  };
  useEffect(() => {
    const provider = ServiceProviderList.find(
      provider => provider.name === row.shipmentPartner
    );
    if (provider?.service !== undefined && provider?.service !== null) {
      setDefaultserviceProviderName(provider?.value);
    }
  }, [defaultserviceProviderName, row?.shipmentPartner]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCancleModel,
    onOpen: handleCancleOpenModel,
    onClose: handleCancleModelClose,
  } = useDisclosure();
  const {
    isOpen: isReverseModel,
    onOpen: handleReverseOpenModel,
    onClose: handleReverseModelClose,
  } = useDisclosure();
  const {
    isOpen: isManifestModelOpen,
    onOpen: handleManifestOpenModel,
    onClose: handleManifestClose,
  } = useDisclosure();
  const onManifestOpen = () => {
    const customerId = row?.customerId;
    dispatch({
      type: 'GET_CUSTOMER_INFO',
      payload: { id: customerId },
    });
    dispatch({
      type: 'GET_KYC_DOCS',
      postData: { customerId: customerId },
    });
    dispatch({
      type: 'GET_ALL_PICKUPLIST',
      payload: {
        customerId: customerId,
      },
    });
    handleManifestOpenModel();
  };
  const onManifestClose = () => {
    dispatch(setManifestPageSize('_auto'));
    handleManifestClose();
  };
  return (
    <>
      <Menu placement="bottom">
        <MenuButton fontWeight={900} rounded={'full'} fontSize={20}>
          ...
        </MenuButton>
        <MenuList>
          <MenuItem
            isDisabled={row?.currentStatus !== 'Booked'}
            onClick={handleCancleOpenModel}
          >
            <Text fontWeight={'bold'} mx={'auto'}>
              Cancel
            </Text>
          </MenuItem>
          <MenuItem
            isDisabled={row?.currentStatus !== 'Delivered'}
            onClick={handleReverseOpenModel}
          >
            <Text mx={'auto'} fontWeight={'bold'}>
              Reverse
            </Text>
          </MenuItem>
          <MenuItem fontWeight={'bold'} onClick={onManifestOpen}>
            <Text mx={'auto'}>Print</Text>
          </MenuItem>
          <MenuItem
            isDisabled={
              !(
                row?.currentStatus === 'ERROR' ||
                row?.currentStatus?.toLowerCase() === 'cancelled'
              )
            }
            fontWeight={'bold'}
            onClick={onOpen}
          >
            <Text mx={'auto'}>BookAgain</Text>
          </MenuItem>
          <MenuItem isDisabled={true}>
            <Text fontWeight={'bold'} mx={'auto'}>
              <Icon as={BsFillPencilFill} />
              Adresss
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      {isManifestModelOpen && (
        <ManifestModel
          isOpen={isManifestModelOpen}
          onClose={onManifestClose}
          printData={[row]}
        />
      )}
      {isCancleModel && (
        <CancelButton
          isOpen={isCancleModel}
          onClose={handleCancleModelClose}
          row={row}
        />
      )}
      {isReverseModel && (
        <ReverseButton
          isOpen={isReverseModel}
          onClose={handleReverseModelClose}
          row={row}
        />
      )}

      <Drawer isOpen={isOpen} size={'lg'} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader bg="lightgrey">
            <Heading as="h1" size="md" color={'blackAlpha.700'}>
              Book Shipment
            </Heading>
            <DrawerCloseButton bg="white" color={'black'} />
          </DrawerHeader>
          <DrawerBody>
            <BookingForm
              defaultFormValue={defaultFormValue}
              onClose={onClose}
              row={row}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
