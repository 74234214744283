import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setDashboardData,
  setIsDashboardLoader,
  setCardCount,
  setRemmitence,
  setServiceProviderCount,
  setLocationCount,
} from '../slices/DashboardDetailsSlice';
import {
  httpCall,
  GET_ADMIN_DASHBOARD_DATA_URL,
  GET_USER_DASHBOARD_DATA_URL,
} from '../../utils/api';

import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
export function* getDashboardData(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { postData } = params;
  const getDashboardDataUrl = `${process.env.REACT_APP_BASEURL}${
    postData.customerId
      ? GET_USER_DASHBOARD_DATA_URL
      : GET_ADMIN_DASHBOARD_DATA_URL
  }`;
  yield put(setIsDashboardLoader(true));
  try {
    const dashboardDataResponse = yield call(httpCall, {
      url: getDashboardDataUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (dashboardDataResponse?.success) {
      const {
        inTransit,
        canceled,
        prepaid,
        cod,
        ndr,
        delivered,
        booked,
        processing,
        remittance,
        forward,
        reverse,
        returnShipment,
        delhivery,
        shadowFax,
        maruti,
        xpressbees,
        ecom,
        ekart,
        city,
        metro,
        zone,
        roi,
        special,
        express,
        surface,
      } = dashboardDataResponse.data;

      const cardCount = {
        inTransit,
        canceled,
        prepaid,
        cod,
        ndr,
        delivered,
        booked,
        processing,
        forward,
        reverse,
        RTO: returnShipment,
        express,
        surface,
      };

      const serviceProviderCount = {
        delhivery,
        shadowFax,
        maruti,
        xpressbees,
        ecom,
        ekart,
      };
      const locationCount = { city, metro, zone, roi, special };
      yield put(setCardCount(cardCount));
      yield put(setServiceProviderCount(serviceProviderCount));
      yield put(setLocationCount(locationCount));
      yield put(setRemmitence(remittance));

      yield put(setDashboardData(dashboardDataResponse.data));
      yield put(setIsDashboardLoader(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsDashboardLoader(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsDashboardLoader(false));
  }
}

export function* watchDashboardDetailsSaga() {
  yield takeLatest('GET_DASHBOARD_DATA', getDashboardData);
}

export default function* DashboardDetailsSaga() {
  yield all([watchDashboardDetailsSaga()]);
}
