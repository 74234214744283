import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  RadioGroup,
  Stack,
  Radio,
  Select,
  SimpleGrid,
  Box,
  Tag,
  Text,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import { ProductType, ServiceProviderList } from '../../utils/utilityConstants';
import {
  getBookingStatus,
  getCoverage,
  getIsBookingDone,
  getPincodeData,
  getPincodeDataLoading,
  setCoverage,
  setIsBooking,
  setPincodeData,
} from '../../redux/slices/BookingDetailsSlice';
import { getCustomerInfo } from '../../redux/slices/MerchantDetailsSlice';
import { useSelector, useDispatch } from 'react-redux';
import InsurancePolicy from '../../components/insurance-policy/InsurancePolicy';
import useDebounce from '../../utils/Debaunce';
import { toast } from 'react-toastify';
import { dateCalc, formatValue } from '../../utils/utilsFunction';
import { getAllPickupPointList } from '../../redux/slices/PickupPointsDetailsSlice';
import { useParams } from 'react-router-dom';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
const BookingForm = ({ defaultFormValue, onClose, row }) => {
  const dispatch = useDispatch();
  let { customerId } = useParams();
  const loggedUser = useSelector(getLoggedUser);
  useEffect(() => {
    const params = {
      id: row?.customerId,
    };
    dispatch({
      type: 'GET_ALL_PICKUPLIST',
      payload: {
        customerId: row?.customerId,
      },
    });
    dispatch({ type: 'GET_CUSTOMER_INFO', payload: params });
  }, [row?.customerId]);
  const customerPickupLocation = useSelector(getAllPickupPointList);
  const customerInfo = useSelector(getCustomerInfo);
  const [inputValue, setInputValue] = useState('');
  const debouncedPincodeValue = useDebounce(inputValue, 500);
  const pincodeData = useSelector(getPincodeData);
  const DeliveryData = pincodeData?.coverage;
  const coverageData = useSelector(getCoverage);
  const isPincodeDataLoading = useSelector(getPincodeDataLoading);
  const isBookingStatus = useSelector(getBookingStatus);
  const isBookingDone = useSelector(getIsBookingDone);
  const [ServiceTypeOption, setServiceTypeOption] = useState([]);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [insurenceAmount, setInsurenceAmount] = useState();
  const [insuranceValue, setInsuranceValue] = useState();
  const [pickupCode, setPickupCode] = useState();
  const [paymentMode, setPaymentMode] = useState(defaultFormValue?.paymentMode);
  const [ServiceProvider, setServiceProvider] = useState(
    defaultFormValue?.serviceProvider
  );
  const [deliveryCoverage, setDeliveryCoverage] = useState(false);
  const [pickupCoverage, setPickupCoverage] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [serviceTypeValue, setServiceTypeValue] = useState(
    defaultFormValue?.serviceType
  );
  const displayToast = () => {
    toast.error(
      `Please recharge your wallet. Minimum Balance should be ${
        customerInfo?.minimumBalance ? customerInfo?.minimumBalance : 250
      }`,
      {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        status: 'error',
        theme: 'colored',
      }
    );
  };
  useEffect(() => {
    if (
      coverageData &&
      Object.keys(coverageData)?.length > 0 &&
      ServiceProvider
    ) {
      const PickCoverage = Object.fromEntries(
        Object.entries(coverageData)?.filter(([key]) =>
          key.startsWith(`${ServiceProvider}`)
        )
      );
      const fwPkCoverage = JSON.parse(
        PickCoverage?.[`${ServiceProvider}_FW_PK`]
      );

      if (fwPkCoverage) {
        setPickupCoverage(true);
      } else {
        setErrorMsg('Pickup NOT available on this Pickup Point');
      }
    }
  }, [ServiceProvider, coverageData]);

  useEffect(() => {
    if (pickupCoverage && DeliveryData) {
      const DelCoverage = Object.fromEntries(
        Object.entries(DeliveryData)?.filter(([key]) =>
          key.startsWith(`${ServiceProvider}`)
        )
      );
      const preDelCoverage =
        DelCoverage &&
        ServiceProvider &&
        JSON.parse(DelCoverage?.[`${ServiceProvider}_FW_DL_PRE`]);
      const codDelCoverage =
        DelCoverage &&
        ServiceProvider &&
        JSON.parse(DelCoverage?.[`${ServiceProvider}_FW_DL_COD`]);

      if (paymentMode === 'Prepaid' && !preDelCoverage) {
        setErrorMsg('Prepaid service NOT available on this Pincode');
        setDeliveryCoverage(false);
      } else if (paymentMode === 'COD' && !codDelCoverage) {
        setErrorMsg('COD service NOT available on this Pincode');
        setDeliveryCoverage(false);
      } else {
        setDeliveryCoverage(true);
        setErrorMsg(null);
      }
    }
  }, [ServiceProvider, DeliveryData, paymentMode, pickupCoverage]);
  useEffect(() => {
    if (
      pincodeData &&
      Object.keys(pincodeData).length > 0 &&
      pickupCoverage &&
      !pincodeData?.state &&
      !pincodeData?.city &&
      !pincodeData?.coverage
    ) {
      setErrorMsg('Delivery NOT available on this Pincode');
      setDeliveryCoverage(false);
    }
  }, [pincodeData, pickupCoverage]);
  useEffect(() => {
    if (errorMsg !== undefined && errorMsg !== null) {
      toast.error(`${errorMsg}`, {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, [errorMsg, ServiceProvider]);
  useEffect(() => {
    const filteredPickupLocations = customerPickupLocation?.filter(
      item => item?.addressId === defaultFormValue?.pickupPoint
    );
    if (defaultFormValue?.pickupPoint) {
      setPickupCode(filteredPickupLocations.map(item => item?.pincode));
    }
  }, [defaultFormValue]);

  useEffect(() => {
    if (pickupCode) {
      dispatch({ type: 'GET_COVERAGE_DATA', postData: pickupCode });
    } else {
      dispatch(setCoverage({}));
    }
  }, [pickupCode]);
  useEffect(() => {
    if (isBookingStatus) {
      onClose();
      dispatch(setIsBooking(false));
    }
  }, [isBookingStatus]);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValue,
  });
  useEffect(() => {
    if (defaultFormValue?.pincode || debouncedPincodeValue?.length > 5) {
      dispatch({
        type: 'GET_PINCODE_DATA',
        postData: debouncedPincodeValue || defaultFormValue?.pincode,
      });
    } else {
      dispatch(setPincodeData({}));
    }
  }, [debouncedPincodeValue, defaultFormValue]);

  useEffect(() => {
    const city = pincodeData?.city?.cityName || defaultFormValue?.city;
    const state = pincodeData?.state?.stateName || defaultFormValue?.state;
    if (pincodeData !== undefined && pincodeData !== null) {
      setValue('city', city);
      setValue('state', state);
    } else {
      setValue('city', '');
      setValue('state', '');
    }
  }, [pincodeData]);

  const onInvoiceChange = value => {
    const amount = (1 / 100) * Number(value);
    setInsurenceAmount(amount);
  };

  function onSubmit(values, e) {
    const pickupAddress = customerPickupLocation?.filter(
      location => location.addressId === values.pickupPoint
    )[0];
    const fetchAllShipmentsParam = {
      empId: loggedUser.empid,
      customerId: customerId,
      startDate: dateCalc.week,
      endDate: dateCalc.today,
    };
    const params = {
      deliveryAddress: {
        name: values.name,
        address1: values.address1,
        address2: values.address2,
        landmark: values.landmark,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
        mobile: values.mobileNumber,
        alternateNumber: values.alternateNumber,
        email: values.email,
        addressType: values.addressType,
      },
      pickupAddress,
      customerType: customerInfo.type,
      productDetails: {
        weight: formatValue(values?.weight),
        height: formatValue(values?.height),
        width: formatValue(values?.width),
        length: formatValue(values?.length),
        invoice: values.invoice,
        productName: values.productName,
        productType: values.productType,
        quantity: values.quantity,
        codAmount: values.codAmount,
        skuNumber: values.skuNumber,
        orderNumber: values.orderNumber,
        ewayBill: values.ewayBill,
      },
      serviceProvider: values.serviceProvider,
      serviceType: values.serviceType,
      paymentMode: paymentMode,
      insuranceCharge: insuranceValue === true ? insurenceAmount : 0,
      customerId: customerInfo.customerId,
      serviceMode: 'forward',
    };

    params.pickupAddress = pickupAddress;
    if (
      customerInfo.paymentMode === 'prepaid' &&
      customerInfo.walletAmount <
        (customerInfo.minimumBalance
          ? Number(customerInfo.minimumBalance)
          : 250)
    ) {
      displayToast();
    } else {
      dispatch({
        type: 'SINGLE_SHIPMENT_BOOKING',
        postData: params,
        fetchAllShipmentsParam: fetchAllShipmentsParam,
      });
    }
  }

  useEffect(() => {
    const provider = ServiceProviderList.find(
      provider => provider.value === ServiceProvider
    );
    if (provider?.service !== undefined && provider?.service !== null) {
      setServiceTypeOption(provider.service);
    }
  }, [ServiceProvider]);
  return (
    <Box color="blackAlpha.800">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box bg="" p={2} w="106%" ml={-21} mt={0}>
          <SimpleGrid columns={[1, 2]} spacing={2}>
            <FormControl isInvalid={errors.paymentMode}>
              <FormLabel htmlFor="paymentMode">
                Order Type
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <RadioGroup
                id="paymentMode"
                onChange={value => setPaymentMode(value)}
                defaultValue="COD"
                value={paymentMode}
              >
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="blue" value="Prepaid">
                    Prepaid
                  </Radio>
                  <Radio colorScheme="blue" value="COD">
                    COD
                  </Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>
                {errors.paymentMode && errors?.paymentMode?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.serviceProvider}>
              <FormLabel htmlFor="serviceProvider">
                Service Provider
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                id="serviceProvider"
                placeholder="Select Service Provider"
                {...register('serviceProvider', {
                  required: 'This is required',
                })}
                onChange={e => setServiceProvider(e.target.value)}
              >
                {ServiceProviderList.map((provider, i) => (
                  <option key={provider.value} value={provider.value}>
                    {provider.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.serviceProvider && errors?.serviceProvider?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.serviceType}>
              <FormLabel htmlFor="serviceType">
                Choose Service
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                value={serviceTypeValue}
                id="serviceType"
                placeholder={'Select Service Type'}
                {...register('serviceType', {
                  required: 'This is required',
                })}
                onChange={e => setServiceTypeValue(e.target.value)}
              >
                {ServiceTypeOption?.map(serviceType => (
                  <option key={serviceType.value} value={serviceType.value}>
                    {serviceType.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors?.serviceType?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.pickupPoint}>
              <FormLabel htmlFor="pickupPoint">
                Address Pickup Point{' '}
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                id="pickupPoint"
                placeholder="Pickup Point"
                {...register('pickupPoint', {
                  required: 'This is required',
                })}
                onChange={e => {
                  const selectedOption =
                    e.target.options[e.target.selectedIndex];
                  const selectedPincode =
                    selectedOption.getAttribute('pincode');
                  setPickupCode(selectedPincode);
                }}
              >
                {customerPickupLocation?.map(location => {
                  return (
                    <option
                      pincode={location.pincode} //  set the pincode attribute
                      key={location.addressId}
                      value={location.addressId}
                    >{`${location.cpPerson}-${location.address1}-${location.pincode}`}</option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.pickupPoint?.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>
        <Box bg="" p={2} w="106%" ml={-21} mt={1}>
          <Tag>Destination Details</Tag>
          <SimpleGrid columns={[1, 2]} spacing={2}>
            <FormControl isInvalid={errors.name}>
              <FormLabel htmlFor="name">
                Contact Name{' '}
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="name"
                placeholder="Name"
                {...register('name', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum length should be 50',
                  },
                })}
              />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.mobileNumber}>
              <FormLabel htmlFor="mobileNumber">
                Contact Mobile
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="mobileNumber"
                placeholder="Mobile Number"
                {...register('mobileNumber', {
                  required: 'This is required',
                  // valueAsNumber: true,
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: 'Wrong mobile number',
                  },
                  maxLength: {
                    value: 10,
                    message: 'maximum length should be 10',
                  },
                  minLength: {
                    value: 10,
                    message: 'Minimum length should be 10',
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.mobileNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors?.alternateNumber}>
              <FormLabel htmlFor="alternateNumber">Aternate Mobile</FormLabel>
              <Input
                id="alternateNumber"
                placeholder="Alternate Mobile Number"
                {...register('alternateNumber', {
                  // required: "This is required",
                  // valueAsNumber: true,
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: 'Wrong mobile number',
                  },
                  maxLength: {
                    value: 10,
                    message: 'maximum length should be 10',
                  },
                  minLength: {
                    value: 10,
                    message: 'Minimum length should be 10',
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.mobileNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.email}>
              <FormLabel htmlFor="email">Email Id</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="abc@xyz.com"
                {...register('email', {
                  pattern: {
                    value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    message: 'Wrong email id',
                  },
                })}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.addressType}>
              <FormLabel htmlFor="addressType">
                Delivery Address Type
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                id="addressType"
                placeholder="Select Address Type"
                {...register('addressType', {
                  required: 'This is required',
                })}
              >
                <option>Home</option>
                <option>Office</option>
              </Select>
              <FormErrorMessage>
                {errors?.addressType?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address1}>
              <FormLabel htmlFor="address1">
                Address1
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="address1"
                placeholder="Address1"
                {...register('address1', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Maximum length should be 100',
                  },
                })}
              />
              <FormErrorMessage>{errors?.address1?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address2}>
              <FormLabel htmlFor="address2">Address2</FormLabel>
              <Input
                id="address2"
                placeholder="Address2"
                {...register('address2', {
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Maximum length should be 100',
                  },
                })}
              />
              <FormErrorMessage>{errors?.address2?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.pincode}
              onChange={e => setInputValue(e.target.value)}
            >
              <FormLabel htmlFor="pincode">
                Pincode
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="pincode"
                placeholder="Pincode"
                {...register('pincode', {
                  required: 'This is required',
                  pattern: {
                    value: /^[1-9][0-9]{5}$/,
                    message: 'Wrong pincode',
                  },
                })}
              />
              <FormErrorMessage>{errors?.pincode?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.landmark}>
              <FormLabel htmlFor="landmark">
                Landmark
                {/* <Text as="span" color="red">
                  *
                </Text> */}
              </FormLabel>
              <Input
                id="landmark"
                placeholder="Landmark"
                {...register('landmark', {
                  // required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                  maxLength: {
                    value: 40,
                    message: 'Maximum length should be 40',
                  },
                })}
              />
              <FormErrorMessage>{errors?.landmark?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.city}>
              <FormLabel htmlFor="city">
                City
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                isDisabled={!!isPincodeDataLoading}
                cursor={isPincodeDataLoading ? 'not-allowed' : 'auto'}
                _disabled={{ opacity: '0.8' }}
                id="city"
                placeholder="City"
                {...register('city', {
                  required: 'This is required',
                })}
              />
              {isPincodeDataLoading ? <Spinner color="blue.500" /> : null}

              <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.state}>
              <FormLabel htmlFor="state">
                State
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                isDisabled={!!isPincodeDataLoading}
                cursor={isPincodeDataLoading ? 'not-allowed' : 'auto'}
                _disabled={{ opacity: '0.8' }}
                id="state"
                placeholder={'State'}
                {...register('state', { required: 'This is required' })}
              />
              {isPincodeDataLoading ? <Spinner color="blue.500" /> : null}
              <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>
        <Box p={2} w="106%" ml={-21} mt={1}>
          <Tag>Package Information</Tag>
          <SimpleGrid columns={[1, 3]} spacing={2}>
            <FormControl isInvalid={errors.ewayBill}>
              <FormLabel htmlFor="ewayBill">E-Way Bill Number</FormLabel>
              <Input
                id="ewayBill"
                placeholder="Eway Bill Number"
                {...register('ewayBill')}
              />
            </FormControl>
            <FormControl isInvalid={errors.skuNumber}>
              <FormLabel htmlFor="skuNumber">
                SKU Number
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="skuNumber"
                type={'text'}
                placeholder="Enter SKU Number"
                {...register('skuNumber', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>{errors?.skuNumber?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.orderNumber}>
              <FormLabel htmlFor="orderNumber">
                Order Number
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="orderNumber"
                type={'text'}
                placeholder="Enter Order Number"
                {...register('orderNumber', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>
                {errors?.orderNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.productName}>
              <FormLabel htmlFor="productName">
                Product Description
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="productName"
                placeholder="Enter Product Name"
                {...register('productName', {
                  required: 'This is required',
                  minLength: {
                    value: 2,
                    message: 'Minimum length should be 2',
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.productName?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.productType}>
              <FormLabel htmlFor="productType">
                Product Type
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                id="productType"
                placeholder="Select Product Type"
                {...register('productType', {
                  required: 'This is required',
                })}
              >
                {ProductType.map(pType => (
                  <option key={pType.value} value={pType.value}>
                    {pType.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors?.productType?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.quantity}>
              <FormLabel htmlFor="quantity">
                Quantity
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="quantity"
                placeholder="Enter Quantity"
                {...register('quantity', {
                  required: 'This is required',
                })}
              />

              <FormErrorMessage>{errors?.quantity?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.weight}>
              <FormLabel htmlFor="Weight">
                Weight (kg)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="weight"
                type="number"
                step=".01"
                placeholder="Weight (0.4 kg) = 400gm"
                {...register('weight', {
                  required: 'This is required',
                })}
              />

              <FormErrorMessage>{errors?.weight?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.length}>
              <FormLabel htmlFor="length">
                Length (cm)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="length"
                type="number"
                step={'any'}
                placeholder="Enter Length "
                {...register('length', {
                  required: 'This is required',
                })}
                onChange={e => setLength(e.target.value)}
              />

              <FormErrorMessage>{errors?.length?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.width}>
              <FormLabel htmlFor="width">
                Breadth (cm)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="width"
                type="number"
                step={'any'}
                placeholder="Enter Breadth "
                {...register('width', {
                  required: 'This is required',
                })}
                onChange={e => setWidth(e.target.value)}
              />

              <FormErrorMessage>{errors?.width?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.height}>
              <FormLabel htmlFor="height">
                Height (cm)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                id="height"
                type="number"
                step={'any'}
                placeholder="Enter Height "
                {...register('height', {
                  required: 'This is required',
                })}
                onChange={e => setHeight(e.target.value)}
              />

              <FormErrorMessage>{errors?.height?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.invoice}>
              <FormLabel htmlFor="invoice">
                Invoice Value
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="invoice"
                type="number"
                placeholder="Enter Invoice Value "
                {...register('invoice', {
                  required: 'This is required',
                })}
                onChange={e => onInvoiceChange(e.target.value)}
              />

              <FormErrorMessage>{errors?.invoice?.message}</FormErrorMessage>
            </FormControl>
            {paymentMode === 'COD' && (
              <FormControl isInvalid={errors.codAmount}>
                <FormLabel htmlFor="codAmount">
                  COD Amount
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  id="codAmount"
                  type="number"
                  placeholder="Enter Invoice Value "
                  {...register('codAmount', {
                    required: 'This is required',
                  })}
                />

                <FormErrorMessage>
                  {errors?.codAmount?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          </SimpleGrid>
        </Box>
        {length && width && height && (
          <Box>
            Volumetric Weight :{(length * width * height) / 5000}kg or{' '}
            {(length * width * height) / 5}gram
          </Box>
        )}
        <br />
        <hr />
        <br />
        <Stack spacing={2}>
          <Text fontWeight={700}>
            Do You want to take Shipment Lost/Damage Insurance ( 1% of Invoice
            value {insurenceAmount > 0 ? `= Rs. ${insurenceAmount} ` : ''})
          </Text>
          <Checkbox
            onChange={e => setInsuranceValue(e.target.checked)}
            border={'1px gray'}
          >
            Add Insurance Charge ( <InsurancePolicy /> )
          </Checkbox>

          {errorMsg ? <Text color={'red'}>{errorMsg}</Text> : null}
          <Button
            w={100}
            isDisabled={!deliveryCoverage}
            colorScheme="blue"
            isLoading={isBookingDone}
            type="submit"
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default BookingForm;
