import { combineReducers } from 'redux';
import UserDetailsSlice from './UserDetailsSlice';
import PartnerDetailsSlice from './PartnerDetailsSlice';
import MerchantDetailsSlice from './MerchantDetailsSlice';
import SettingsDetailsSlice from './SettingsDetailsSlice';
import EmployeeDetailsSlice from './EmployeeDetailsSlice';
import ShipmentDetailsSlice from './ShipmentDetailsSlice';
import DashboardDetailsSlice from './DashboardDetailsSlice';
import TransactionDetailsSlice from './TransactionDetailsSlice';
import InsuranceDetailsSlice from './InsuranceDetailsSlice';
import weightManagementDetailsSlice from './weightManagementDetailsSlice';
import PickupPointsDetailsSlice from './PickupPointsDetailsSlice';
import BookingDetailsSlice from './BookingDetailsSlice';
import CustomerSettingsSlice from './CustomerSettingSlice';
import ComponentFunctionSlice from './ComponentFunctionSlice';
const appReducer = combineReducers({
  UserDetailsSlice,
  PartnerDetailsSlice,
  MerchantDetailsSlice,
  SettingsDetailsSlice,
  EmployeeDetailsSlice,
  ShipmentDetailsSlice,
  DashboardDetailsSlice,
  TransactionDetailsSlice,
  InsuranceDetailsSlice,
  weightManagementDetailsSlice,
  PickupPointsDetailsSlice,
  BookingDetailsSlice,
  CustomerSettingsSlice,
  ComponentFunctionSlice
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
