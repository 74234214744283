import { React, useState, useEffect } from 'react';
import { Select, Flex, Text, useMediaQuery, Input } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { dateCalc } from '../utils/utilsFunction';
import useDebounce from '../utils/Debaunce';
import { IoMdArrowDropdown } from 'react-icons/io';
const DateSection = ({ onChange, isCustomerFieldVisible }) => {
  const [isLessThan768] = useMediaQuery('(max-width: 477px)');
  const [dateFrom, setDateFrom] = useState(dateCalc.week);
  const [dateTo, setDateTo] = useState(dateCalc.today);
  const [isDateDisabled, setIsDateDisabled] = useState(true);
  const [userId, setUserId] = useState('');
  const debouncedInputValue = useDebounce(userId, 1000);
  const handleOnChange = value => {
    if (value === 'custom') {
      setIsDateDisabled(false);
    } else if (value === 'today') {
      setDateFrom(dateCalc.today);
      setDateTo(dateCalc.today);
      setIsDateDisabled(true);
    } else if (value === 'yesterday') {
      setDateFrom(dateCalc.yesterday);
      setDateTo(dateCalc.yesterday);
      setIsDateDisabled(true);
    } else {
      setDateFrom(dateCalc[value]);
      setDateTo(dateCalc.today);
      setIsDateDisabled(true);
    }
  };
  useEffect(() => {
    const sData = userId && !dateFrom ? dateCalc?.week : dateFrom;
    const eDate = userId && !dateTo ? dateCalc?.today : dateTo;
    if (sData && eDate) {
      onChange(sData, eDate, debouncedInputValue);
    }
  }, [dateFrom, dateTo, debouncedInputValue]);
  return (
    <>
      <Flex alignItems={'center'} ml={2} gap={2} flexDir={['column', 'row']}>
        <Flex alignItems={'center'} gap={2}>
          {isLessThan768 && <Text>From :</Text>}
          <Input
            w={[28]}
            textAlign={['left', 'right']}
            p={0}
            type="date"
            placeholder="Basic usage"
            value={dateFrom}
            disabled={isDateDisabled}
            _disabled={{ opacity: '0.8' }}
            onChange={e => setDateFrom(e.target.value)}
            color={'black'}
          />
        </Flex>
        {!isLessThan768 && <Text>To</Text>}
        <Flex alignItems={'center'} gap={2}>
          {isLessThan768 && <Text>To :</Text>}
          <Input
            p={0}
            w={[28]}
            type="date"
            textAlign={'left'}
            placeholder="Basic usage"
            height={10}
            value={dateTo}
            disabled={isDateDisabled}
            _disabled={{ opacity: '0.8' }}
            fontWeight={'400'}
            color={'black'}
            onChange={e => setDateTo(e.target.value)}
          />
        </Flex>
        <Select
          icon={<IoMdArrowDropdown />}
          m={0}
          p={0}
          maxW={['200px', '200px', '200px']}
          bg="white"
          color="gray"
          onChange={e => handleOnChange(e.target.value)}
          defaultValue={'week'}
          fontSize={16}
          fontWeight={400}
          border="2px solid gray"
          outline={'gray'}
          borderRadius={7}
        >
          <option value={'today'}>Today</option>
          <option value="yesterday">Yesterday</option>
          <option value={'hours24'}>Last 24 Hours</option>
          <option value={'hours48'}>Last 48 Hours</option>
          <option value={'week'}>Last Week</option>
          <option value={'last30Days'}>Last 30 days</option>
          <option value={'last60Days'}>Last 60 days</option>
          <option value={'last90Days'}>Last 90 days</option>
          <option value="custom"> Custom Date</option>
        </Select>
        {isCustomerFieldVisible && (
          <Input
            maxW={'200px'}
            placeholder="User Id"
            fontSize={20}
            borderRadius={6}
            onChange={e => setUserId(e.target.value)}
            border="2px solid gray"
            outline={'gray'}
            textAlign={'center'}
          />
        )}
      </Flex>
    </>
  );
};

export default DateSection;
