import React from 'react';
import { Stack, Skeleton } from '@chakra-ui/react';
const TableLoader = () => {
  return (
    <Stack h={'70vh'}>
      {Array(6)
        .fill('')
        .map((e, index) => (
          <Skeleton height="20px" key={index} />
        ))}
    </Stack>
  );
};

export default TableLoader;
