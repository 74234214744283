import React from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  TableCaption,
  Img,
} from '@chakra-ui/react';
import { SERVICE_PROVIDERS } from '../../utils/utilityConstants';

const isNumber = value => {
  return typeof value === 'number' && !Number.isNaN(value) && value % 1 !== 0;
};

const renderProviderImage = pickupCode => {
  return Object.entries(SERVICE_PROVIDERS).map(([providerKey, provider]) => {
    if (pickupCode?.toLowerCase()?.includes(providerKey?.toLowerCase())) {
      return (
        <Img
          key={providerKey}
          mx={'auto'}
          h={10}
          src={provider?.pics}
          alt={provider?.name}
        />
      );
    }
    return null;
  });
};

const B2bBookingCards = ({ data }) => {
  return (
    <TableContainer
      py={1}
      px={4}
      border={'1px solid '}
      borderColor={'blackAlpha.500'}
      rounded={'md'}
    >
      <Table size={'sm'} variant="simple">
        <TableCaption
          m={0}
          placement="top"
          fontSize={22}
          fontWeight={'bold'}
          textTransform={'capitalize'}
        >
          {renderProviderImage(data?.pickupCode)}
        </TableCaption>

        <Tbody>
          {Object.entries(data).map(([key, value]) => (
            <Tr
              key={key}
              bg={key === 'total' ? 'blue.500' : 'inherit'}
              color={key === 'total' ? 'white' : 'inherit'}
              fontWeight={key === 'total' ? 'bold' : 'inherit'}
            >
              <Td
                p={1.5}
                textTransform={'capitalize'}
                fontWeight={'bold'}
                borderRight={'1px solid '}
                borderRightColor={'blackAlpha.100'}
                fontSize={key === 'total' ? 18 : 15}
              >
                {key}
              </Td>
              <Td mx={'auto'} p={1.5} fontSize={key === 'total' ? 18 : 16}>
                {isNumber(value) ? value?.toFixed(2) : value}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default B2bBookingCards;
