import React from 'react';
import { Box, Icon, Link, Text, Tooltip } from '@chakra-ui/react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { BiRupee } from 'react-icons/bi';
import CustomerAddress from '../CustomerAdress';
import { RiErrorWarningFill } from 'react-icons/ri';
import { MdOutlineDoneAll } from 'react-icons/md';
import PaymentDetails from '../PaymentDetails';
const currentStatusColor = currentStatus => {
  const statusColorMap = {
    Delivered: 'green',
    Booked: 'blue',
    'return In Transit': 'orange',
  };
  return statusColorMap[currentStatus] || 'red';
};
const BookingChannelDetails = ({ row }) => {
  return (
    <>
      <Text px={1} rounded={'md'} as={'span'} color="black">
        {row?.bookingChannel}
      </Text>
      <Tooltip rounded={'md'} label={'Order ID'}>
        <Text
          px={1}
          rounded={'md'}
          fontSize={'15px'}
          fontWeight={'medium'}
          color="blackAlpha.700"
        >
          {row?.orderId}
        </Text>
      </Tooltip>
    </>
  );
};
const CurrentStatus = ({ row }) => {
  return (
    <>
      {row?.currentStatus === 'ERROR' ? (
        <>
          <Tooltip
            border={'2px solid #800080'}
            rounded={'md'}
            bg={'white'}
            label={<Text>{JSON.stringify(row?.otherInfo_remark)}</Text>}
          >
            <Text
              textTransform={'capitalize'}
              color={currentStatusColor(row?.currentStatus)}
            >
              {row.currentStatus}
              <Icon
                as={RiErrorWarningFill}
                color={'green.500'}
                ml={2}
                boxSize={3}
              />
            </Text>
          </Tooltip>
        </>
      ) : (
        <Text
          textTransform={'capitalize'}
          color={currentStatusColor(row?.currentStatus)}
        >
          {row.currentStatus}
        </Text>
      )}
    </>
  );
};
const AWBStatus = ({ row }) => {
  const location = useLocation();
  return (
    <Link
      as={NavLink}
      to={`/status/${row.awbNumber}`}
      params={{ awbNumber: row.awbNumber }}
      color="green.500"
      fontSize={13}
      textDecoration="underline"
      state={{ from: location.pathname }}
    >
      {row.awbNumber}
    </Link>
  );
};
const Tracker = ({ row }) => {
  return (
    <Box textAlign="center">
      <Text fontWeight={'bold'} color="black" textTransform={'capitalize'}>
        {row.shipmentPartner}
        <Text
          px={1}
          rounded={'md'}
          fontSize={'10px'}
          as={'span'}
          bg={row.serviceTypes == 'express' ? 'whatsapp.200' : 'skyblue'}
          fontWeight={'medium'}
          fontFamily={'cursive'}
          color="black"
        >
          {row?.serviceTypes?.slice(0, 3)}
        </Text>{' '}
      </Text>

      <AWBStatus row={row} />
    </Box>
  );
};

const CustomerName = ({ row }) => {
  return (
    <Tooltip
      border={'2px solid #800080'}
      rounded={'md'}
      bg={'white'}
      label={<CustomerAddress row={row} />}
    >
      <Box w={48} px={2}>
        <Text color="blackAlpha.800" whiteSpace={'break-spaces'}>
          {row.deliveryAddress_name}
        </Text>
        <Text textAlign={'center'}>
          {row.deliveryAddress_pin
            ? row.deliveryAddress_pin
            : row.deliveryAddress_pincode}
          <Icon as={RiErrorWarningFill} color={'green.500'} pt={'4px'} />
        </Text>
      </Box>
    </Tooltip>
  );
};

const Payment = ({ row }) => {
  return (
    <Tooltip
      border={'2px solid #800080'}
      rounded={'md'}
      bg={'white'}
      label={<PaymentDetails row={row} />}
    >
      <Box>
        <Text color={row.paymentMode === 'Prepaid' ? '#0089F1' : '#4B0082'}>
          {row.paymentMode}

          {row?.isInsured === true ? (
            <Text
              ml={1}
              rounded={'md'}
              fontSize={'10px'}
              as={'span'}
              bg={'whatsapp.200'}
              fontWeight={'medium'}
              fontFamily={'cursive'}
              color="black"
            >
              {' '}
              {'Ins'}
            </Text>
          ) : (
            ''
          )}

          {row?.isSettled === true && row?.paymentMode === 'COD' ? (
            <Text
              ml={1}
              as={'span'}
              fontWeight={900}
              color={'green'}
              style={{ display: 'inline-block', fontSize: '16px' }}
            >
              <Icon as={MdOutlineDoneAll} color={'black'} />
            </Text>
          ) : (
            ''
          )}
        </Text>
        <Text fontSize={13} color={'green'}>
          <BiRupee style={{ display: 'inline-block', fontSize: '14px' }} />
          {row.finalCharge}{' '}
          <Icon as={RiErrorWarningFill} color={'green.500'} pt={'3px'} />
        </Text>
      </Box>
    </Tooltip>
  );
};
const PickupPin = ({ row }) => {
  const navigate = useNavigate();
  return (
    <>
      <Text color={row?.isDiscrepancy === true ? 'red' : 'blackAlpha.700'}>
        {Number(row.productDetails_height).toFixed(2)} x{' '}
        {Number(row.productDetails_length).toFixed(2)} x{' '}
        {Number(row.productDetails_width).toFixed(2)} CM
      </Text>
      <Text color={row?.isDiscrepancy === true ? 'red' : 'blackAlpha.900'}>
        {Number(row.productDetails_weight).toFixed(2)} KG
        {row?.isDiscrepancy === true ? (
          <Link
            ml={2}
            rounded={'md'}
            fontSize={'16px'}
            as={'span'}
            fontWeight={'medium'}
            fontFamily={'san serif'}
            color="blue"
            textDecor={'underline'}
            onClick={() => navigate('/weightmanagement')}
          >
            {'Disc'}
          </Link>
        ) : (
          ''
        )}
      </Text>
    </>
  );
};

const Quantity = ({ row }) => {
  return <> {row.productDetails_quantity}</>;
};

const Insurance_Status = ({ row }) => {
  return (
    <Text textTransform={'capitalize'} color={currentStatusColor}>
      {row.currentStatus}
    </Text>
  );
};
export {
  Tracker,
  CustomerName,
  Payment,
  PickupPin,
  CurrentStatus,
  Quantity,
  Insurance_Status,
  AWBStatus,
  BookingChannelDetails,
};
