import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import PayBillForm from './PayBillForm';

const PayBillModel = ({ onClose, isOpen }) => {
  return (
    <Modal
      onClose={onClose}
      size="xl"
      isOpen={isOpen}
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Bill Form</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PayBillForm />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PayBillModel;
