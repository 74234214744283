import React from "react";
import {
  Button,
  useDisclosure,
  ModalBody,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Text
} from "@chakra-ui/react";
const InsurancePolicy = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const scrollBehavior="inside";

  const btnRef = React.useRef(null);

  const policyDetails = [
    null,
    'Bulk shipment unless carried in ISO Tank containers or purpose-built road or rail tank Conveyance',
    'Bloodstock, livestock, and living creature ',
    'Cigarettes ',
    
    'Deeds, securities, treasury notes, and thelike excluding Passports.' ,
    'Designs, patterns, plans, manuscripts, and all such documents' ,
    'Money (Including banknotes and coin currencies) ',
    ' Precious stones and metals (Including but not limited to bullion, jewellery, gold, silver coins and such precious items/articles) ',
    'Stamps, Duty Stamps, Tickets, and the like' ,
    'Motor vehicles, aircraft,Watercraft, and the like ',
    'Arms, ammunition and explosives ',
    'Charity goods and/or humanitarian aid' ,
    'Temperature-Sensitive Cargo ' ,
    'Second-hand items/equipment ',
    'Prototypes and one-of-a-kind interest ',
    'Explosives ',
    'Gases (Including compressed air of gas appliances) ',
    'Indian Postal Articles' ,
    'Radioactivematerials / Corrosive Materials', 
    'Chemicals and Powers if not accompanied by chemical analysis report and Material safety data sheet and NonDGR declaration',
    'Bottled Spirit ',
    'Cement' ,
    'Fur and Fur Garments',
    'Hazardous goods.',
    'Flammable Cargo ',
    'Poisonous /Toxic Articles ',
    'Precious and Semi Precious items' ,
    'Food products containing liquids.' ,
    'Work of Art / Paintings ',
    'Temperature Sensitive Cargo will be covered subject to Institute Frozen food clause 1.1.86 with 24 hour time excess 30. Goods carried in tanker or as bulk innature'
  ]
   delete policyDetails[0]
 
  return (
    <>
      <Text color={"blue"} as={"span"} cursor={"pointer"} textDecoration={"underline"} mt={3} ref={btnRef} onClick={onOpen} fontFamily={"san serif"}>
        *Insurance Terms & Policy
      </Text>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior={scrollBehavior}
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Insurance Terms & Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Text fontWeight={700} color={'orange'}>Note: These Items are not covered</Text>
          <br/>
         {policyDetails.map((item,index)=>{
             return (
              <Text key={index}><span style={{fontWeight:'700', fontSize:'16px'}}>{index} .</span> {item}</Text>
             ) 
         })}
          
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InsurancePolicy;
