import {
  Flex,
  Text,
  Box,
  Heading,
  SimpleGrid,
  Menu,
  MenuItem,
  MenuDivider,
  MenuButton,
  HStack,
  VStack,
  MenuList,
  Avatar,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR } from '../utils/constant';
import './layout.scss';
import { getLoggedUser } from '../redux/slices/UserDetailsSlice';
import { setLayoutType } from '../redux/slices/ComponentFunctionSlice';
import QuixgoText from '../components/card/shared/QuixgoText';
import MainNavbar from '../components/card/shared/MainNavbar';

const text =
  'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque aliquid a placeat aliquam sunt,   Lorem ipsum, dolor sit amet consectetur adipisicing elit.';

const LayoutCard = ({ heading, text, onClick, ...props }) => {
  return (
    <>
      <Flex
        w={[240, 250]}
        h={[280, 320]}
        bg={'#274a69'}
        rounded={'xl'}
        color={'white'}
        justifyContent={'center'}
        flexDir={'column'}
        p={[5]}
        gap={2}
        _hover={{ backgroundColor: 'black' }}
        onClick={onClick}
        {...props}
      >
        <Heading color={'whiteAlpha.900'} size="xl" fontWeight={'bold'}>
          {heading}
        </Heading>
        <Text color={'whiteAlpha.700'} lineHeight={1.5}>
          {text}
        </Text>
      </Flex>
    </>
  );
};

const Layout = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };
  const handleB2bCardCLick = () => {
    dispatch(setLayoutType('b2b'));
  };
  const handleB2cCardCLick = () => {
    dispatch(setLayoutType('b2c'));
  };
  return (
    <Box
      backgroundImage={'/web_poster.webp'}
      backgroundSize={'contain'}
      backgroundRepeat={'repeat'}
    >
      <MainNavbar loggedUser={loggedUser} />
      <Flex height={['full', 'full', 'full', `calc(100vh - 56px)`]}>
        <Flex
          justifyContent={'space-around'}
          alignItems={'center'}
          height={['full', 'full', 'full', `calc(100vh - 156px)`]}
          rounded={'16px'}
          pos={'relative'}
          mt={10}
          p={[0, 5]}
          className="glassUI"
          w={'95vw'}
          mx={'auto'}
          flexDir={'column'}
          gap={5}
        >
          <>
            <Heading color={'whiteAlpha.900'} fontSize={'xl'} mx={'auto'}>
              Welcome {`${loggedUser.firstName} ${loggedUser.lastName}`}
            </Heading>
            <SimpleGrid columns={[1, 1, 1, 2]} spacingY={5}>
              <Flex
                flexDir={'column'}
                w={[240, '80vw', 540, '80%', 540]}
                h={[280, 'full', 'full', 320]}
                py={5}
                whiteSpace={'break-spaces'}
              >
                <QuixgoText fontSize={['lg', '5xl']} fontWeight={'bold'} />
                <Text color={'whiteAlpha.700'}>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Itaque aliquid a placeat aliquam sunt, sequi temporibus
                  voluptatibus autem impedit architecto praesentium consequuntur
                  accusamus doloremque nisi excepturi quis ea commodi? Quidem?
                </Text>
              </Flex>
              <Flex
                gap={5}
                alignItems={'center'}
                flexDir={['column', 'row']}
                justifyContent={'center'}
              >
                <Link to="/b2c/dashboard">
                  <LayoutCard
                    heading={'B2C'}
                    text={text}
                    onClick={handleB2cCardCLick}
                  />
                </Link>
                <Link to="/b2b/dashboard">
                  <LayoutCard
                    heading={'B2B'}
                    text={
                      'The trade between two business entities needs a wide pin code reach as it facilitates the delivery of bulk products at the right time.'
                    }
                    onClick={handleB2bCardCLick}
                  />
                </Link>
                <Link to="/allMerchant">
                  <LayoutCard heading={'All Customer'} text={text} />
                </Link>
              </Flex>
            </SimpleGrid>
          </>
        </Flex>
      </Flex>
      <Outlet />
    </Box>
  );
};

export default Layout;
