import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  SimpleGrid,
  Box,
  Text,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { SERVICEMODE } from '../../utils/utilityConstants';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import { useNavigate } from 'react-router-dom';
import {
  CODAmountControl,
  CustomDeliveryPincodeFormControl,
  CustomFormControl,
  CustomPincodeFormControl,
  PaymentModeControl,
  WeightFormControl,
} from './CalculatorField';

const Calculatepage = ({ close, type }) => {
  const loggedUser = useSelector(getLoggedUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  function onSubmit(values) {
    const params = {
      ...values,
      customerId: loggedUser.customerId,
    };
    dispatch({ type: 'CALCULATE_PRICE', payload: params, shipmentType: type });
    navigate('/calculatePrice');
    close();
  }
  return (
    <Box color="blackAlpha.800">
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <SimpleGrid columns={[1, 2]} spacing={2}>
            <CustomFormControl
              controlId="serviceType"
              label="Service Type"
              placeholder="Select Service Type"
              register={register}
              errors={errors}
              options={Object.keys(SERVICEMODE).map(mode => ({
                value: mode,
                label: SERVICEMODE[mode],
              }))}
            />
            <CustomPincodeFormControl
              controlId="pickUpPincode"
              label="Pickup Pincode"
              placeholder="PickUp Pincode"
              register={register}
              errors={errors}
            />
            <CustomDeliveryPincodeFormControl
              controlId="deliveryPincode"
              label="Delivery Pincode"
              placeholder="Delivery Pincode"
              register={register}
              errors={errors}
            />

            <WeightFormControl
              controlId="weight"
              label="Total Weight (Kg)"
              register={register}
              errors={errors}
            />

            <FormControl isInvalid={errors.length}>
              <FormLabel htmlFor="length">
                Length (cm)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="length"
                type="text"
                placeholder="Enter Length "
                {...register('length', {
                  required: 'This is required',

                  pattern: {
                    value: /^\d*\.?\d{0,2}$/,
                    message: 'Only Numbers are allowed',
                  },
                })}
                onChange={e => setLength(e.target.value)}
              />
              <FormErrorMessage>
                {errors.length && errors.length.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.width}>
              <FormLabel htmlFor="width">
                Width (cm)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="width"
                type="text"
                placeholder="Enter Width "
                {...register('width', {
                  required: 'This is required',
                  pattern: {
                    value: /^\d*\.?\d{0,2}$/,
                    message: 'Only Numbers are allowed',
                  },
                  // minLength: { value: 4, message: 'Minimum length should be 4' },
                })}
                onChange={e => setWidth(e.target.value)}
              />
              <FormErrorMessage>
                {errors.width && errors.width.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.height}>
              <FormLabel htmlFor="height">
                Height (cm)
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="height"
                type="text"
                placeholder="Enter Height "
                {...register('height', {
                  required: 'This is required',
                  pattern: {
                    value: /^\d*\.?\d{0,2}$/,
                    message: 'Only Numbers are allowed',
                  },
                  // minLength: { value: 4, message: 'Minimum length should be 4' },
                })}
                onChange={e => setHeight(e.target.value)}
              />
              <FormErrorMessage>
                {errors.height && errors.height.message}
              </FormErrorMessage>
            </FormControl>
            <PaymentModeControl
              controlId="paymentMode"
              label="Payment Mode"
              control={control}
              errors={errors}
            />

            {watch('paymentMode') === 'COD' && (
              <CODAmountControl
                controlId="CODamount"
                label="COD Amount"
                control={control}
                errors={errors}
              />
            )}
          </SimpleGrid>
          <Button
            mt={4}
            colorScheme="blue"
            isLoading={isSubmitting}
            type="submit"
          >
            Submit
          </Button>
          <Box>
            Volumetric Weight : {''}
            {(length && width && height) != null
              ? (length * width * height) / 5000
              : ''}
            kg or{' '}
            {(length && width && height) != null
              ? (length * width * height) / 5
              : ''}
            gram
          </Box>
        </>
      </form>
    </Box>
  );
};
export default Calculatepage;
