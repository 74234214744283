import { Flex, Icon, Text, Link } from '@chakra-ui/react';
import { HiOutlineMail } from 'react-icons/hi';
import { ImMobile } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getLayoutType } from '../../redux/slices/ComponentFunctionSlice';

export const ContactDetails = ({ row }) => {
  return (
    <Flex
      gap={1}
      flexDir={'column'}
      width={'70%'}
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      <Text>{row?.customers_detail?.customerName}</Text>
      <Text>
        <Icon as={ImMobile} color={'#4285F4'} /> {':'} {row?.cpMobile ?? '_'}
      </Text>
      <Text>
        <Icon as={HiOutlineMail} color={'#4285F4'} /> {':'}
        {row?.email ?? '_'}
      </Text>
    </Flex>
  );
};

export const CustomerDetails = ({ row }) => {
  const location = useLocation();
  const layoutType = useSelector(getLayoutType);
  return (
    <>
      <Text>{row?.customers_detail?.customerName}</Text>
      <Link
        textDecor={'underline'}
        as={NavLink}
        to={`/${layoutType}/customerdetails/${row?.customerId}`}
        params={{ merchantId: row?.customerId }}
        color="blue.500"
        state={{ from: location?.pathname }}
      >
        ID : {row?.customerId}
      </Link>
    </>
  );
};
