import React, { useEffect, useState } from 'react';
import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { ServiceProviderList, Zone } from '../../../../utils/utilityConstants';
import { getCustomerInfo } from '../../../../redux/slices/CustomerDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCustomerSetting } from '../../../../redux/slices/CustomerSettingSlice';
import { isFormdataAvailable } from '../../../../utils/utilsFunction';
import { useParams } from 'react-router-dom';
const DefaultPartnerForm = ({ onClose, defaultFormValue }) => {
  let { customerId } = useParams();
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerInfo);
  const [ServiceType, setServiceType] = useState();
  const [IsshipmetPartner, setIsshipmetPartner] = useState();
  const [ServiceProvider, setServiceProvider] = useState(
    defaultFormValue?.serviceProvider || ''
  );
  const [priorityType, setPriorityType] = useState(
    defaultFormValue?.priorityType || ''
  );
  const customerSetting = useSelector(getCustomerSetting);
  const customerCourierSettingData = customerSetting?.courierPartner;
  useEffect(() => {
    const provider = ServiceProviderList.find(
      provider => provider.value === ServiceProvider
    );
    if (provider?.service !== undefined && provider?.service !== null) {
      setServiceType(provider.service);
    }
  }, [ServiceProvider]);

  useEffect(() => {
    if (priorityType === 'default') {
      setIsshipmetPartner(false);
    } else {
      setIsshipmetPartner(true);
    }
  }, [priorityType]);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({ defaultValues: defaultFormValue });

  const onSubmit = async data => {
    try {
      const isAvailable = await isFormdataAvailable(
        customerCourierSettingData,
        data
      );
      const Params = {
        zone: data.zone,
        type: data.priorityType,
        data: {
          courier: data.serviceProvider,
          serviceType: data.serviceType,
        },
        customerId: customerId,
      };

      if (!!defaultFormValue) {
        if (JSON.stringify(data) === JSON.stringify(defaultFormValue)) {
          toast.error(`This form did not detect any changes`, toastOptions);
        } else {
          dispatchAndClose('PUT_DEFAULT_COURIER', Params);
        }
      } else {
        if (isAvailable) {
          toast.error(
            `Data Already Available, Edit Previous Data`,
            toastOptions
          );
        } else {
          dispatchAndClose('PUT_DEFAULT_COURIER', Params);
        }
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`, toastOptions);
    }
  };

  const toastOptions = {
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    status: 'error',
    theme: 'colored',
  };

  const dispatchAndClose = (actionType, postData) => {
    dispatch({
      type: actionType,
      postData: JSON.stringify(postData),
    });
    onClose();
  };

  return (
    <SimpleGrid columns={[1]} spacing={[2, 5]}>
      <FormControl isInvalid={errors.zone}>
        <FormLabel>Select Zone</FormLabel>
        <Controller
          name="zone"
          control={control}
          rules={{ required: 'Zone is required' }}
          render={({ field }) => (
            <>
              <Select {...field} w={'220px'} placeholder="Select Zone">
                {Zone?.map(item => (
                  <option value={item?.value} key={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.zone && errors?.zone?.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>

      <FormControl isInvalid={errors.priorityType}>
        <FormLabel>Select Priority</FormLabel>
        <Controller
          name="priorityType"
          control={control}
          rules={{ required: 'Priority is required' }}
          render={({ field }) => (
            <>
              <Select
                {...field}
                w={'220px'}
                placeholder="Select Priority"
                onChange={e => {
                  field.onChange(e);
                  setPriorityType(e.target.value);
                }}
              >
                {['Default', 'Primary', 'Secondary'].map(item => (
                  <option key={item} value={item.toLowerCase()}>
                    {item}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.priorityType && errors?.priorityType?.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>

      <FormControl isInvalid={errors.serviceProvider}>
        <FormLabel>Select Service Provider</FormLabel>
        <Controller
          name="serviceProvider"
          control={control}
          rules={{ required: 'Service Provider is required' }}
          render={({ field }) => (
            <>
              <Select
                {...field}
                w={'220px'}
                placeholder="Select Service Provider"
                onChange={e => {
                  field.onChange(e);
                  setServiceProvider(e.target.value);
                }}
              >
                {IsshipmetPartner &&
                  ServiceProviderList?.map(provider => (
                    <option key={provider?.value} value={provider.value}>
                      {provider.name}
                    </option>
                  ))}
                {!IsshipmetPartner && (
                  <>
                    <option value="cheapest">The Cheapest Courier First</option>
                    <option value="quixgo">Quixgo Priority</option>
                    <option value="fastest">Fastest Service</option>
                  </>
                )}
              </Select>
              <FormErrorMessage>
                {errors.serviceProvider && errors?.serviceProvider?.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>

      <FormControl isInvalid={errors.serviceType}>
        <FormLabel>Select Service Type</FormLabel>
        <Controller
          name="serviceType"
          control={control}
          rules={{ required: 'Service Type is required' }}
          render={({ field }) => (
            <>
              <Select {...field} w={'220px'} placeholder="Select Service Type">
                {IsshipmetPartner &&
                  ServiceType?.map(serviceType => (
                    <option key={serviceType?.value} value={serviceType.value}>
                      {serviceType.label}
                    </option>
                  ))}
                {!IsshipmetPartner && (
                  <>
                    <option value={'SF'}>{'SURFACE'}</option>
                    <option value={'EXP'}>{'EXPRESS'}</option>
                  </>
                )}
              </Select>
              <FormErrorMessage>
                {errors.serviceType && errors?.serviceType?.message}
              </FormErrorMessage>
            </>
          )}
        />
      </FormControl>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          isLoading={isSubmitting}
          type="submit"
          colorScheme="blue"
          aria-label="Save"
        >
          Save
        </Button>
      </form>
    </SimpleGrid>
  );
};

export default DefaultPartnerForm;
