import {
  CategorySelect,
  ComparisonCategory,
  CustomSelect,
  SearchInCategory,
} from '../../components/CustomTable/CustomFilterComponent';
import DateRangePickerComponent from '../../components/CustomTable/DateRangePickerComponent';
import {
  CreatedAtDate,
  DocumemtVerifiedDetails,
  ShipmentTypeDetails,
} from '../AllMerchant/AllMerchantColumnData';
import { ContactDetails, CustomerDetails } from './B2c_MerchantColumnData';

export const TableBody = [
  CustomerDetails,
  ContactDetails,
  ShipmentTypeDetails,
  DocumemtVerifiedDetails,
  CreatedAtDate,
];

export const SearchCategoryOption = [
  { label: 'Customer Id', value: 'customerId', default: true },
  { label: 'Minimum Balance', value: 'minimumBalance', default: false },
  { label: 'Wallet Amount', value: 'walletAmount', default: false },
  { label: 'CashBack', value: 'cashBack', default: false },
  { label: 'Credit Limit', value: 'creditLimit', default: false },
];

export const isDiscountApplicable = [
  { label: 'True', value: true, default: true },
  { label: 'False', value: false, default: false },
];
export const isActive = [
  { label: 'True', value: true, default: true },
  { label: 'False', value: false, default: false },
];
export const paymentMode = [
  { label: 'Prepaid', value: 'prepaid', default: true },
  { label: 'Credit', value: 'credit', default: false },
];
export const B2c_MerchantList_Filter_Component = [
  {
    component: CategorySelect,
    props: {
      searchCategoryOption: SearchCategoryOption,
    },
  },
  {
    component: ComparisonCategory,
    IsGridChild: false,
  },
  {
    component: SearchInCategory,
  },

  {
    component: DateRangePickerComponent,
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: paymentMode,
      placeholder: 'Payment mode',
      valueType: 'paymentMode',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: isActive,
      placeholder: 'Is Active',
      valueType: 'isActive',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: isDiscountApplicable,
      placeholder: 'is Discount Applicable',
      valueType: 'isDiscountApplicable',
    },
  },
];
