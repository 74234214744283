import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllPickupPointList } from '../../../redux/slices/PickupPointsDetailsSlice';
import {
  getCustomerInfo,
  getKycDocs,
} from '../../../redux/slices/MerchantDetailsSlice';
import './Manifest.scss';
import BarcodeGenerator from './BarcodeGenerator';
import { getManifestPageSize } from '../../../redux/slices/ComponentFunctionSlice';
import { getCustomerSetting } from '../../../redux/slices/CustomerSettingSlice';
const Label = ({ data }) => {
  const dispatch = useDispatch();
  const kycDocs = useSelector(getKycDocs);
  const pickupLocations = useSelector(getAllPickupPointList);
  const customerInfo = useSelector(getCustomerInfo);
  const { manifestSetting } = useSelector(getCustomerSetting);
  const ManifestClass = useSelector(getManifestPageSize);

  const {
    weight,
    products,
    quixgoLogo,
    customerLogo,
    returnAddress,
    sellerContactDetails,
    invoiceValue,
  } = manifestSetting || {};

  const {
    awbNumber,
    bookingDate,
    paymentMode,
    shipmentPartner,
    serviceTypes,
    pickupAddress: pickupAddId,
  } = data;

  const pickUpAddress = pickupLocations.find(
    address => address.addressId === pickupAddId
  );

  return (
    <Flex className={`container${ManifestClass}`}>
      {!quixgoLogo && !customerLogo && (
        <Grid className={`logo-container${ManifestClass}`}>
          <GridItem className={`grid-item${ManifestClass}`}>
            {!quixgoLogo && <Image src="/quixgo.jpg" alt="Quixgo" />}
          </GridItem>
          <GridItem className={`grid-item${ManifestClass}`}>
            {!customerLogo && kycDocs?.logo?.doc && (
              <Image
                src={`data:image/jpeg;base64,${kycDocs?.logo?.doc}`}
                alt="logo"
              />
            )}
          </GridItem>
        </Grid>
      )}

      <Flex
        alignItems="center"
        borderBottom="1px solid black"
        h={ManifestClass === '_3_5' ? '50px' : '60px'}
        overflow="hidden"
        justifyContent="center"
        border="1px solid black"
      >
        <BarcodeGenerator
          barcodeValue={awbNumber}
          barcodeWidth={2}
          barcodeHeight={ManifestClass === '_3_5' ? 30 : 40}
          barcodeMargin={ManifestClass === '_3_5' ? 1 : 3}
          barcodeFontSize={ManifestClass === '_3_5' ? 10 : 15}
          barcodeTextMargin={ManifestClass === '_3_5' ? 0 : -2}
        />
      </Flex>

      <Box className={`ship-to-container${ManifestClass}`}>
        <Text fontWeight="bold" className={`ship-to-text${ManifestClass}`}>
          Ship To: {data?.deliveryAddress_name}
        </Text>
        <Text className={`ship-to-text${ManifestClass}`}>
          {data?.deliveryAddress_add1}, {data?.deliveryAddress_add2},
          {data?.deliveryAddress_city},{data?.deliveryAddress_lmk}
          {data?.deliveryAddress_state}, {data?.deliveryAddress_pincode}
        </Text>
      </Box>

      <Grid className={`details-container${ManifestClass}`}>
        <GridItem className={`order-details${ManifestClass}`}>
          <SimpleGrid columns={2} verticalAlign="top">
            <Text className={`order-text${ManifestClass}`}>Order No</Text>
            <Text className={`order-text${ManifestClass}`}>
              {data?.productDetails_orderNum}
            </Text>
            <Text className={`order-text${ManifestClass}`}>Invoice Date</Text>
            <Text className={`order-text${ManifestClass}`}>{bookingDate}</Text>
            {!weight && (
              <Text className={`order-text${ManifestClass}`}>Weight (kgs)</Text>
            )}
            <Text className={`order-text${ManifestClass}`}>
              {data?.productDetails_weight}
            </Text>
            <Text className={`order-text${ManifestClass}`}>
              Dimensions (cms)
            </Text>
            <Text className={`order-text${ManifestClass}`}>
              {data?.productDetails_height} X {data?.productDetails_width} X{' '}
              {data?.productDetails_length}
            </Text>
          </SimpleGrid>
        </GridItem>

        <GridItem className={`other-details-container${ManifestClass}`}>
          <Flex flexDir="column" gap={ManifestClass === '_3_5' ? 1 : 1.5}>
            <Box>
              <Text className={`other-details-text${ManifestClass}`}>
                {shipmentPartner}
              </Text>
              <Text className={`other-details-text${ManifestClass}`}>
                {serviceTypes}
              </Text>
            </Box>
            <Box>
              <Text className={`other-details-text${ManifestClass}`}>
                {paymentMode}
              </Text>
              {!invoiceValue && paymentMode === 'Prepaid' && (
                <Text className={`other-details-text${ManifestClass}`}>
                  {data?.productDetails_invoice}
                </Text>
              )}
              {paymentMode === 'COD' && (
                <Text className={`other-details-text${ManifestClass}`}>
                  INR {data?.productDetails_cod}
                </Text>
              )}
            </Box>
          </Flex>
        </GridItem>
      </Grid>

      {!sellerContactDetails && (
        <Box className={`seller-details${ManifestClass}`}>
          <Text color="black">
            <Text
              as="span"
              fontWeight="bold"
              textTransform="capitalize"
              color="black"
            >
              Seller: {customerInfo?.customerName} ,
            </Text>
            {pickUpAddress?.address1},{pickUpAddress?.address2},
            {pickUpAddress?.pincode}
          </Text>
        </Box>
      )}

      {!products && (
        <Box className={`prodDetails${ManifestClass}`}>
          <Text color="black">Product : {data?.productDetails_pName}</Text>
        </Box>
      )}

      <Grid className={`barcode-container${ManifestClass}`}>
        <GridItem colSpan={11} borderRight="1px solid black">
          <Flex className={`barcode-container-bar${ManifestClass}`}>
            <BarcodeGenerator
              barcodeValue={data?.productDetails_orderNum}
              barcodeHeight={ManifestClass === '_3_5' ? 32 : 40}
              barcodeWidth={1.5}
              barcodeMargin={ManifestClass === '_3_5' ? 1 : 3}
              barcodeTextMargin={ManifestClass === '_3_5' ? -1 : -2}
              barcodeFontSize={ManifestClass === '_3_5' ? 10 : 15}
            />
          </Flex>
        </GridItem>
        <GridItem className={`quantity-container${ManifestClass}`}>
          <Text color="black">Qty</Text>
          <Text color="black">{data?.productDetails_quantity}</Text>
        </GridItem>
      </Grid>

      {!returnAddress && (
        <Box className={`return-address-container${ManifestClass}`}>
          <Text className={`return-address-text${ManifestClass}`}>
            <Text
              color="black"
              fontWeight="bold"
              as="span"
              textTransform="capitalize"
            >
              Return Address : {customerInfo?.customerName} ,
            </Text>
            {pickUpAddress?.address1},{pickUpAddress?.address2},{' '}
            {pickUpAddress?.pincode}
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default Label;
