import axios from 'axios';

export const httpCall = async config => {
  let headerConfig;
  if (config?.headers) {
    headerConfig = {
      ...config,
      headers: {
        ...config.headers,
        'Cache-Control': 'no-store,no-cache,',
        Pragma: 'no-cache',
      },
    };
  } else {
    headerConfig = {
      ...config,
      headers: {
        'Cache-Control': 'no-store,no-cache,',
        Pragma: 'no-cache',
      },
    };
  }
  try {
    const response = await axios(headerConfig);
    return {
      success: true,
      data: response.data,
      ...response,
    };
  } catch (err) {
    console.log(err);
    return { success: false, err: err.response };
    // }
  }
};

///// User apis---------------------
export const REGISTER_USER_URL = '/users/registerUser';
export const LOGIN_URL = '/users/login';
export const SEND_OTP_URL = '/users/sendotp';
export const RESEND_OTP_URL = '/users/resendotp';
export const VARIFY_OTP_URL = '/users/verifyOtp';
export const UPDATE_PASSWORD_URL = '/users/changePassword';
export const GET_ALL_USERS_URL = '/users/getAllUser';
export const Add_SHIPPING_PARTNER_URL =
  '/courierProvider/registerServiceProvider';
export const GET_SHIPPING_PARTNERS_URL =
  '/courierProvider/getAllServiceProvider';
export const GET_CUSTOMERS_LIST_URL = '/customers/getAllCustomer';
export const GET_CUSTOMER_INFO_URL = '/customers/getCustomerInfo';
export const UPLOAD_SERVICEABILITY_URL = '/pincodeCoverage/uploadPincodes';
export const VERIFY_CUSTOMER_URL = '/customers/verifyDetails';
export const CUSTOMER_SETTINGS_UPDATE_URL = '/customers/settings/update';
export const DOWNLOAD_SERVICEABILITY_SAMPLE_URL =
  '/download/sample/pincodeCoverage';
export const UPLOAD_PRICE_URL = '/serviceCharge/uploadPrice';
export const DOWNLOAD_PRICE_SAMPLE_URL = '/download/sample/price';
export const UPLOAD_CUSTOMER_PRICE_URL = '/serviceCharge/customer/uploadFile';
export const GET_PRICE_URL = '/serviceCharge/customer/price';
export const CALCULATE_PRICE_URL = '/serviceCharge/admin/calculateCharge';

export const ADD_OFFERCODE_URL = '/OfferCodes/registerOfferCode/';
export const GET_ALL_OFFERCODE_URL = '/OfferCodes/getOfferCodes/';
export const ADD_OFFICE_LOCATION = '/officeLocation/registerOfficeLocation';
export const GET_ALL_OFFICE_LOCATION_DETAILS_URL =
  '/officeLocation/getAllOfficeLocation';
export const ADD_DEPARTMENT_DETAILS_URL = '/department/registerDepartment';
export const GET_ALL_DEPARTMENT_DETAILS_URL = '/department/getAllDepartment';

export const GET_ALL_DESIGNATION_DETAILS_URL = '/designation/getAllDesignation';
export const ADD_DESIGNATION_DETAILS = '/designation/registerDesignation';

export const GET_ALL_IDTYPE_DETAILS_URL = '/identityType/getAllIdentityType';
export const ADD_IDTYPE_DETAILS = '/identityType/registerIdentityType';

export const GET_ALL_EMPLOYEE_URL = '/employee/getAllEmployee';
export const ADD_EMPLOYEE_URL = '/employee/addEmployee';

export const SET_REFERRAL_CODE_URL = '/referralCode/addReferralCode';
export const GET_REFERRAL_CODE_URL = '/referralCode/getAllReferralCode';

////SHIPMENT URL
export const GET_ALL_SHIPMENT_LIST = '/shipment/getAllShipmentInfo';
export const GET_USER__SHIPMENT_LIST_URL =
  '/shipment/allShipmentStatusByCustomer';
export const GET_AWB_STATUS_URL = '/shipmentStatus/statusTrack';
export const CANCEL_SHIPMENT_URL = '/shipment/cancelBooking';
export const GET_USER_PICKUP_LIST_URL = '/address/getByCustomerId';
export const GET_PICKUP_ADDRESS_URL = '/address/getPickupAddInfo';
export const GET_ADMIN_DASHBOARD_DATA_URL = '/dashboard/admin';
export const GET_USER_DASHBOARD_DATA_URL = '/dashboard/customer';

export const GET_MERCHANT_TRANSACTIONS_URL =
  '/transaction/allTransactionStatusByCustomer';
export const GET_ALL_TRANSACTIONS_URL = '/transaction/getAllTransactionDetails';

export const UPDATE_BANKING_DETAILS_URL =
  '/customerBankDetails/updateBankDetails';
export const GET_BANKING_DETAILS_URL = '/customerBankDetails/getBankDetails';
export const VERIFY_BANK_DETAILS_URL = '/customerBankDetails/verifyBankDetails';
export const GET_KYC_DETAILS_URL = '/customers/getCustomerDocs';

// Insura
export const GET_ALL_INSURANCES_URL = '/shipmentStatus/insurance/all';
export const GET_WEIGHT_DISCREPANCY_URL = '/download/sample/discrepancy';
export const UPLOAD_WEIGHT_DISCREPANCY_URL = '/discrepancy/upload';
export const GET_ALL_WEIGHT_DISCREPANCY_URL = '/discrepancy/shipments';

export const PAY_REMITTANCE_URL = '/pay/remittance';
export const GET_ALL_REMITTANCE_URL = '/pay/getAllTransaction';
export const ADD_ALL_NOTES_URL = '/pay/notes';

export const GET_DUE_REMITTANCE_URL = '/shipmentStatus/remittanceDue';

export const GET_ALL_PICKUP_ADDRESS_URL = '/address/getAllPickupAdd';
export const HANDLE_DELETE_IN_PICKUPTABLE_URL = '/address/deletePickupAdd';
export const GET_CUSTOMER_Id_URL = '/address/getByCustomerId';
export const REGISTER_PICKUP_LOCATION_URL = '/address/registerPickupAdd';

export const EDIT_PICKUP_FORMDATA_URL = '/address/updatePickupAdd';
export const GET_ALL_PICKUP_URL = '/address/getByCustomerId';
export const DOWNLOAD_BOOKING_SAMPLEFILE_URL =
  '/shipment/downloadBookingSampleFile';
export const UPLOAD_BOOKING_URL = '/shipment/uplaodBookingFile';
export const SINGLE_BOOKING_URL = '/shipment/singleShipmentBooking';
export const GET_ALL_SHIPMENT_URL = '/shipment/allShipmentStatusByCustomer';
export const ADD_KYC_DETAILS_URL = '/customers/kyc';
export const UPDATE_KYC_DETAILS_URL = '/customers/updateDocuments';
export const GET_ALL_IDENTITY_URL = '/identityType/getAllIdentityType';
export const UPDATE_CUSTOMER_INFO_URL = '/customers/updateCustomer';
export const UPDATE_CUSTOMER_ADDRESS_URL = '/address/updatePickupAdd';
export const GET_INVOICE_URL = '/download/invoice';

export const SAVE_DEFAULT_ADDRESS_URL = '/settings/address';
export const SAVE_DEFAULT_BANKACCOUNT_URL = '/settings/default/bankAccount';
export const SAVE_COURIER_SETTINGS_URL = '/settings/courier';
export const SAVE_PACKAGE_VOLUME_URL = '/settings/package';
export const GET_COURIER_SETTINGS_URL = "/settings";
export const SAVE_MANIFEST_SETTING_URL = '/settings/manifestSetting';
export const GET_PINCODE_DATA_URL = "/pincodeCoverage/getPincodeInfo";
