import React, { useEffect, useState } from 'react';
import { Box, Flex, Select } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getMerchantTransactionList,
  getTransactionDetailsLoading,
  setTransactionDetailsLoading,
} from '../../redux/slices/TransactionDetailsSlice';
import { MERCHANT_TRANSACTION_COLUMNS } from './TransactionColumns/MerchantTransactionColumns';
import FilteredTransactions from './FilteredTransactions';
import TableLoader from '../../components/Loader/TableLoader';
import DateSection from '../../pages/DateSection';
import { dateCalc } from '../../utils/utilsFunction';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import { IoMdArrowDropdown } from 'react-icons/io';
const TransactionContainer = () => {
  let { customerId } = useParams();
  const dispatch = useDispatch();
  const transactionList = useSelector(getMerchantTransactionList);
  const isLoading = useSelector(getTransactionDetailsLoading);
  const loggedUser = useSelector(getLoggedUser);
  const [selectedValue, setSelectedValue] = useState('All Transaction');
  const TransactionContainer = [
    {
      label: 'All Transaction',
      exxcelFileName: 'Quixgo_All_Transaction_List.csv',
    },
    {
      label: 'Shipment charge',
      filterBy: 'dr',
    },
    {
      label: 'Account Recharge',
      filterBy: 'cr',
    },
  ];

  useEffect(() => {
    if (loggedUser.empid) {
      handleOnChange();
      dispatch(setTransactionDetailsLoading(true));
    }
  }, [loggedUser.empid]);
  const handleOnChange = (
    startDate = dateCalc.week,
    endDate = dateCalc.today
  ) => {
    const params = {
      empId: loggedUser.empid,
      customerId: customerId,
      startDate: startDate,
      endDate: endDate,
    };
    if (loggedUser.empid) {
      dispatch(setTransactionDetailsLoading(true));
      dispatch({ type: 'GET_MERCHANTS_Transactions_LIST', postData: params });
    }
  };
  return (
    <>
      <Flex
        gap={2}
        flexDir={['column', 'row']}
        alignItems={'center'}
        pos={[
          'relative',
          'relative',
          'relative',
          'relative',
          'relative',
          'absolute',
        ]}
      >
        <Select
          bg="blue.500"
          borderColor="blue.500"
          color="white"
          defaultValue={selectedValue}
          icon={<IoMdArrowDropdown />}
          w={[200]}
          onChange={e => setSelectedValue(e.target.value)}
          textTransform={'capitalize'}
        >
          {TransactionContainer.map(item => (
            <option
              style={{ color: 'black' }}
              key={item?.label}
              value={item?.label}
            >
              {item?.label}
            </option>
          ))}
        </Select>
        <Box maxH={['full', 'full', 12]} my={1}>
          <DateSection onChange={handleOnChange} />
        </Box>
      </Flex>

      {TransactionContainer.map(item => {
        if (selectedValue === item.label) {
          return isLoading ? (
            <TableLoader key={item?.label} />
          ) : (
            <FilteredTransactions
              key={item?.label}
              TableHeight={['40vh', '54vh', '64vh', '64vh', '68vh', '75vh']}
              data={transactionList}
              column={MERCHANT_TRANSACTION_COLUMNS}
              filterBy={item?.filterBy}
              excelFilename={item?.exxcelFileName}
            />
          );
        }
      })}
    </>
  );
};

export default TransactionContainer;
