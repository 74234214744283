import React from 'react';
import { Flex, Text, Icon, Heading } from '@chakra-ui/react';
import { BsDatabaseX } from 'react-icons/bs';

const NoDataAvailable = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h={'60vh'}
      textAlign="center"
    >
      <Icon as={BsDatabaseX} boxSize={16} />
      <Heading as={'h1'} fontSize={30} fontWeight="bold">
        No Record Available
      </Heading>
      <Text mt={2} fontSize={20} color="gray.500">
        There is currently no data to display.
      </Text>
    </Flex>
  );
};

export default NoDataAvailable;
