import React from 'react';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { MdEdit } from 'react-icons/md';
import DefaultShipmentPartnerList from './DefaultShipmentPartnerList';
import AddCourierDrawer from './AddCourierDrawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCourierEdit,
  setCourierEdit,
} from '../../../../redux/slices/ComponentFunctionSlice';
import { getCustomerSetting } from '../../../../redux/slices/CustomerSettingSlice';
import Card from '../../../../components/card';

const DefaultPartner = () => {
  const dispatch = useDispatch();
  const isEdit = useSelector(getCourierEdit);
  const customerSetting = useSelector(getCustomerSetting);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleEdit = () => {
    dispatch(setCourierEdit(!isEdit));
  };
  console.log(customerSetting?.courierPartner);
  return (
    <>
      <Flex gap={4} justifyContent={'center'}>
        <Button colorScheme="blue" onClick={onOpen} leftIcon={<GrAdd />}>
          Add Courier
        </Button>
        {customerSetting?.courierPartner !== null &&
          customerSetting?.courierPartner !== undefined && (
            <Button
              colorScheme="blue"
              onClick={handleEdit}
              leftIcon={<MdEdit color="black" />}
            >
              Edit
            </Button>
          )}
      </Flex>
      <AddCourierDrawer isOpen={isOpen} onClose={onClose} />
      <DefaultShipmentPartnerList />
    </>
  );
};

export default DefaultPartner;
