import React, { useState, useEffect } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  FormControl,
  Input,
  TableContainer,
  Thead,
  Th,
  IconButton,
  FormErrorMessage,
  Heading,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerSetting } from '../../../redux/slices/CustomerSettingSlice';
import { useParams } from 'react-router-dom';
const VolumetricTable = () => {
  const pageColor = '#274a69';
  const dispatch = useDispatch();
  let { customerId } = useParams();
  const customerSetting = useSelector(getCustomerSetting);
  const [volumes, setVolumes] = useState([]);

  useEffect(() => {
    const dataArray = [];
    for (const key in customerSetting?.packageDetails) {
      const item = {
        weight: key,
        ...customerSetting?.packageDetails[key],
      };
      dataArray.push(item);
    }
    localStorage.setItem('volumetric_data', JSON.stringify(dataArray));
  }, [customerSetting]);
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    const storedVolumes = JSON.parse(
      localStorage.getItem('volumetric_data') || '[]'
    );
    setVolumes(storedVolumes);
  }, []);
  function doesMatchExist(array, searchData) {
    for (const item of array) {
      if (item.weight === searchData.weight) {
        return true;
      }
    }
    return false;
  }
  const onSubmit = data => {
    if (doesMatchExist(volumes, data)) {
      toast.error('Data Available', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
    } else {
      const updatedVolumes = [...volumes, data];
      localStorage.setItem('volumetric_data', JSON.stringify(updatedVolumes));
      setVolumes(updatedVolumes);
      reset({
        height: '',
        length: '',
        width: '',
      });
      setValue('weight', null);
    }
  };
  function transformData(data) {
    const transformed = {};
    data.forEach(item => {
      const { weight, ...rest } = item;
      transformed[weight] = rest;
    });
    return transformed;
  }
  const onDelete = index => {
    const updatedVolumes = volumes.filter((_, i) => i !== index);
    localStorage.setItem('volumetric_data', JSON.stringify(updatedVolumes));
    setVolumes(updatedVolumes);
  };
  const handleVolumeData = () => {
    const params = {
      customerId: customerId,
      packageDetails: transformData(volumes),
    };
    dispatch({ type: 'PUT_PACKAGE_VOLUME', postData: params });
    localStorage.removeItem('volumetric_data');
  };

  const weightOptions = [];
  for (let i = 1; i <= 50; i++) {
    if (i < 4) {
      weightOptions.push({
        value: `${i + 0.5}`,
        label: `${i + 0.5} Kg`,
      });
    }
    weightOptions.push({
      value: `${i}`,
      label: `${i} Kg`,
    });
  }
  return (
    <>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        mt={10}
        gap={4}
        flexDir={['column', 'row']}
      >
        <Heading as={'h1'} color="#274a69" fontSize={['1.3rem', '2rem']}>
          Package Volume Data
        </Heading>
        <Button
          isDisabled={volumes?.length <= 0}
          colorScheme="blue"
          mb={2}
          onClick={handleVolumeData}
        >
          Save
        </Button>
      </Flex>
      <TableContainer
        pos={'relative'}
        mt={1}
        w={['100vw', '100vw', '100vw', '80vw', '70vw']}
        rounded={5}
        mx={'auto'}
        p={0}
        h={'60vh'}
        overflowY={'auto'}
      >
        <Table variant="simple" border={`2px solid ${pageColor}`}>
          <Thead bg={pageColor} pos={'sticky'} top={0} zIndex={1}>
            <Tr>
              {['Weight', 'Height', 'Width', 'Length', 'Action'].map(item => (
                <Th key={item} color={'white'}>
                  {item}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {volumes?.map((item, index) => (
              <Tr key={item?.weight}>
                <Td pl={20}>{item?.weight} KG</Td>
                <Td>{item?.height} cm </Td>
                <Td>{item?.width} cm</Td>
                <Td>{item?.length} cm</Td>
                <Td>
                  <IconButton
                    icon={<MdDelete />}
                    colorScheme="red"
                    onClick={() => onDelete(index)}
                  />
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td w={250}>
                <FormControl isInvalid={errors.weight} mb={4}>
                  <Controller
                    name="weight"
                    control={control}
                    rules={{ required: 'Weight is required' }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          options={weightOptions}
                          value={weightOptions.find(
                            option => option.value === value
                          )}
                          isClearable
                          placeholder="Select a weight"
                          onChange={selectedOption => {
                            onChange(
                              selectedOption ? selectedOption.value : null
                            );
                            setValue(
                              'weight',
                              selectedOption ? selectedOption.value : null
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {errors.weight?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  />
                </FormControl>
              </Td>
              <Td>
                <FormControl isInvalid={errors.height}>
                  <Input
                    {...register('height', {
                      required: 'This is required',
                    })}
                    placeholder="Height"
                  />
                  <FormErrorMessage>{errors?.height?.message}</FormErrorMessage>
                </FormControl>
              </Td>
              <Td>
                <FormControl isInvalid={errors.length}>
                  <Input
                    {...register('length', {
                      required: 'This is required',
                    })}
                    placeholder="Length"
                  />
                  <FormErrorMessage>{errors?.length?.message}</FormErrorMessage>
                </FormControl>
              </Td>
              <Td>
                <FormControl isInvalid={errors.width}>
                  <Input
                    {...register('width', {
                      required: 'This is required',
                    })}
                    placeholder="Width"
                  />
                  <FormErrorMessage>{errors?.width?.message}</FormErrorMessage>
                </FormControl>
              </Td>
              <Td>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <IconButton
                    icon={<AiOutlinePlus />}
                    mt={4}
                    colorScheme="teal"
                    type="submit"
                  />
                </form>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VolumetricTable;
