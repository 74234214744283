import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Box, Text, Grid, GridItem } from '@chakra-ui/react';
import { SERVICEMODE } from '../../utils/utilityConstants';
import DimensionInput from './DimensionInput';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CODAmountControl,
  CustomDeliveryPincodeFormControl,
  CustomFormControl,
  CustomPincodeFormControl,
  InvoiceFormControl,
  PaymentModeControl,
  WeightFormControl,
} from './CalculatorField';
const B2BcalculatorForm = ({ close, type }) => {
  const loggedUser = useSelector(getLoggedUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      volume: [{ Qty: '', height: '', length: '', width: '' }],
    },
  });

  function convertToCm(value, unit) {
    if (unit.toLowerCase() === 'feet') {
      return value * 30.48;
    } else if (unit?.toLowerCase() === 'inch') {
      return value * 2.54;
    }
    return value;
  }

  function onSubmit(values) {
    let errorOccurred = false;
    try {
      values?.volume.forEach(entry => {
        entry.height = convertToCm(parseFloat(entry?.height), values?.unit);
        entry.length = convertToCm(parseFloat(entry?.length), values?.unit);
        entry.width = convertToCm(parseFloat(entry?.width), values?.unit);
      });
    } catch (error) {
      errorOccurred = true;
      console.error('Error during form submission:', error);
    } finally {
      if (errorOccurred) {
        console.log('Form submission failed');
      } else {
        const params = {
          ...values,
          customerId: loggedUser?.customerId,
        };
        dispatch({
          type: 'CALCULATE_PRICE',
          payload: params,
          shipmentType: type,
        });
        navigate('/B2bCalculatePrice');
        close();
      }
    }
  }

  return (
    <Box color="blackAlpha.800">
      <>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <CustomFormControl
            controlId="serviceType"
            label="Service Type"
            placeholder="Select Service Type"
            register={register}
            errors={errors}
            options={Object.keys(SERVICEMODE).map(mode => ({
              value: mode,
              label: SERVICEMODE[mode],
            }))}
          />
          <CustomPincodeFormControl
            controlId="pickUpPincode"
            label="Pickup Pincode"
            placeholder="PickUp Pincode"
            register={register}
            errors={errors}
          />
          <CustomDeliveryPincodeFormControl
            controlId="deliveryPincode"
            label="Delivery Pincode"
            placeholder="Delivery Pincode"
            register={register}
            errors={errors}
          />
          <PaymentModeControl
            controlId="paymentMode"
            label="Payment Mode"
            control={control}
            errors={errors}
          />

          {watch('paymentMode') === 'COD' && (
            <CODAmountControl
              controlId="CODamount"
              label="COD Amount"
              control={control}
              errors={errors}
            />
          )}

          <InvoiceFormControl
            controlId="invoice"
            label="Invoice Value"
            register={register}
            errors={errors}
          />

          <WeightFormControl
            controlId="weight"
            label="Total Weight (Kg)"
            register={register}
            errors={errors}
          />

          <GridItem colSpan={2}>
            <Text fontSize={'18px'} color={'blackAlpha.700'}>
              Dimensions{' '}
            </Text>

            <DimensionInput
              register={register}
              control={control}
              errors={errors}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Button
                mt={4}
                colorScheme="blue"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </form>
          </GridItem>
        </Grid>
      </>
    </Box>
  );
};
export default B2BcalculatorForm;
