import { Flex, VStack } from '@chakra-ui/react';
import React from 'react';
import FilterContainer from '../../components/CustomTable/FilterContainer';
import {
  B2c_MerchantList_Filter_Component,
  TableBody,
} from './B2cMerchantList_Constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMerchantLoader,
  getMerchants,
} from '../../redux/slices/MerchantDetailsSlice';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import Pagination from '../../components/CustomTable/Pagination';
import CustomTable from '../../components/CustomTable';
import NoDataAvailable from '../../components/Loader/NoDataAvailable';
import { TableHeader } from '../AllMerchant/AllMerchant_Constant';

const B2cMerchantList = ({ CustomerType }) => {
  const dispatch = useDispatch();
  const merchantList = useSelector(getMerchants);
  const loggedUser = useSelector(getLoggedUser);
  const isMerchantLoading = useSelector(getMerchantLoader);
  const getData = (limit, page, filterFormData) => {
    dispatch({
      type: 'GET_ALL_CUSTOMERS',
      CustomerType: CustomerType,
      postData: {
        empId: loggedUser.empid,
        limit: limit,
        page: page,
        filter: filterFormData,
      },
    });
  };
  return (
    <VStack mx={'auto'} spacing={5} pt={5}>
      <FilterContainer
        mainComponent={B2c_MerchantList_Filter_Component}
        styleprops={{
          templateColumns: [
            'auto',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
          ],
        }}
      />
      <Flex w={'100%'} justifyContent={'flex-end'}>
        <Pagination
          paginationProps={{ count: merchantList?.count, getData: getData }}
        />
      </Flex>
      {merchantList?.rows?.length > 0 ? (
        <CustomTable
          tableData={{
            PropsData: merchantList?.rows,
            Header: TableHeader,
            Body: TableBody,
            isDataLoading: isMerchantLoading,
          }}
          styleprops={{
            w: ['90vw'],
            h: ['90vh', '55vh'],
            overflowY: 'auto',
          }}
        />
      ) : (
        <NoDataAvailable />
      )}
    </VStack>
  );
};

export default B2cMerchantList;
