import { createSlice } from '@reduxjs/toolkit';
export const SettingsDetailsSlice = createSlice({
  name: 'PartnerDetailsSlice',
  initialState: {
    offerCodeList: [],
    OfficeLocationList: [],
    DepartmentList: [],
    DesignationList: [],
    idTypeList: [],
  },
  reducers: {
    setOfferCodeList: (state, action) => {
      state.offerCodeList = action.payload;
    },
    setOfficeLocationList: (state, action) => {
      state.OfficeLocationList = action.payload;
    },
    setDepartmentList: (state, action) => {
      state.DepartmentList = action.payload;
    },
    setDesignationList: (state, action) => {
      state.DesignationList = action.payload;
    },
    setidTypeList: (state, action) => {
      state.idTypeList = action.payload;
    },
  },
});
export const {
  setOfferCodeList,
  setOfficeLocationList,
  setDepartmentList,
  setDesignationList,
  setidTypeList,
} = SettingsDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getOfferCodeList = state =>
  state.SettingsDetailsSlice.offerCodeList;
export const getOfficeLocationList = state =>
  state.SettingsDetailsSlice.OfficeLocationList;
export const getDepartmentList = state =>
  state.SettingsDetailsSlice.DepartmentList;
export const getDesignationList = state =>
  state.SettingsDetailsSlice.DesignationList;
export const getidTypeList = state => state.SettingsDetailsSlice.idTypeList;
///we can also write thunks by hand , which may contain both sync and async logic

export default SettingsDetailsSlice.reducer;
