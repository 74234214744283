import React, { useState } from 'react';
import { Radio, Text, Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setManifestPageSize } from '../../../redux/slices/ComponentFunctionSlice';

const ManifestSize = () => {
  const sizes = [
    { value: '_auto', label: 'Suitable for standard Desktop Printer' },
    {
      value: '_3_5',
      label: "3' 'x 5' '",
    },
    {
      value: '_4_6',
      label: "4' 'x 6' '",
    },
  ];
  const dispatch = useDispatch();
  const [value, setValue] = useState('_auto');

  const handleSizeChange = data => {
    dispatch(setManifestPageSize(data));
    setValue(data);
  };

  return (
    <Flex flexDir={['column', 'column']} gap={2}>
      <Text fontSize="xl" fontWeight="bold">
        Select a Size:
      </Text>
      <>
        {sizes.map((size, index) => (
          <React.Fragment key={size.value}>
            <Radio
              key={size.value} // Use size.value as the unique key
              value={size.value}
              isChecked={value === size.value}
              onChange={() => handleSizeChange(size.value)}
            >
              <Text>
                {size.label}
                {'     '}
                {index !== 0 && (
                  <Text as={'span'} fontSize={16} fontWeight={'bold'}>
                    Suitable for Thermal Label Printer
                  </Text>
                )}
              </Text>
            </Radio>
          </React.Fragment>
        ))}
      </>
    </Flex>
  );
};

export default ManifestSize;
