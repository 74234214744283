import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  getRemittancePaidList,
  getTransactionDetailsLoading,
} from '../../../redux/slices/TransactionDetailsSlice';
import { REMITTANCE_PAID_COLUMNS } from './RemittancePaidColumn';
import { useSelector } from 'react-redux';
import TableLoader from '../../../components/Loader/TableLoader';
import NoDataAvailable from '../../../components/Loader/NoDataAvailable';
import RemittanceList from './RemittanceList';
const RemittancePaid = ({ TableHeight, excelFilename }) => {
  const paidShipmentList = useSelector(getRemittancePaidList);
  const isLoading = useSelector(getTransactionDetailsLoading);
  return (
    <Box>
      {isLoading ? (
        <TableLoader />
      ) : paidShipmentList.length > 0 ? (
        <RemittanceList
          column={REMITTANCE_PAID_COLUMNS}
          data={paidShipmentList}
          TableHeight={TableHeight}
          excelFilename={excelFilename}
        />
      ) : (
        <NoDataAvailable />
      )}
    </Box>
  );
};
export default RemittancePaid;
