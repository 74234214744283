import {
  B2bSidebarOptions,
  B2cSidebarOptions,
  SidebarOptions,
} from './sidebar.options';
import { Link, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './sidebar.scss';
import { getLayoutType } from '../../redux/slices/ComponentFunctionSlice';
import { useSelector } from 'react-redux';
const MobileMenu = ({ onClose }) => {
  const layOutType = useSelector(getLayoutType);
  const SidebarOptions =
    layOutType === 'b2b' ? B2bSidebarOptions() : B2cSidebarOptions();
  return (
    <Flex className="sidebar-menu" flexDir="column" w="100%" as="nav">
      {SidebarOptions.map((menuItem, index) => {
        return (
          <Link
            as={NavLink}
            to={menuItem}
            key={index}
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            p={2}
            ml={2}
            borderRadius={8}
            _hover={{
              textDecor: 'none',
              backgroundColor: '#303f9f9c',
              width: '90%',
            }}
            onClick={() => onClose()}
          >
            <Flex
              key={index}
              flexDir="column"
              w="100%"
              className="sidebar-menu"
            >
              <Flex>
                <Icon as={menuItem.icon} fontSize="xl" color={'white'} />
                <Text color="white" ml={5}>
                  {menuItem.name}
                </Text>
              </Flex>
            </Flex>
          </Link>
        );
      })}
    </Flex>
  );
};

export default MobileMenu;
