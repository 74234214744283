import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import PickupHoverDetails from './PickupHoverDetails';
import PickupActionButtton from './PickupEditButton';
import { Text, Tooltip } from '@chakra-ui/react';
import AddressId from './AddreessId';
const Address = ({ row }) => {
  return (
    <Tooltip
      border={'2px solid #800080'}
      rounded={'md'}
      bg={'white'}
      width={'150px'}
      label={<PickupHoverDetails row={row} />}
    >
      <Text
        whiteSpace={'normal'}
        maxW={150}
        maxH={'70px'}
        overflow={'auto'}
        py={2}
      >
        {row?.address1}
        {row?.address2}
      </Text>
    </Tooltip>
  );
};

export const PICKUP_COLUMNS = () => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('customerId', {
      cell: info => info.getValue(),
      id: 'customerId',
      header: 'cus.Id',
    }),
    columnHelper.accessor('addressId', {
      cell: info => <AddressId row={info.row.original} />,
      id: 'addressId',
      header: 'Id',
    }),
    columnHelper.accessor(row => row.addressType, {
      id: 'addressType',
      cell: info => <i>{info.getValue()}</i>,
      header: 'Location',
    }),
    columnHelper.accessor('cpPerson', {
      header: () => 'Contact person',
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cpMobile', {
      header: () => 'Mobile',
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('address1', {
      header: () => 'Address',
      cell: info => <Address row={info.row.original} />,
    }),
    columnHelper.accessor('pincode', {
      header: 'Pincode',
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('city', {
      header: 'City',
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('state', {
      header: 'State',
      cell: info => info.renderValue(),
    }),
    {
      id: 'action',
      cell: info => <PickupActionButtton row={info.row.original} />,
      header: 'action',
    },
  ];
};
