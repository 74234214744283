import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PICKUP_COLUMNS } from './PickupPointsColumn';
import { Box } from '@chakra-ui/layout';
import ReactTable from '../../components/ReactTable/tablev8';
import { useSelector, useDispatch } from 'react-redux';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';

import { getAllPickupPointList } from '../../redux/slices/PickupPointsDetailsSlice';
import AddPickupContainer from './AddPickupContainer';
import NoDataAvailable from '../../components/Loader/NoDataAvailable';
const PickupContainer = () => {
  const loggedUser = useSelector(getLoggedUser);
  const pickupDataList = useSelector(getAllPickupPointList);
  let { customerId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (loggedUser.empid) {
      dispatch({
        type: 'GET_ALL_PICKUPLIST',
        payload: {
          customerId: customerId,
        },
      });
    }
  }, [loggedUser?.empid, customerId]);
  return (
    <Box>
      <AddPickupContainer />
      {pickupDataList?.length > 0 ? (
        <ReactTable
          data={pickupDataList}
          columns={PICKUP_COLUMNS()}
          classes={{
            container: 'pickup-address-table-container',
          }}
        />
      ) : (
        <NoDataAvailable />
      )}
    </Box>
  );
};

export default PickupContainer;
