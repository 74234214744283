import { Flex, Icon, Link, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { B2bSidebarOptions, B2cSidebarOptions } from './sidebar.options';
import { COLOR } from '../../utils/constant';
import React from 'react';
import './sidebar.scss';
import { getLayoutType } from '../../redux/slices/ComponentFunctionSlice';
import { useSelector } from 'react-redux';
export default function Sidebar() {
  const layOutType = useSelector(getLayoutType);
  const SidebarOptions =
    layOutType === 'b2b' ? B2bSidebarOptions() : B2cSidebarOptions();
  return (
    <div style={{ position: 'relative' }}>
      <Flex
        pos="sticky"
        className="sidebar-menu-container"
        minW="70px"
        height="calc(100vh - 60px)"
        boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
        borderRadius={'2px'}
        _hover={{ overflow: 'hidden', width: '150p' }}
        bg={COLOR.blue}
        alignItems="center"
        pt={2}
        mx={1}
        flexDir="column"
        justifyContent="space-between"
      >
        <Flex className="sidebar-menu" flexDir="column" w="100%" as="nav">
          {SidebarOptions.map((menuItem, index) => {
            return (
              menuItem.isVisible && (
                <Link
                  key={index}
                  as={NavLink}
                  to={menuItem}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  p={3}
                  ml={5}
                  borderRadius={8}
                  overflow={'hidden'}
                  _hover={{
                    textDecor: 'none',
                    backgroundColor: '#303f9f9c',
                    width: '90%',
                    overflow: 'hidden',
                  }}
                >
                  <Flex
                    flexDir="column"
                    w="100%"
                    className="sidebar-menu"
                    overflow={'hidden'}
                  >
                    <Flex overflow={'hidden'}>
                      <Icon as={menuItem.icon} fontSize="xl" color={'white'} />
                      <Text className="text" overflow={'hidden'} ml={5}>
                        {menuItem.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Link>
              )
            );
          })}
        </Flex>
      </Flex>
    </div>
  );
}
