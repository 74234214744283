import React, { useEffect, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';

function IndeterminateCheckbox({ indeterminate, className = '', ...rest }) {
  const ref = useRef();

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  );
}

const CustomerName = ({ row }) => {
  return <>{row.deliveryAddress_name}</>;
};

const AWBStatus = ({ row }) => {
  const location = useLocation();
  return (
    <Link
      as={NavLink}
      to={`/status/${row.awbNumber}`}
      params={{ awbNumber: row.awbNumber }}
      color="blue.500"
      state={{ from: location.pathname }}
    >
      {row.awbNumber}
    </Link>
  );
};
const IdDetails = ({ row }) => {
  const location = useLocation();
  return (
    <>
      <Link
        as={NavLink}
        to={`/customerdetails/${row.customerId}`}
        params={{ merchantId: row.customerId }}
        color="blue.500"
        state={{ from: location.pathname }}
      >
        {row.customerId}
      </Link>
    </>
  );
};
const CodAmount = ({ row }) => {
  let amount = row?.productDetails_cod ? row?.productDetails_cod : 0;
  return <>{amount}</>;
};

export const REMITTANCE_COLUMNS = () => {
  const columnHelper = createColumnHelper();
  return React.useMemo(() => [
    {
      id: 'select',
      toggleVisibility: ({ row }) =>
        row.currentStatus.toLowerCase() !== 'delivered',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },

    columnHelper.accessor('customerId', {
      cell: info => <IdDetails row={info.row.original} />,
      header: 'Seller Id',
    }),
    columnHelper.accessor('customerName', {
      cell: info => <CustomerName row={info.row.original} />,
      header: 'Cus. Name',
    }),
    columnHelper.accessor('awbNumber', {
      cell: info => <AWBStatus row={info.row.original} />,
      header: 'Awb Number',
    }),

    columnHelper.accessor('currentStatus', {
      header: () => 'Status',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('shipmentPartner', {
      header: () => 'Courier',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('paymentMode', {
      header: () => 'Payment Mode',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),

    columnHelper.accessor('cod', {
      header: 'Cod Amount',
      cell: info => <CodAmount row={info.row.original} />,
      // footer: info => info.column.id,
      // Filter:ColumnFilter
    }),

    columnHelper.accessor(row => row.updatedAt, {
      id: 'UpdatedAt',
      cell: info => <i>{info.getValue()}</i>,
      header: 'Delivered Date', //() => <span>Location</span>,
      // footer: info => info.column.id,
    }),
  ]);
};
