import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../pages/Loginside.page';
import PageNotFound from './PageNotFound.routes';
import RoutesConfig from './config.routes';
import PrivateLayout from '../layouts/private.layouts';
import HomeLayout from '../layouts/Home.layouts';
import Layout from '../layouts/public.layouts';
import { COURIER_SERVICE_LOGIN } from '../utils/constant';
import ResetPasswordPage from '../pages/ResetPassword.page';
import B2bRoutesConfig from './b2b.routes';
import {
  setLogedUserDetails,
  getLoggedUser,
} from '../redux/slices/UserDetailsSlice';
import AllMerchantDetails from '../pages/AllMerchantDetails.page';
import CustomerDetails from '../pages/CustomerDetails.page';
const QuixRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logedUser = useSelector(getLoggedUser);
  let isLoggedIn;

  useEffect(() => {
    if (logedUser) navigate('/');
    else navigate('/login');
    // dispatch({type:'USER_LOGGED_OUT'})
  }, [logedUser]);
  isLoggedIn = JSON.parse(sessionStorage.getItem(COURIER_SERVICE_LOGIN));
  if (!logedUser && isLoggedIn && isLoggedIn.token) {
    const userDetails = JSON.parse(
      sessionStorage.getItem(COURIER_SERVICE_LOGIN)
    );
    dispatch(setLogedUserDetails(userDetails));
  }
  const addPathPrefix = (routes, prefix) =>
    routes.map(route => ({ ...route, path: `${prefix}${route.path}` }));
  const prefixedB2bPrivateRoute = addPathPrefix(
    B2bRoutesConfig.privateRoute,
    '/b2b'
  );
  const prefixedB2cPrivateRoute = addPathPrefix(
    RoutesConfig.privateRoute,
    '/b2c'
  );
  return (
    <Routes>
      {!isLoggedIn ? (
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route path="resetPassword" element={<ResetPasswordPage />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<HomeLayout />} />
          <Route path="/allMerchant" element={<AllMerchantDetails />} />
          <Route path="/b2b" element={<PrivateLayout />}>
            {prefixedB2bPrivateRoute.map(publicRoute => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
          <Route path="/b2c" element={<PrivateLayout />}>
            {prefixedB2cPrivateRoute.map(publicRoute => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
        </>
      )}
      <Route path="/:pageName" element={<PageNotFound />} />
    </Routes>
  );
};
export default QuixRoutes;
