import {
  ContactDetails,
  CustomerDetails,
  DocumemtVerifiedDetails,
  ShipmentTypeDetails,
  CreatedAtDate,
} from './AllMerchantColumnData';
import {
  CategorySelect,
  CustomSelect,
  SearchInCategory,
} from '../../components/CustomTable/CustomFilterComponent';
import DateRangePickerComponent from '../../components/CustomTable/DateRangePickerComponent';
export const TableHeader = [
  { label: 'Customer Details', sortByTerm: 'customerName' },
  { label: 'Contact Details', sortByTerm: 'email' },
  { label: 'ShipmetType', sortByTerm: '' },
  { label: 'is Kyc', sortByTerm: '' },
  { label: 'Created At', sortByTerm: 'createdAt' },
];
export const TableBody = [
  CustomerDetails,
  ContactDetails,
  ShipmentTypeDetails,
  DocumemtVerifiedDetails,
  CreatedAtDate,
];
export const SearchCategoryOption = [
  { label: 'Customer Id', value: 'customerId', default: true },
  { label: 'Customer Name', value: 'customerName', default: false },
  { label: 'Customer Mobile', value: 'cpMobile', default: false },
  { label: 'Customer email', value: 'email', default: false },
];

export const IsGstVerified = [
  { label: 'True', value: true, default: true },
  { label: 'False', value: false, default: false },
];
export const IsIDVerified = [
  { label: 'True', value: true, default: true },
  { label: 'False', value: false, default: false },
];
export const IsCustomerActive = [
  { label: 'True', value: true, default: true },
  { label: 'False', value: false, default: false },
];
export const All_MerchantList_Filter_Component = [
  {
    component: CategorySelect,
    props: {
      searchCategoryOption: SearchCategoryOption,
    },
  },
  {
    component: SearchInCategory,
  },

  {
    component: DateRangePickerComponent,
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: IsGstVerified,
      placeholder: 'Is Gst Verified',
      valueType: 'isGstVerified',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: IsIDVerified,
      placeholder: 'Is IDVerified ',
      valueType: 'isIdVerified',
    },
  },
  {
    component: CustomSelect,
    props: {
      selectCategoryOption: IsCustomerActive,
      placeholder: 'Is Customer Active',
      valueType: 'isActive',
    },
  },
];
