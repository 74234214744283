import { Box } from "@chakra-ui/react";
import JsBarcode from "jsbarcode";
import React, { useEffect, useRef } from "react";

function BarcodeGenerator({
  barcodeValue,
  barcodeWidth,
  barcodeHeight,
  barcodeMargin,
  barcodeFontSize,
  barcodeTextMargin,
}) {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, barcodeValue, {
        format: "CODE128",
        displayValue: true,
        width: barcodeWidth,
        height: barcodeHeight,
        margin: barcodeMargin,
        fontSize: barcodeFontSize,
        textMargin: barcodeTextMargin,
      });
    }
  }, [
    barcodeValue,
    barcodeWidth,
    barcodeHeight,
    barcodeMargin,
    barcodeFontSize,
    barcodeTextMargin,
  ]);

  return (
    <>
      <svg ref={barcodeRef}></svg>
    </>
  );
}

export default BarcodeGenerator;
