import React from 'react';
import DefaultPartnerForm from './DefaultPartnerForm';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Heading,
  DrawerBody,
} from '@chakra-ui/react';

const AddCourierDrawer = ({ isOpen, onClose, defaultFormValue }) => {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={'md'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader bg="lightgrey">
          <Heading as="h1" size="md" color={'blackAlpha.700'}>
            Default Courier
          </Heading>
        </DrawerHeader>
        <DrawerBody mx={'auto'}>
          <DefaultPartnerForm
            defaultFormValue={defaultFormValue}
            onClose={onClose}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default AddCourierDrawer;
