import { fork, all } from 'redux-saga/effects';
import UserDetailsSaga from './UserDetailsSaga';
import PartnerDetailsSaga from './PartnerDetailsSaga';
import MerchantDetailsSaga from './MerchantDetailsSaga';
import SettingsDetailsSaga from './SettingsDetailsSaga';
import EmployeeDetailsSaga from './EmployeeDetailsSaga';
import ShipmentDetailsSaga from './ShipmentDetailsSaga';
import DashboardDetailsSaga from './DashboardDetailsSaga';
import TransactionDetailsSaga from './TransactionDetailsSaga';
import InsuranceDetailsSaga from './InsuranceDetailsSaga';
import WeightDetailsSaga from './WeightManagementDetailsSaga';
import PickupPointsDetailsSaga from './PickupPointsDetailsSaga';
import BookingDetailsSaga from './BookingDetailsSaga';
import CustomerSettingsSaga from './CustomerSettingSaga';
export default function* rootSaga() {
  yield all([
    fork(UserDetailsSaga),
    fork(PartnerDetailsSaga),
    fork(MerchantDetailsSaga),
    fork(SettingsDetailsSaga),
    fork(EmployeeDetailsSaga),
    fork(ShipmentDetailsSaga),
    fork(DashboardDetailsSaga),
    fork(TransactionDetailsSaga),
    fork(InsuranceDetailsSaga),
    fork(WeightDetailsSaga),
    fork(PickupPointsDetailsSaga),
    fork(BookingDetailsSaga),
    fork(CustomerSettingsSaga),
  ]);
}
