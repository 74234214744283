import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { Flex, Select } from '@chakra-ui/react';
import RemittanceDue from './RemittanceDue';
import RemittancePaid from './RemittancePaid';
import DateSection from '../../../pages/DateSection';
import { dateCalc } from '../../../utils/utilsFunction';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedUser } from '../../../redux/slices/UserDetailsSlice';
import { useParams } from 'react-router-dom';
const RemittanceContainer = () => {
  const [selectedValue, setSelectedValue] = useState('Remittance Due');
  const dispatch = useDispatch();
  let { customerId } = useParams();
  const loggedUser = useSelector(getLoggedUser);
  useEffect(() => {
    dispatch({
      type: 'GET_QUIXGO_BANKING_DETAILS',
      postData: {
        customerId: 'quixgo',
        accountBelonging: 'quixgo',
      },
    });
  }, []);
  const RemittanceContainerData = [
    {
      label: 'Remittance Due',
      component: (
        <RemittanceDue
          key={'Remittnace due'}
          hasMultiSelect={true}
          TableHeight={['40vh', '45vh', '53vh', '60vh', '65vh']}
        />
      ),
    },
    {
      label: 'Remittance Paid',
      component: (
        <RemittancePaid
          key={'Remittnace Paid'}
          TableHeight={['40vh', '45vh', '53vh', '60vh', '65vh']}
        />
      ),
    },
  ];

  const handleOnChange = (
    startDate = dateCalc.week,
    endDate = dateCalc.today
  ) => {
    const params = {
      empId: loggedUser.empid,
      customerId: customerId,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch({
      type: 'GET_DUE_REMITTANCE_LIST',
      payload: params,
    });
    dispatch({ type: 'GET_REMITTANCE_TRANSACTION', payload: params });
  };
  return (
    <>
      <Flex
        pos={'sticky'}
        flexDir={['column', 'column', 'row', 'row']}
        gap={2}
        p={2}
        alignItems={'center'}
      >
        <Select
          bg="blue.500"
          borderColor="blue.500"
          color="white"
          defaultValue={selectedValue}
          icon={<IoMdArrowDropdown />}
          w={[200]}
          onChange={e => setSelectedValue(e.target.value)}
          textTransform={'capitalize'}
        >
          {RemittanceContainerData.map(item => (
            <option
              style={{ color: 'black' }}
              key={item?.label}
              value={item.label}
            >
              {item.label}
            </option>
          ))}
        </Select>
        <DateSection onChange={handleOnChange} />
      </Flex>

      {RemittanceContainerData.map(item => {
        if (selectedValue === item.label) {
          return item?.component;
        }
      })}
    </>
  );
};

export default RemittanceContainer;
