import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Flex,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { RiToggleFill, RiToggleLine } from 'react-icons/ri';
import { manifestSettingData } from '../../../utils/utilityConstants';
import { getCustomerSetting } from '../../../redux/slices/CustomerSettingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
const ManifestSetting = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const customerSetting = useSelector(getCustomerSetting);
  const pageColor = '#274a69';
  const [searchTerm, setSearchTerm] = useState('');
  const providedData = customerSetting?.manifestSetting;
  const [state, setState] = useState(() => {
    const initialState = {};
    manifestSettingData?.forEach(setting => {
      initialState[setting?.name] = providedData?.[setting?.name] || false;
    });
    return initialState;
  });

  const toggleSetting = name => {
    setState(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  const tableDataEntries = manifestSettingData.map(setting => ({
    title: setting.title,
    description: setting.description,
    initial: state[setting.name],
    action: (
      <IconButton
        ml={2}
        fontSize={[40, 50]}
        bg="white"
        _hover={{ backgroundColor: 'white' }}
        icon={
          state[setting.name] ? (
            <RiToggleFill color="green" />
          ) : (
            <RiToggleLine />
          )
        }
        onClick={() => toggleSetting(setting.name)}
      />
    ),
  }));
  const filteredData = tableDataEntries.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleManifestSetting = () => {
    const params = {
      customerId: customerId,
      manifestSetting: state,
    };
    dispatch({ type: 'PUT_MANIFEST_SETTING', postData: params });
    localStorage.removeItem('volumetric_data');
  };

  return (
    <>
      <Flex justifyContent="center" alignItems="center" pb={2} gap={4}>
        <Input
          w={250}
          className="search-input"
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <Button colorScheme="blue" onClick={handleManifestSetting}>
          Save
        </Button>
      </Flex>
      <TableContainer
        w={['100vw', '100vw', '100vw', '80vw', '70vw']}
        rounded={5}
        mx="auto"
        h={'68vh'}
        overflowY={'auto'}
        p={0}
        pos={'relative'}
      >
        <Table border={`2px solid ${pageColor}`}>
          <Thead bg={pageColor} pos={'sticky'} top={0} zIndex={1}>
            <Tr>
              {['#', 'Title', 'Description', 'Action'].map(item => (
                <Th py={3} key={item} color="white">
                  {item}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{item?.title}</Td>
                <Td>{item?.description}</Td>
                <Td>{item?.action}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ManifestSetting;
