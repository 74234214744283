import React from 'react';
import {
  IoArrowRedoCircleSharp,
  IoArrowUndoCircleSharp,
} from 'react-icons/io5';
import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { getCustomerSetting } from '../../redux/slices/CustomerSettingSlice';
const CustomComponent = ({
  isDefaultPickupAddress,
  isDefaultReturnAddress,
  label,
  row,
}) => {
  return (
    <>
      {isDefaultPickupAddress || isDefaultReturnAddress ? (
        <Tooltip
          border="2px solid #3182ce"
          rounded="md"
          bg="white"
          width="150px"
          label={label}
        >
          <Text as="span" textTransform="lowercase">
            {row?.addressId}
            <Icon
              boxSize={6}
              color={isDefaultPickupAddress ? 'green.400' : 'red.400'}
              as={
                isDefaultPickupAddress
                  ? IoArrowRedoCircleSharp
                  : IoArrowUndoCircleSharp
              }
            />
          </Text>
        </Tooltip>
      ) : (
        <Text as="span" textTransform="lowercase">
          {row?.addressId}
        </Text>
      )}
    </>
  );
};

const AddressId = ({ row }) => {
  const pageColor = '#274a69';
  const customerSetting = useSelector(getCustomerSetting);

  const isDefaultPickupAddress =
    row?.addressId === customerSetting?.pickupAddress;
  const isDefaultReturnAddress =
    row?.addressId === customerSetting?.returnAddress;
  const isDefult =
    row?.addressId === customerSetting?.pickupAddress &&
    row?.addressId === customerSetting?.returnAddress;

  const label = (
    <>
      <Text
        bg={pageColor}
        color="white"
        fontWeight="extrabold"
        textAlign="center"
      >
        Default :
      </Text>
      {isDefult ? (
        <>
          <Text textAlign="center" color="black">
            pickupAddress
          </Text>
          <Text textAlign="center" color="black">
            ReturnAddress
          </Text>
        </>
      ) : (
        <Text textAlign="center" color="black">
          {isDefaultPickupAddress ? 'pickupAddress' : 'ReturnAddress'}
        </Text>
      )}
    </>
  );

  return (
    <>
      {isDefult ? (
        <Flex justifyContent={'center'} alignContent={'center'}>
          <Text textTransform="lowercase" mt={3}>
            {row?.addressId}
          </Text>
          <Tooltip
            border="2px solid #3182ce"
            rounded="md"
            bg="white"
            width="150px"
            label={label}
          >
            <Flex flexDir={'column'}>
              <Icon
                boxSize={5}
                color={'green.400'}
                as={IoArrowRedoCircleSharp}
              />
              <Icon boxSize={5} color={'red.400'} as={IoArrowUndoCircleSharp} />
            </Flex>
          </Tooltip>
        </Flex>
      ) : (
        <CustomComponent
          isDefaultPickupAddress={isDefaultPickupAddress}
          isDefaultReturnAddress={isDefaultReturnAddress}
          label={label}
          row={row}
        />
      )}
    </>
  );
};
export default AddressId;
