import { FiBarChart2 } from 'react-icons/fi';
import { MdSettingsSuggest, MdOutlineHomeWork } from 'react-icons/md';
import {
  FaBoxOpen,
  FaMoneyBillAlt,
  FaFileInvoice,
  FaHandHoldingHeart,
  FaUsers,
  FaUserCog,
} from 'react-icons/fa';
import { TbScale } from 'react-icons/tb';
import { HiTruck, HiCurrencyRupee } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import { USER_ROLES } from '../../utils/utilityConstants';

export const B2bSidebarOptions = () => {
  const loggedUser = useSelector(getLoggedUser);
  return [
    {
      name: 'Dashboard',
      icon: FiBarChart2,
      pathname: '/b2b/dashboard',
      isVisible: true,
    },
    {
      name: 'Merchants',
      icon: FaUsers,
      pathname: '/b2b/merchants',
      isVisible: true,
    },
    {
      name: 'Shipments',
      icon: FaBoxOpen,
      pathname: '/b2b/shipmentstatus',
      isVisible: true,
    },

    {
      name: 'Shipping partners',
      icon: HiTruck,
      pathname: '/b2b/shippingpartners',
      isVisible: true,
    },
    {
      name: 'Pickup points',
      icon: MdOutlineHomeWork,
      pathname: '/b2b/pickuppoints',
      isVisible: true,
    },
    {
      name: 'Billing',
      icon: FaMoneyBillAlt,
      pathname: '/b2b/billing',
      isVisible: true,
    },
    {
      name: 'Manage Invoices',
      icon: FaFileInvoice,
      pathname: '/b2b/manageinvoices',
      isVisible: true,
    },
    {
      name: 'Insurance',
      icon: FaHandHoldingHeart,
      pathname: '/b2b/insurance',
      isVisible: true,
    },
    {
      name: 'Weight Management',
      icon: TbScale,
      pathname: '/b2b/weightmanagement',
      isVisible: true,
    },

    {
      name: 'Remittance',
      icon: HiCurrencyRupee,
      pathname: '/b2b/remittance',
      isVisible: true,
    },
    {
      name: 'Manage Users',
      icon: FaUserCog,
      pathname: '/b2b/manageusers',
      isVisible:
        loggedUser.userRole === USER_ROLES.SUPER ||
        loggedUser.userRole === USER_ROLES.ADMIN,
    },

    {
      name: 'Settings',
      icon: MdSettingsSuggest,
      pathname: '/b2b/settings',
      isVisible:
        loggedUser.userRole === USER_ROLES.SUPER ||
        loggedUser.userRole === USER_ROLES.ADMIN,
    },
  ];
};
export const B2cSidebarOptions = () => {
  const loggedUser = useSelector(getLoggedUser);
  return [
    {
      name: 'Dashboard',
      icon: FiBarChart2,
      pathname: '/b2c/dashboard',
      isVisible: true,
    },
    {
      name: 'Merchants',
      icon: FaUsers,
      pathname: '/b2c/merchants',
      isVisible: true,
    },
    {
      name: 'Shipments',
      icon: FaBoxOpen,
      pathname: '/b2c/shipmentstatus',
      isVisible: true,
    },

    {
      name: 'Shipping partners',
      icon: HiTruck,
      pathname: '/b2c/shippingpartners',
      isVisible: true,
    },
    {
      name: 'Pickup points',
      icon: MdOutlineHomeWork,
      pathname: '/b2c/pickuppoints',
      isVisible: true,
    },
    {
      name: 'Billing',
      icon: FaMoneyBillAlt,
      pathname: '/b2c/billing',
      isVisible: true,
    },
    {
      name: 'Manage Invoices',
      icon: FaFileInvoice,
      pathname: '/b2c/manageinvoices',
      isVisible: true,
    },
    {
      name: 'Insurance',
      icon: FaHandHoldingHeart,
      pathname: '/b2c/insurance',
      isVisible: true,
    },
    {
      name: 'Weight Management',
      icon: TbScale,
      pathname: '/b2c/weightmanagement',
      isVisible: true,
    },

    {
      name: 'Remittance',
      icon: HiCurrencyRupee,
      pathname: '/b2c/remittance',
      isVisible: true,
    },
    {
      name: 'Manage Users',
      icon: FaUserCog,
      pathname: '/b2c/manageusers',
      isVisible:
        loggedUser.userRole === USER_ROLES.SUPER ||
        loggedUser.userRole === USER_ROLES.ADMIN,
    },

    {
      name: 'Settings',
      icon: MdSettingsSuggest,
      pathname: '/b2c/settings',
      isVisible:
        loggedUser.userRole === USER_ROLES.SUPER ||
        loggedUser.userRole === USER_ROLES.ADMIN,
    },
  ];
};
