import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { HiOutlineMail } from 'react-icons/hi';
import { ImMobile } from 'react-icons/im';

const CustomText = (row, label) => {
  return (
    <Text
      color={row?.[label] ? 'green.500' : 'red.500'}
      fontWeight={'bold'}
      letterSpacing={1.5}
    >
      {`${label} : ${row?.[label]}`}
    </Text>
  );
};
export const ShipmentTypeDetails = ({ row }) => {
  return (
    <Box>
      {CustomText(row, 'isB2bActive')}
      {CustomText(row, 'isB2cActive')}
    </Box>
  );
};

export const DocumemtVerifiedDetails = ({ row }) => {
  return (
    <Box>
      {CustomText(row, 'isGstVerified')}
      {CustomText(row, 'isIdVerified')}
    </Box>
  );
};
export const ContactDetails = ({ row }) => {
  return (
    <Flex
      gap={1}
      flexDir={'column'}
      width={'70%'}
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      <Text>{row?.cpName}</Text>
      <Text>
        <Icon as={ImMobile} color={'#4285F4'} /> {':'} {row?.cpMobile}
      </Text>
      <Text>
        <Icon as={HiOutlineMail} color={'#4285F4'} /> {':'}
        {row?.email}
      </Text>
    </Flex>
  );
};

export const CustomerDetails = ({ row }) => {
  return (
    <>
      <Text>{row.customerName}</Text>
      <Text> ID : {row.customerId}</Text>{' '}
    </>
  );
};

export const CreatedAtDate = ({ row }) => {
  const createdAt = row?.createdAt;

  if (createdAt) {
    const dateTime = parseISO(createdAt);
    const dateString = format(dateTime, 'yyyy-MM-dd');
    const timeString = format(dateTime, 'HH:mm:ss');

    return (
      <Box text textAlign={'center'} letterSpacing={1.5}>
        <Text>{dateString}</Text>
        <Text>{timeString}</Text>
      </Box>
    );
  } else {
    return (
      <Box text textAlign={'center'}>
        No date available
      </Box>
    );
  }
};
