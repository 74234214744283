import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setCustomerInfo,
  setIdList,
  setIsCustomerInfoLoading,
} from '../slices/CustomerDetailsSlice';
import {
  httpCall,
  GET_CUSTOMER_INFO_URL,
  GET_ALL_IDENTITY_URL,
  UPDATE_CUSTOMER_INFO_URL,
  UPDATE_BANKING_DETAILS_URL,
  ADD_KYC_DETAILS_URL,
  UPDATE_KYC_DETAILS_URL,
  UPDATE_CUSTOMER_ADDRESS_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { fetchPickupLocation } from './BookingDetailsSaga';
import { toast } from 'react-toastify';

export function* addKycInfo(params) {
  try {
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${ADD_KYC_DETAILS_URL}`;
    const { postData } = params;
    const { docInfo } = postData;
    const formData = new FormData();

    if (postData.companyInfo) {
      formData.append('companyInfo', JSON.stringify(postData.companyInfo));
    }
    formData.append('address', JSON.stringify(postData.address));

    if (docInfo.gstDoc) {
      formData.append('gstFile', docInfo.gstDoc, docInfo.gstDoc.name);
    }

    if (docInfo.selfiDoc) {
      formData.append('selfiFile', docInfo.selfiDoc);
    }

    if (docInfo.logoDoc) {
      formData.append('logoFile', docInfo.logoDoc, docInfo.logoDoc.name);
    }

    if (docInfo.aadharFront) {
      formData.append(
        'aadharFront',
        docInfo.aadharFront,
        docInfo.aadharFront.name
      );
    }

    if (docInfo.aadharBack) {
      formData.append(
        'aadharBack',
        docInfo.aadharBack,
        docInfo.aadharBack.name
      );
    }

    const customerUpdateResponse = yield call(httpCall, {
      url: getAllIdUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (customerUpdateResponse?.success) {
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
      const params = {
        id: postData.address.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        payload: params,
      });
    } else {
      toast.error(customerUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      setIsCustomerInfoLoading(false);
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}

export function* getCustomerInfo(params) {
  setIsCustomerInfoLoading(true);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;

  const getCustomerUrl = `${process.env.REACT_APP_BASEURL}${GET_CUSTOMER_INFO_URL}/${payload.id}`;
  try {
    const customerDetails = yield call(httpCall, {
      url: getCustomerUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      // data:data
    });
    if (customerDetails?.success) {
      yield put(setCustomerInfo(customerDetails.data));
      yield call(fetchPickupLocation, {
        customerId: customerDetails.data.customerId,
      });
      setIsCustomerInfoLoading(false);
    } else {
      toast.error('Something went wrong');
      setIsCustomerInfoLoading(false);
    }
  } catch (err) {
    console.log(err);
    setIsCustomerInfoLoading(false);
  }
}

export function* getAllIdentityType(params) {
  try {
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_IDENTITY_URL}`;
    const idDetails = yield call(httpCall, {
      url: getAllIdUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (idDetails?.success) {
      yield put(setIdList(idDetails.data));
    } else {
      toast.error('Id Type List fetch failed', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {}
}

export function* updateCustomerInfo(params) {
  setIsCustomerInfoLoading(true);
  try {
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_CUSTOMER_INFO_URL}`;
    const { postData } = params;
    const customerUpdateResponse = yield call(httpCall, {
      url: getAllIdUrl,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (customerUpdateResponse?.success) {
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
      const params = {
        id: postData.customerInfo.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        payload: params,
      });
    } else {
      toast.error(customerUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      setIsCustomerInfoLoading(false);
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}

export function* updateBankingDetails(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const updateBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_BANKING_DETAILS_URL}`;
    const formData = new FormData();
    formData.append('bankingInfo', JSON.stringify(postData.bankingInfo));

    setIsCustomerInfoLoading(true);
    if (Array.isArray(postData.cancelCheck)) {
      formData.append(
        'check',
        postData.cancelCheck[0],
        postData.cancelCheck[0].name
      );
    } else {
      formData.append('check', postData.cancelCheck);
    }
    const bankingDetailsResponse = yield call(httpCall, {
      url: updateBankingInfoUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (bankingDetailsResponse?.success) {
       toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(bankingDetailsResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      setIsCustomerInfoLoading(false);
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}


export function* putKycDocs(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getKycDocsUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_KYC_DETAILS_URL}`;
    const formData = new FormData();

    // if(postData.companyInfo){?
    formData.append('docData', JSON.stringify(postData.docData));
    formData.append('newFile', postData.file, postData.file.name);

    const kycDocsResponse = yield call(httpCall, {
      url: getKycDocsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (kycDocsResponse?.success) {
      setIsCustomerInfoLoading(false);
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}

export function* updateCustomerAddress(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const updateAdressUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_CUSTOMER_ADDRESS_URL}`;
    const addressUpdateResponse = yield call(httpCall, {
      url: updateAdressUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (addressUpdateResponse?.success) {
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}

export function* watchCustomerDetailsSaga() {
  yield takeLatest('GET_CUSTOMER_INFO', getCustomerInfo);
  yield takeLatest('GET_ALL_IDENTITY_TYPE', getAllIdentityType);
  yield takeLatest('UPDATE_CUSTOMER_INFO', updateCustomerInfo);
  yield takeLatest('UPDATE_BANKING_DETAILS', updateBankingDetails);
  yield takeLatest('ADD_KYC_INFO', addKycInfo);
  yield takeLatest('PUT_KYC_DOCS', putKycDocs);
  yield takeLatest('PUT_CUSTOMER_ADDRESS', updateCustomerAddress);
}

export default function* CustomerDetailsSaga() {
  yield all([watchCustomerDetailsSaga()]);
}
