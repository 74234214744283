import React, { useState } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { CSVLink } from 'react-csv';
import { HiOutlineDownload } from 'react-icons/hi';
import { flattenArray } from '../utils/utilsFunction';
const Exceldata = ({ data, filename }) => {
  const [flattenData, setFlattenData] = useState();
  const Data = data.map(items => {
    return items;
  });
  return (
    <div>
      {' '}
      <Box color={'#209E63'} textDecor={'underline'} minW={10}>
        <CSVLink
          data={flattenData || Data}
          filename={filename}
          onClick={() => setFlattenData(flattenArray(data))}
        >
          <Icon as={SiMicrosoftexcel} boxSize={5} />
          <Icon as={HiOutlineDownload} boxSize={3} />
        </CSVLink>
      </Box>{' '}
    </div>
  );
};

export default Exceldata;
