/* eslint-disable no-unused-vars */
import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import { hydrate, render } from 'react-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
// import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './App.css';
const rootElement = document.getElementById('root');
let AppCompoent = (
  <>
    <ColorModeScript />
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </>
);

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, AppCompoent);
} else {
  const root = createRoot(rootElement);
  root.render(AppCompoent);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
