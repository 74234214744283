import { takeLatest, all, call, put } from 'redux-saga/effects';
import download from 'js-file-download';
import {
  setShippingPartners,
  setPriceList,
  setIsPriceLoading,
} from '../slices/PartnerDetailsSlice';
import {
  httpCall,
  Add_SHIPPING_PARTNER_URL,
  GET_SHIPPING_PARTNERS_URL,
  UPLOAD_SERVICEABILITY_URL,
  DOWNLOAD_SERVICEABILITY_SAMPLE_URL,
  DOWNLOAD_PRICE_SAMPLE_URL,
  UPLOAD_CUSTOMER_PRICE_URL,
  GET_PRICE_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';

var qs = require('qs');

export function* fetchPartnerDetails(payload) {
  // const {postData}=payload;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  // console.log(postData);
  // var data = qs.stringify(postData);
  const getPartnerUrl = `${process.env.REACT_APP_BASEURL}${GET_SHIPPING_PARTNERS_URL}`;
  try {
    const partnerDetails = yield call(httpCall, {
      url: getPartnerUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (partnerDetails && partnerDetails.success) {
      yield put(setShippingPartners(partnerDetails.data));
      // toast.success('User Added Successfully');
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* addPartnerDetails(params) {
  const { payload } = params;
  // console.log(postData);
  var data = qs.stringify(payload);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${Add_SHIPPING_PARTNER_URL}`;
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data,
    });
    if (partnerDetails && partnerDetails.success) {
      yield call(fetchPartnerDetails, data);
      toast.success('Shipping Partners Added Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else if (partnerDetails.err.status === 401) {
      // console.log(partnerDetails);
      toast.error(partnerDetails.err.data.error.errMsg, {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      // console.log(partnerDetails);
      toast.error('Something Went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
    toast.error('Something Went wrong', {
      closeOnClick: true,
      draggable: true,
    });
  }
}

export function* uploadServiceabilityFile(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;

  const formData = new FormData();
  formData.append('file', payload?.file);
  formData.append('fileName', payload?.file?.name);
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${UPLOAD_SERVICEABILITY_URL}/${payload?.fileType}`;
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (partnerDetails && partnerDetails.success) {
      // yield call(fetchPartnerDetails, data);
      // partnerDetails.data.blob()
      toast.success(' SERVICEABILITY Updated Added Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(partnerDetails.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* downloadServiceabilitySample(params) {
  const { payload } = params;
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_SERVICEABILITY_SAMPLE_URL}/${payload}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (partnerDetails?.success) {
      const data = partnerDetails.data;
      download(data, 'samplePincodeCoverage.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(partnerDetails.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* uploadPriceFile(params) {
  const { payload } = params;
  // const objectLen=Object.keys(payload).length;
  // const url= objectLen===3?UPLOAD_CUSTOMER_PRICE_URL:UPLOAD_PRICE_URL;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const file = payload.file;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file?.name);
  formData.append('employeeId', payload?.empId);
  formData.append('customerId', payload?.customerId);
  formData.append('priceType', payload?.priceType);

  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${UPLOAD_CUSTOMER_PRICE_URL}`;
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (partnerDetails?.success) {
      toast.success(' SERVICEABILITY Updated Added Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(partnerDetails.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* downloadPriceSample(params) {
  const { payload } = params;
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_PRICE_SAMPLE_URL}/${payload}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (partnerDetails?.success) {
      const data = partnerDetails.data;
      download(data, 'samplePrice.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPrice(params) {
  const { payload } = params;
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${GET_PRICE_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  yield put(setIsPriceLoading(true));
  try {
    const priceRes = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: payload,
    });
    if (priceRes && priceRes.success) {
      yield put(setPriceList(priceRes?.data));
      yield put(setIsPriceLoading(false));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsPriceLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsPriceLoading(false));
  }
}

export function* watchPartnerDetailsSaga() {
  yield takeLatest('GETALL_SHIPPING_PARTNERS', fetchPartnerDetails);
  yield takeLatest('ADD_SHIPPING_PARTNERS', addPartnerDetails);
  yield takeLatest('UPLOAD_SERVICEABILITY', uploadServiceabilityFile);
  yield takeLatest(
    'DOWNLOAD_SERVICEABILITY_SAMPLE',
    downloadServiceabilitySample
  );
  yield takeLatest('UPLOAD_PRICE', uploadPriceFile);
  yield takeLatest('DOWNLOAD_PRICE_SAMPLE', downloadPriceSample);
  yield takeLatest('GET_PRICE', getPrice);
}

export default function* PartnerDetailsSaga() {
  yield all([watchPartnerDetailsSaga()]);
}
