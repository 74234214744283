import React from 'react';
const CustomerContainer = React.lazy(() =>
  import('../containers/Customer/index')
);
const TransactionContainer = React.lazy(() =>
  import('../containers/Transaction/index')
);
const ShipmentContainer = React.lazy(() =>
  import('../containers/Shipment/index')
);
const PickupContainer = React.lazy(() =>
  import('../containers/PickupPoints.jsx/PickupContainer')
);

export const CustomerDetailsPage = [
  { label: 'Customer Details', component: <CustomerContainer /> },
  { label: 'Transaction Details', component: <TransactionContainer /> },
  { label: 'Shipments', component: <ShipmentContainer /> },
  { label: 'Pickup Points', component: <PickupContainer /> },
];
const PersonalDetails = React.lazy(() =>
  import('../containers/Customer/PersonalDetails/index')
);
const BankingDetails = React.lazy(() =>
  import('../containers/Customer/UsersPaymentSettings/index')
);

const KycDocs = React.lazy(() =>
  import('../containers/Customer/KycDocs/index')
);
const SpecialPrice = React.lazy(() =>
  import('../containers/Customer/SpecialPrice/index')
);
export const CustomerContainerData = [
  { label: 'Personal Details', component: <PersonalDetails /> },
  { label: 'Bank Details', component: <BankingDetails /> },
  { label: 'Kyc Docs', component: <KycDocs /> },
  { label: 'Price', component: <SpecialPrice /> },
];
