import { createSlice } from '@reduxjs/toolkit';
export const ShipmentDetailsSlice = createSlice({
  name: 'ShipmentDetailsSlice',
  initialState: {
    allShipmentList: [],
    userShipmentList: [],
    selectedShipmentInfo: [],
    shipmentStatus: null,
    isStatusLoading: true,
    userPickupList: [],
    allPickupList: [],
    shipmentLoader: false,
    selectedPickupAddress: [],
    calCulatedPrice: null,
    remittanceDueList:[]
  },
  reducers: {
    setCalculatedPrice: (state, action) => {
      state.calCulatedPrice = action.payload;
    },
    setAllShipmentList: (state, action) => {
      state.allShipmentList = action.payload;
    },
    setUserShipmentList: (state, action) => {
      state.userShipmentList = action.payload;
    },
    setSelectedShipmentInfo: (state, action) => {
      state.selectedShipmentInfo = action.payload;
    },
    setShipmentStatus: (state, action) => {
      state.shipmentStatus = action.payload;
    },
    setIsStatusLoading: (state, action) => {
      state.isStatusLoading = action.payload;
    },
    setUserPickupLocations: (state, action) => {
      state.userPickupList = action.payload;
    },
    setAllPickupLocations: (state, action) => {
      state.allPickupList = action.payload;
    },
    setShipmentLoader: (state, action) => {
      state.shipmentLoader = action.payload;
    },
    setSelectedPickupAddress: (state, action) => {
      state.selectedPickupAddress = action.payload;
    },
    setRemittanceDueList: (state, action) => {
      state.remittanceDueList=action.payload;
    }
  },
});

export const {
  setCalculatedPrice,
  setAllShipmentList,
  setUserShipmentList,
  setSelectedShipmentInfo,
  setShipmentStatus,
  setIsStatusLoading,
  setUserPickupLocations,
  setAllPickupLocations,
  setShipmentLoader,
  setSelectedPickupAddress,
  setRemittanceDueList
} = ShipmentDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getCalculatedPrice = state =>
  state.ShipmentDetailsSlice.calCulatedPrice;
export const getAllShipmentList = state =>
  state.ShipmentDetailsSlice.allShipmentList;
export const getUserShipmentList = state =>
  state.ShipmentDetailsSlice.userShipmentList;
export const getSelectedShipmentInfo = state =>
  state.ShipmentDetailsSlice.selectedShipmentInfo;
export const getShipmentStatus = state =>
  state.ShipmentDetailsSlice.shipmentStatus;
export const getIsStatusLoading = state =>
  state.ShipmentDetailsSlice.isStatusLoading;
export const getUserPickupLocations = state =>
  state.ShipmentDetailsSlice.userPickupList;
export const getAllPickupLocations = state =>
  state.ShipmentDetailsSlice.allPickupList;
export const getShipmentLoader = state =>
  state.ShipmentDetailsSlice.shipmentLoader;
export const getSelectedPickupAddress = state =>
  state.ShipmentDetailsSlice.selectedPickupAddress;
export const getRemittanceDueList = state =>
  state.ShipmentDetailsSlice.remittanceDueList;
///we can also write thunks by hand , which may contain both sync and async logic

export default ShipmentDetailsSlice.reducer;
