import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import './pickupForm.scss';
import DrawerFormData from './DrawerFormData';
const AddPickupContainer = data => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen} leftIcon={<GrAdd />}>
        Add Pickup Point
      </Button>
      <DrawerFormData isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AddPickupContainer;
