import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  SimpleGrid,
  Box,
  Text,
} from '@chakra-ui/react';
import { getCustomerInfo } from '../../redux/slices/MerchantDetailsSlice';

import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
const PickupForm = ({ defaultFormValue, onClose }) => {
  const customerInfo = useSelector(getCustomerInfo);
  const dispatch = useDispatch();
  const LoggedUser = useSelector(getLoggedUser);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaultFormValue,
  });

  function onSubmit(values) {
    const params = {
      customerId: customerInfo?.customerId
        ? customerInfo?.customerId
        : LoggedUser?.id,
      customerName: customerInfo?.customerName,
      cpPerson: values.cpName,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      country: 'India',
      addressType: values.addressType,
      pincode: values.pincode,
      cpMobile: values.mobileNumber,
      alternateNumber: values.alternateNumber,
      email: values.email,
      landmark: values.landmark,
    };

    if (defaultFormValue) {
      dispatch({
        type: 'EDIT_PICKUP_FORMDATA',
        postData: { id: defaultFormValue?.Id, ...params },
      });
    } else {
      dispatch({ type: 'ADD_PICKUP_LOCATION', payload: params });
    }
    onClose();
  }

  return (
    <Box color="blackAlpha.800">
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <SimpleGrid columns={[1, 2]} spacing={2}>
            <FormControl isInvalid={errors.addressType}>
              <FormLabel htmlFor="addressType">
                Pickup Location{' '}
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                id="addressType"
                placeholder="Select Pickup Location"
                {...register('addressType', {
                  required: 'This is required',
                  // minLength: { value: 4, message: 'Minimum length should be 4' },
                })}
              >
                <option> Office</option>
                <option>Home</option>
                <option>Warehouse</option>
                <option>Manufacturing House</option>
              </Select>
              <FormErrorMessage>
                {errors?.addressType?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.cpName}>
              <FormLabel htmlFor="cpName">
                Contact Person Name
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="cpName"
                placeholder="Name"
                {...register('cpName', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                })}
              />
              <FormErrorMessage>{errors?.cpName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.mobileNumber}>
              <FormLabel htmlFor="mobileNumber">
                Contact Mobile
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="mobileNumber"
                placeholder="Mobile Number"
                {...register('mobileNumber', {
                  required: 'This is required',
                  // valueAsNumber: true,
                  pattern: {
                    value:
                      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    message: 'Wrong mobile number',
                  },
                  maxLength: {
                    value: 10,
                    message: 'maximum length should be 10',
                  },
                  // minLength: { value: 10, message: 'Minimum length should be 10' },
                })}
              />
              <FormErrorMessage>
                {errors?.mobileNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors?.alternateNumber}>
              <FormLabel htmlFor="alternateNumber">Aternate Mobile</FormLabel>
              <Input
                id="alternateNumber"
                placeholder="Alternate Mobile Number"
                {...register('alternateNumber', {
                  // required: "This is required",
                  // valueAsNumber: true,
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: 'Wrong mobile number',
                  },
                  maxLength: {
                    value: 10,
                    message: 'maximum length should be 10',
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.alternateNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.email}>
              <FormLabel htmlFor="email">
                Email Id
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="abc@xyz.com"
                {...register('email', {
                  required: 'This is required',
                  pattern: {
                    value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    message: 'Wrong email id',
                  },
                })}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address1}>
              <FormLabel htmlFor="address1">
                Address1
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="address1"
                placeholder="Address1"
                {...register('address1', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                })}
              />
              <FormErrorMessage>{errors?.address1?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address2}>
              <FormLabel htmlFor="address2">Address2</FormLabel>
              <Input
                id="address2"
                placeholder="Address2"
                {...register('address2')}
              />
            </FormControl>
            <FormControl isInvalid={errors.landmark}>
              <FormLabel htmlFor="landmark">
                Landmark
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="landmark"
                placeholder="Landmark"
                {...register('landmark', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                })}
              />
              <FormErrorMessage>{errors?.landmark?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.city}>
              <FormLabel htmlFor="city">
                City
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="city"
                placeholder="City"
                {...register('city', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                })}
              />
              <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.state}>
              <FormLabel htmlFor="State">
                State
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="state"
                placeholder="State"
                {...register('state', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                })}
              />
              <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.pincode}>
              <FormLabel htmlFor="pincode">
                Pincode
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="pincode"
                placeholder="Pincode"
                {...register('pincode', {
                  required: 'This is required',
                  pattern: {
                    value: /^[1-9][0-9]{5}$/,
                    message: 'Wrong pincode',
                  },
                })}
              />
              <FormErrorMessage>{errors?.pincode?.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </>
        <Button
          mt={4}
          colorScheme="blue"
          isLoading={isSubmitting}
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default PickupForm;
