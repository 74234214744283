import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setAllWeightManagement,
  setWeightDetailsLoading,
} from '../slices/weightManagementDetailsSlice';
import download from 'js-file-download';
import {
  httpCall,
  GET_WEIGHT_DISCREPANCY_URL,
  UPLOAD_WEIGHT_DISCREPANCY_URL,
  GET_ALL_WEIGHT_DISCREPANCY_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';

export function* downloadSampleDiscrepancyFile(payload) {
  //  const {postData} = payload
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const downloadSampleDiscrepancyUrl = `${process.env.REACT_APP_BASEURL}${GET_WEIGHT_DISCREPANCY_URL}`;
  yield put(setWeightDetailsLoading(true));
  try {
    const sampleDownloadResponse = yield call(httpCall, {
      url: downloadSampleDiscrepancyUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      responseType: 'blob',
      // data : payload
    });
    if (sampleDownloadResponse?.success) {
      const data = sampleDownloadResponse.data;
      download(data, 'sample-weight-discrepancy.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });

      yield put(setWeightDetailsLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setWeightDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* uploadWeightDiscrepancyFile(params) {
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileName', payload.file.name);
  const uploadWeightDiscrepancyUrl = `${process.env.REACT_APP_BASEURL}${UPLOAD_WEIGHT_DISCREPANCY_URL}`;
  try {
    const uploadResponse = yield call(httpCall, {
      url: uploadWeightDiscrepancyUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (uploadResponse?.success) {
      toast.success(' Updated Added Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getAllDiscrepancy(params) {
  const { postData } = params;
  yield put(setWeightDetailsLoading(true));
  try {
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getDiscrepancyUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_WEIGHT_DISCREPANCY_URL}`;
    const dispreancyListResponse = yield call(httpCall, {
      url: getDiscrepancyUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });

    if (dispreancyListResponse?.success) {
      yield put(setAllWeightManagement(dispreancyListResponse.data));
      yield put(setWeightDetailsLoading(false));
    } else {
      toast.error(dispreancyListResponse.err.data);
      yield put(setWeightDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchWeightDetailsSaga() {
  yield takeLatest(
    'DOWNLOAD_SAMPLE_DISCREPANCY_FILE',
    downloadSampleDiscrepancyFile
  );
  yield takeLatest('UPLOAD_WEIGHT_DISCREPANCY', uploadWeightDiscrepancyFile);
  yield takeLatest('GET_ALL_DISCREPANCY_LIST', getAllDiscrepancy);
}

export default function* WeightDetailsSaga() {
  yield all([watchWeightDetailsSaga()]);
}
