import { Box } from '@chakra-ui/react';
import React from 'react';

export default function Card(props) {
  const { children } = props;
  return (
    <Box
      mt={2}
      overflowY={['scroll', 'scroll', 'hidden']}
      overflowX={['hidden']}
      maxH="90vh"
      minW={['100vw', '100vw', `calc(100vw - 100px)`]}
      {...props}
    >
      {children}
    </Box>
  );
}
