import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setPickupLocations,
  setShipmentList,
  setPickupDeleteRow,
  setIsPickupEdit,
  setShipmentStatus,
  setIsStatusLoading,
  setPickupLocationLoading,
  setShipmentListLoading,
  setIsBookingDone,
  setIsBooking,
  setIsPincodeDataLoading,
  setPincodeData,
  setCoverage,
} from '../slices/BookingDetailsSlice';
import {
  httpCall,
  REGISTER_PICKUP_LOCATION_URL,
  // GETALL_PICKUP_LOCATION_URL,
  DOWNLOAD_BOOKING_SAMPLEFILE_URL,
  UPLOAD_BOOKING_URL,
  SINGLE_BOOKING_URL,
  GET_ALL_SHIPMENT_URL,
  GET_ALL_PICKUP_URL,
  HANDLE_DELETE_IN_PICKUPTABLE_URL,
  EDIT_PICKUP_FORMDATA_URL,
  GET_AWB_STATUS_URL,
  CANCEL_SHIPMENT_URL,
  GET_PINCODE_DATA_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import download from 'js-file-download';
import { getCustomerInfo } from './CustomerDetailsSaga';
import { setAddressInfo } from '../slices/CustomerDetailsSlice';
import { sub, format } from 'date-fns';
import { fetchAllShipments } from './ShipmentDetailsSaga';
export function* fetchPickupLocation(params) {
  let { payload } = params;
  payload = payload ? payload : params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllPickupUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_PICKUP_URL}`;
  yield put(setPickupLocationLoading(true));
  try {
    const pickupLocationDetails = yield call(httpCall, {
      url: getAllPickupUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (pickupLocationDetails?.success) {
      const custPickupAddress = pickupLocationDetails.data.filter(address => {
        return address.addressType !== 'self';
      });

      yield put(setPickupLocations(custPickupAddress));
      const custAddress = pickupLocationDetails.data.filter(
        address => address.addressType === 'self'
      )[0];
      const address2 = custAddress?.address2.split('-');
      const addressParams = {
        addressId: custAddress.addressId,
        address1: custAddress.address1,
        address2: address2[0],
        city: address2[1],
        district: custAddress.city,
        state: custAddress.state,
        pin: custAddress.pincode,
      };
      yield put(setAddressInfo(addressParams));
      yield put(setPickupLocationLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setPickupLocationLoading(false));
  }
}

export function* addPickupLocation(params) {
  const { payload } = params;

  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const addPickupLocationUrl = `${process.env.REACT_APP_BASEURL}${REGISTER_PICKUP_LOCATION_URL}`;
  yield put(setPickupLocationLoading(true));
  try {
    const customerDetails = yield call(httpCall, {
      url: addPickupLocationUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (customerDetails?.success) {
      yield call(fetchPickupLocation, {
        customerId: customerDetails.data.customerId,
      });
      toast.success(' Pickup location genrated Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Duplicate Entry', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setPickupLocationLoading(false));
    }
  } catch (err) {
    console.log(err);
    toast.error('Something went wrong', {
      closeOnClick: true,
      draggable: true,
    });
  }
}

export function* downloadBookingSampleFile() {
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_BOOKING_SAMPLEFILE_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (partnerDetails?.success) {
      const data = partnerDetails.data;
      download(data, 'SampleBookingFile.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* uploadBulkBookingFile(params) {
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileName', payload.file.name);
  formData.append('customerId', payload.customerId);
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${UPLOAD_BOOKING_URL}`;
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (partnerDetails?.success) {
      let today = format(new Date(), 'yyyy-MM-dd');
      const hours48 = sub(new Date(), {
        hours: 24,
      });
      const shipmentParams = {
        customerId: payload.customerId,
        startDate: format(hours48, 'yyyy-MM-dd'),
        endDate: today,
      };
      yield fetchAllShipmentStatus({
        type: 'ALL_SHIPMENT_STATUS',
        postData: shipmentParams,
      });
      const params = {
        id: payload.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        payload: params,
      });
      toast.success('File Uploaded Successfully. It is processing', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* singleShipmentBooking(params) {
  yield put(setIsBookingDone(true));
  const { postData, fetchAllShipmentsParam } = params;

  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getBookingUrl = `${process.env.REACT_APP_BASEURL}${SINGLE_BOOKING_URL}`;
  try {
    const bookingDetails = yield call(httpCall, {
      url: getBookingUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (bookingDetails?.success) {
      toast.success('Shipment Booked Successfully', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsBooking(true));
      yield put(setIsBookingDone(false));

      yield fetchAllShipments({
        type: 'GET_ALL_SHIPMENT_LIST',
        payload: fetchAllShipmentsParam,
      });
    } else {
      toast.error(
        bookingDetails?.err?.data
          ? bookingDetails.err.data
          : 'Something Went wrong',
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBooking(false));
      yield put(setIsBookingDone(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* fetchAllShipmentStatus(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getShipmentUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_SHIPMENT_URL}`;
  const { postData } = payload;
  yield put(setShipmentListLoading(true));
  try {
    const shipmentDetails = yield call(httpCall, {
      url: getShipmentUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (shipmentDetails?.success) {
      yield put(setShipmentList(shipmentDetails.data));
      yield put(setShipmentListLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setShipmentListLoading(false));
  }
}

export function* DeletePickupDataFromTable(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const DeletePickupDataURL = `${process.env.REACT_APP_BASEURL}${HANDLE_DELETE_IN_PICKUPTABLE_URL}`;
  const { postData, allFetchLocationParam } = payload;

  try {
    const Getdelete = yield call(httpCall, {
      url: DeletePickupDataURL,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (Getdelete?.success) {
      toast.success(`Delete Successfully`, {
        closeOnClick: true,
        draggable: true,
        theme: 'colored',
      });
      yield put(setPickupDeleteRow(Getdelete.data));
      yield fetchPickupLocation({
        type: 'GETALL_PICKUP_LOCATION',
        payload: allFetchLocationParam,
      });
    }
  } catch (err) {
    toast.error(`Something Went Wrong`, {
      closeOnClick: true,
      draggable: true,
      theme: 'colored',
    });
  }
}

export function* editPickupFormdata(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const EditPickupDataURL = `${process.env.REACT_APP_BASEURL}${EDIT_PICKUP_FORMDATA_URL}`;
  const { postData } = payload;

  try {
    const GetEdit = yield call(httpCall, {
      url: EditPickupDataURL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (GetEdit?.success) {
      yield put(setIsPickupEdit(GetEdit.data)); //function call //yield call(function name,parameter)
      toast.success(`Update Successfully`, {
        closeOnClick: true,
        draggable: true,
        theme: 'colored',
      });
    }
  } catch (err) {
    toast.error('Something Went Wrong', {
      closeOnClick: true,
      draggable: true,
      theme: 'colored',
    });
  }
}

export function* getAwbStatus(payload) {
  yield put(setIsStatusLoading(true));
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAwbStatusURL = `${process.env.REACT_APP_BASEURL}${GET_AWB_STATUS_URL}`;
  const { postData } = payload;
  try {
    const getStatus = yield call(httpCall, {
      url: getAwbStatusURL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', //json
        Authorization: token,
      },
      data: postData,
    });
    if (getStatus?.success) {
      yield put(setShipmentStatus(getStatus?.data));
      yield put(setIsStatusLoading(false));
    } else {
      yield put(setShipmentStatus(getStatus?.err?.data));
      yield put(setIsStatusLoading(false));
      toast.error(getStatus?.err?.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    yield put(setIsStatusLoading(false));
    console.log(err);
  }
}

export function* cancelShipment(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const cancelShipmentURL = `${process.env.REACT_APP_BASEURL}${CANCEL_SHIPMENT_URL}`;
  const { postData } = payload;
  try {
    const cancelResponse = yield call(httpCall, {
      url: cancelShipmentURL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', //json
        Authorization: token,
      },
      data: postData,
    });
    if (cancelResponse?.success) {
      let today = format(new Date(), 'yyyy-MM-dd');
      const lastWeek = sub(new Date(), {
        weeks: 1,
      });
      const shipmentParams = {
        customerId: postData.customerId,
        startDate: format(lastWeek, 'yyyy-MM-dd'),
        endDate: today,
      };
      yield fetchAllShipmentStatus({
        type: 'ALL_SHIPMENT_STATUS',
        postData: shipmentParams,
      });
      const params = {
        id: postData.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        payload: params,
      });

      toast.success(
        `${postData.awbNumber} Shipment Marked As Canceled Successfully`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
    } else {
      toast.error(
        `Unable to process your request. please try after some time or contact admin`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
    }
  } catch (err) {
    yield put(setIsStatusLoading(false));
    console.log(err);
  }
}
export function* DeliveryPincodeData(params) {
  yield put(setIsPincodeDataLoading(true));
  const { postData } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getPincodeDataUrl = `${process.env.REACT_APP_BASEURL}${GET_PINCODE_DATA_URL}/${postData}`;
  try {
    const pincodeDetails = yield call(httpCall, {
      url: getPincodeDataUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (pincodeDetails?.success) {
      const { state, city, coverage } = pincodeDetails.data;
      const pincodeData = { state, city, coverage };
      yield put(setPincodeData(pincodeData));
      yield put(setIsPincodeDataLoading(false));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsPincodeDataLoading(false));
    }
  } catch (err) {
    yield put(setIsPincodeDataLoading(true));
    console.log(err);
  }
}
export function* PickupPincodeData(params) {
  const { postData } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getPickupPincodeDataUrl = `${process.env.REACT_APP_BASEURL}${GET_PINCODE_DATA_URL}/${postData}`;
  try {
    const PickupPincodeDetails = yield call(httpCall, {
      url: getPickupPincodeDataUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (PickupPincodeDetails?.data && PickupPincodeDetails?.data?.coverage) {
      const { coverage } = PickupPincodeDetails.data;
      yield put(setCoverage(coverage));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* watchBookingDetialsSaga() {
  yield takeLatest('GETALL_PICKUP_LOCATION', fetchPickupLocation);
  yield takeLatest('ADD_PICKUP_LOCATION', addPickupLocation);
  yield takeLatest('DOWNLOAD_BOOKING_SAMPLE', downloadBookingSampleFile);
  yield takeLatest('UPLOAD_BULK_BOOKING_FILE', uploadBulkBookingFile);
  yield takeLatest('SINGLE_SHIPMENT_BOOKING', singleShipmentBooking);
  yield takeLatest('ALL_SHIPMENT_STATUS', fetchAllShipmentStatus);
  yield takeLatest('HANDLE_DELETE', DeletePickupDataFromTable);
  yield takeLatest('EDIT_PICKUP_FORMDATA', editPickupFormdata);
  yield takeLatest('GET_AWB_STATUS', getAwbStatus);
  yield takeLatest('CANCEL_SHIPMENT', cancelShipment);
  yield takeLatest('GET_PINCODE_DATA', DeliveryPincodeData);
  yield takeLatest('GET_COVERAGE_DATA', PickupPincodeData);
}

export default function* BookingDetailsSaga() {
  yield all([watchBookingDetialsSaga()]);
}
