import React, { useState } from 'react';
import { Select } from '@chakra-ui/react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { CustomerContainerData } from '../../utils/customerDetails.Constant';
import Card from '../../components/card';
const CustomerContainer = () => {
  const [selectedValue, setSelectedValue] = useState('Personal Details');
  return (
    <>
      <Select
        bg="blue.500"
        borderColor="blue.500"
        color="white"
        defaultValue={selectedValue}
        icon={<IoMdArrowDropdown />}
        w={[200]}
        onChange={e => setSelectedValue(e.target.value)}
        textTransform={'capitalize'}
        mt={2}
      >
        {CustomerContainerData.map(item => (
          <option
            style={{ color: 'black' }}
            key={item.label}
            value={item?.label}
          >
            {item?.label}
          </option>
        ))}
      </Select>
      <Card h={'75vh'} overflowY={'auto'} pb={5}>
        {CustomerContainerData.map(item => {
          if (selectedValue === item.label) {
            return (
              <React.Fragment key={item.label}>
                {item?.component}
              </React.Fragment>
            );
          }
        })}
      </Card>
    </>
  );
};

export default CustomerContainer;
