import React from 'react';
import { MdEmail, MdLocationCity, MdPhone } from 'react-icons/md';
import { Box, Text, Flex, Icon } from '@chakra-ui/react';

const CustomerAddress = ({ row }) => {

  return (
    <Box>
      <Text color="blue">
        Name:{' '}
        <Text as="span" color="black">
          {row?.deliveryAddress_name || 'Not available'}
        </Text>
      </Text>

      <Box color="blue">
        Address:{' '}
        <Text as="span" color="black">
          {row?.deliveryAddress_add1}, {row?.deliveryAddress_add2},
          <Text as="span" color="black">
            {row?.deliveryAddress_lmk}
          </Text>
          <Text as="span" color="black">
            {row?.deliveryAddress_state}: {row?.deliveryAddress_pincode}
          </Text>
        </Text>
      </Box>

      <Flex alignItems="center">
        <Icon as={MdLocationCity} boxSize={6} color="blue" />
        <Text fontSize="1rem" color="black">
          : {row?.address_city || 'Not available'}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Icon as={MdEmail} boxSize={6} color="blue" />
        <Text fontSize="1rem" color="black">
          : {row?.deliveryAddress_email || 'Not available'}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Icon as={MdPhone} boxSize={6} color="blue" />
        <Text fontSize="1rem" color="black">
          : {row?.deliveryAddress_mobile || 'Not available'}
        </Text>
      </Flex>
    </Box>
  );
};

export default CustomerAddress;
