import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import AddCourierDrawer from './AddCourierDrawer';
import { getCourierEdit } from '../../../../redux/slices/ComponentFunctionSlice';
import { useSelector } from 'react-redux';
import {
  Zone,
  defautShipmentPartnerValue,
} from '../../../../utils/utilityConstants';
const ZoneName = ({ row }) => {
  const label = Zone.find(item => item.value === Object.keys(row)[0])?.label;
  return <Text textTransform="capitalize">{label}</Text>;
};
const ShipmentPartner = ({ row, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isEdit = useSelector(getCourierEdit);
  const courier = defautShipmentPartnerValue.find(
    item => item.value === row?.[Object.keys(row)[0]]?.[type]?.courier
  )?.value;
  const serviceType = row?.[Object.keys(row)[0]]?.[type]?.serviceType;
  const defaultFormValue = {
    zone: Object.keys(row)[0],
    priorityType: type,
    serviceType: serviceType,
    serviceProvider: courier,
  };

  return (
    <Flex w={28} mx={'auto'} justifyContent={'center'} gap={2}>
      {courier ? (
        <Box>
          <Text textTransform="capitalize">{courier}</Text>
          <Text textTransform="capitalize" color="green.500">
            {serviceType}
          </Text>
        </Box>
      ) : (
        <Tooltip label="Data Not Set Yet" placement="bottom" minW="100px">
          <Text fontWeight={'extrabold'} fontSize={20}>
            ...
          </Text>
        </Tooltip>
      )}

      {isEdit && courier ? (
        <IconButton
          colorScheme="blue"
          variant="outline"
          boxSize={8}
          icon={<MdEdit />}
          onClick={onOpen}
        />
      ) : null}

      <AddCourierDrawer
        defaultFormValue={defaultFormValue}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export const Default_Shipment_PartnerList_Column = () => {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('zone', {
      cell: info => <ZoneName row={info.row.original} />,
      id: 'zone',
      header: 'Zone',
    }),
    columnHelper.accessor('default', {
      cell: info => <ShipmentPartner row={info.row.original} type="default" />,
      id: 'Default',
      header: 'Default',
    }),
    columnHelper.accessor('primary', {
      cell: info => <ShipmentPartner row={info.row.original} type="primary" />,
      id: 'Primary',
      header: 'Primary',
    }),
    columnHelper.accessor('secondary', {
      cell: info => (
        <ShipmentPartner row={info.row.original} type="secondary" />
      ),
      id: 'Secondary',
      header: 'Secondary',
    }),
  ];
};
