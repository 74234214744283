import React, { useEffect, useState } from 'react';
import { getCalculatedPrice } from '../redux/slices/ShipmentDetailsSlice';
import { useSelector } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import B2bBookingCards from '../containers/Calculator/B2bBookingCards';
import Card from '../../src/components/card';
import { isEmpty } from '../utils/utilsFunction';
import NoDataAvailable from '../components/Loader/NoDataAvailable';
const B2bCalculatePage = () => {
  const calculatedPrice = useSelector(getCalculatedPrice);
  const [lists, setLists] = useState({ normal: [], business: [], gst: [] });
  useEffect(() => {
    if (calculatedPrice) {
      const newLists = Object.entries(calculatedPrice).reduce(
        (acc, [key, value]) => {
          const priceList = value?.priceList || [];
          if (priceList.length > 0) {
            return {
              ...acc,
              [key]: priceList,
            };
          }
          return acc;
        },
        {}
      );

      setLists(newLists);
    }
  }, [calculatedPrice]);

  return (
    <Card overflowY={['none', 'none', 'auto']} pb={5}>
      {isEmpty(lists) ? (
        <NoDataAvailable />
      ) : (
        <Flex flexDir={'column'} alignItems={'center'} gap={5}>
          {Object.entries(lists).map(([category, items]) => (
            <React.Fragment key={category}>
              <Text fontSize={28} fontWeight={'bold'} color={'blue.500'}>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </Text>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                flexWrap={'wrap'}
                gap={4}
              >
                {items.length > 0 &&
                  items.map((item, index) => (
                    <B2bBookingCards key={index} data={item} />
                  ))}
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      )}
    </Card>
  );
};

export default B2bCalculatePage;
