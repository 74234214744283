import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { MdNotifications } from 'react-icons/md';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Input,
  Icon,
  IconButton,
  Spacer,
  Text,
  Menu,
  MenuItem,
  MenuDivider,
  MenuButton,
  useMediaQuery,
  MenuList,
  VStack,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR } from '../../utils/constant';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import Calculatedrawer from '../../containers/Calculator/CalculatorDrawer';
import useDebounce from '../../utils/Debaunce';
import { useDispatch, useSelector } from 'react-redux';
import QuixgoText from '../card/shared/QuixgoText';
export default function Navbar({ onOpen, btnref }) {
  const [inputValue, setAwbNumber] = useState('');
  const debouncedAwbNumberValue = useDebounce(inputValue, 1000);
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const navigate = useNavigate();
  const [isLessThan768] = useMediaQuery('(max-width: 768px)');
  const handleLogout = () => {
    sessionStorage.clear();
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };
  useEffect(() => {
    if (debouncedAwbNumberValue?.length > 7) {
      navigate(`/status/${debouncedAwbNumberValue.trim()}`, { replace: true });
    }
  }, [debouncedAwbNumberValue]);

  return (
    <Flex
      py={[0, 0, 2]}
      width={'100%'}
      position="fixed"
      top="0"
      zIndex="10"
      bg={COLOR.blue}
      flexDir={'column'}
    >
      <Flex alignItems="center">
        {isLessThan768 && (
          <IconButton
            ref={btnref}
            variant="unstyled"
            aria-label="notifications"
            mx={3}
            color="white"
            icon={<Icon as={FiMenu} w={6} h={6} />}
            onClick={onOpen}
          />
        )}
        <QuixgoText as={'h2'} ml={5} fontSize={'3xl'} />
        <Spacer />
        <Flex h={'100%'}>
          <Flex justifyContent={'flex-end'} alignItems={'center'} mr={2}>
            <Flex justifyContent={'flex-end'} alignItems={'center'}>
              {!isLessThan768 && (
                <>
                  <InputGroup minW="20vw">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={AiOutlineSearch}></Icon>
                    </InputLeftElement>
                    <Input
                      type="tel"
                      placeholder="Search AWB..."
                      bg="white"
                      value={inputValue}
                      onChange={e => setAwbNumber(e.target.value)}
                    />
                  </InputGroup>
                </>
              )}
            </Flex>
          </Flex>
          <HStack spacing={4} pr="20px">
            <Calculatedrawer />
            {!isLessThan768 && (
              <IconButton
                variant="unstyled"
                aria-label="notifications"
                icon={<Icon as={MdNotifications} w={8} h={8} color="white" />}
              />
            )}

            <HStack spacing={{ base: '0', md: '6' }}>
              <Flex alignItems={'center'}>
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}
                  >
                    <HStack>
                      <Avatar
                        size={'sm'}
                        name={`${loggedUser.firstName} ${loggedUser.lastName}`}
                      />
                      <VStack
                        display={{ base: 'none', md: 'flex' }}
                        alignItems="flex-start"
                        spacing="1px"
                        color="white"
                        ml="2"
                      >
                        <Text
                          fontSize="sm"
                          color="white"
                        >{`${loggedUser.firstName} ${loggedUser.lastName}`}</Text>
                      </VStack>
                      <Box display={{ base: 'none', md: 'flex' }}>
                        <FiChevronDown />
                      </Box>
                    </HStack>
                  </MenuButton>
                  <MenuList bg={COLOR.blue} borderColor={COLOR.blue}>
                    <MenuDivider />
                    <MenuItem onClick={() => handleLogout()}>Sign out</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </HStack>
        </Flex>
      </Flex>
      {isLessThan768 && (
        <>
          <InputGroup minW="20vw">
            <InputLeftElement pointerEvents="none">
              <Icon as={AiOutlineSearch}></Icon>
            </InputLeftElement>
            <Input
              roundedBottom={0}
              type="tel"
              placeholder="Search AWB..."
              bg="white"
              value={inputValue}
              onChange={e => setAwbNumber(e.target.value)}
            />
          </InputGroup>
        </>
      )}
    </Flex>
  );
}
