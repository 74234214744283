import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import QuixRoutes from './routes/index.routes';
import theme from './theme/index.theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <QuixRoutes />
      <ToastContainer closeButton={false} position="top-right" />
    </ChakraProvider>
  );
}

export default App;
