import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  getRemittanceDueList,
  getShipmentLoader,
} from '../../../redux/slices/ShipmentDetailsSlice';
import TableLoader from '../../../components/Loader/TableLoader';
import { REMITTANCE_COLUMNS } from './RemittanceDueColumn';
import NoDataAvailable from '../../../components/Loader/NoDataAvailable';
import RemittanceList from './RemittanceList';
const Remittance = ({ hasMultiSelect, TableHeight, excelFilename }) => {
  const remittanceDueList = useSelector(getRemittanceDueList);
  const shipmentLoader = useSelector(getShipmentLoader);
  return (
    <Box>
      {shipmentLoader ? (
        <TableLoader />
      ) : remittanceDueList.length > 0 ? (
        <RemittanceList
          isMultiSelect={hasMultiSelect}
          column={REMITTANCE_COLUMNS}
          data={[...remittanceDueList]}
          name={'rem'}
          excel={true}
          TableHeight={TableHeight}
          excelFilename={excelFilename}
        />
      ) : (
        <NoDataAvailable />
      )}
    </Box>
  );
};
export default Remittance;
