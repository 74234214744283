import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import ReactTable from '../../../components/ReactTable/tablev8';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedShipmentInfo,
  getSelectedShipmentInfo,
} from '../../../redux/slices/ShipmentDetailsSlice';
import {
  getBankDetails,
  getIsBankingInfoLoading,
  getQuixgoBankDetails,
} from '../../../redux/slices/MerchantDetailsSlice';
import { AiOutlinePrinter } from 'react-icons/ai';
import PayBillModel from './../Manifest/PayBillModel';
import { getCustomerSetting } from '../../../redux/slices/CustomerSettingSlice';
import { setSelectedShipmentIds } from '../../../redux/slices/ComponentFunctionSlice';
const RemittanceList = ({
  TableHeight,
  column,
  data,
  isMultiSelect,
  name,
  excelFilename,
}) => {
  const dispatch = useDispatch();
  const selectedShipment = useSelector(getSelectedShipmentInfo);
  const [codAmount, setCodAmount] = useState();
  const onSelect = rows => {
    let total = 0;
    const shipmentIds = [];
    rows.forEach(row => {
      let codAmount = JSON.parse(row.productDetails);
      total += Number(codAmount.cod ? codAmount.cod : 0);
      shipmentIds.push(row.shipmentId);
    });
    setCodAmount(total.toFixed(2));
    dispatch(setSelectedShipmentIds(shipmentIds));
    dispatch(setSelectedShipmentInfo(rows));
  };

  const {
    isOpen: isManifestModelOpen,
    onOpen: handleManifestOpenModel,
    onClose: handleManifestClose,
  } = useDisclosure();
  const handleManifestOpen = () => {
    const customerId = selectedShipment?.[0]?.customerId;
    dispatch({
      type: 'GET_BANKING_DETAILS',
      postData: {
        customerId: customerId,
        accountBelonging: 'customer',
      },
    });
    handleManifestOpenModel();
  };
  return (
    <Box pos={'relative'}>
      <Flex
        pos={['relative', 'relative', 'relative', 'absolute']}
        justifyContent={'center'}
      >
        <>
          {isMultiSelect && (
            <Button
              leftIcon={<AiOutlinePrinter />}
              isDisabled={selectedShipment?.length === 0}
              variant="solid"
              colorScheme="blue"
              onClick={handleManifestOpen}
            >
              Pay Bill
            </Button>
          )}
        </>
        <Text float={'right'} fontSize={20} ml={10} fontWeight="bold">
          {name === 'rem' ? codAmount : ''}
        </Text>
      </Flex>
      <>
        <ReactTable
          TableUtility={{
            search: true,
            pagination: true,
            excel: true,
            h: TableHeight,
            excelFilename: excelFilename,
          }}
          data={data}
          columns={column()}
          onSelect={onSelect}
        />
      </>
      {isManifestModelOpen && (
        <PayBillModel
          isOpen={isManifestModelOpen}
          onClose={handleManifestClose}
        />
      )}
    </Box>
  );
};

export default RemittanceList;
