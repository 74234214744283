import React from 'react';
import { Text } from '@chakra-ui/react';

const PickupHoverDetails = ({ row }) => {
  return (
    <Text textAlign={'right'}>
      {row?.address1}
      {row?.address2}
    </Text>
  );
};

export default PickupHoverDetails;
