import React from 'react';
import { Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

const ChargeAmount = ({ row }) => {
  const amount =
    row.transactionType === 'cr' ? `+${row.amount}` : `-${row.amount}`;
  const color = row.transactionType === 'cr' ? 'blue' : 'red';
  return <Text color={color}>{amount}</Text>;
};

const Balance = ({ row }) => {
  const balance =
    row.transactionType === 'cr' ? `+${row.balance}` : `-${row.balance}`;
  const color = row.transactionType === 'cr' ? 'blue' : 'red';
  return <Text color={color}>{balance}</Text>;
};

export const MERCHANT_TRANSACTION_COLUMNS = () => {
  const columnHelper = createColumnHelper();
  // console.log(columnHelper)
  return React.useMemo(() => [
    columnHelper.accessor('transactionId', {
      cell: info => info.renderValue(),
      header: 'Id',
    }),
    // columnHelper.accessor('transactionNumber', {
    //   cell: info => info.renderValue(),
    //   header: 'Trans. Number',
    // }),
    columnHelper.accessor('transactionReason', {
      header: 'Reason',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('paymentMode', {
      header: 'Payment Mode',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('ModeDetails', {
      header: () => 'Mode Details',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),

    columnHelper.accessor('transactionType', {
      header: 'Type',
      cell: info => info.renderValue(),
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      cell: info => <ChargeAmount row={info.row.original} />,
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('balance', {
      header: 'Balance',
      cell: info => <Balance row={info.row.original} />,
      // footer: info => info.column.id,
    }),
    columnHelper.accessor('transactionDate', {
      header: 'Date',
      cell: info => info.renderValue(),
    }),
  ]);
};
