import { Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const QuixgoText = ({ ...props }) => {
  return (
    <Link to={'/'}>
      <Text as="h1" color="white" {...props}>
        <Text color="orange.300" as="span">
          QU
        </Text>
        <Text as="span">IX</Text>
        <Text as="span" color="green.400">
          GO
        </Text>
      </Text>
    </Link>
  );
};

export default QuixgoText;
