import React from 'react';
import {
  getMerchantLoader,
  getMerchants,
} from '../../redux/slices/MerchantDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import NoDataAvailable from '../../components/Loader/NoDataAvailable';
import CustomTable from '../../components/CustomTable';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import {
  All_MerchantList_Filter_Component,
  TableBody,
  TableHeader,
} from './AllMerchant_Constant';
import Pagination from '../../components/CustomTable/Pagination';
import { Flex, VStack } from '@chakra-ui/react';
import FilterContainer from '../../components/CustomTable/FilterContainer';
const AllMerchantList = () => {
  const dispatch = useDispatch();
  const merchantList = useSelector(getMerchants);
  const loggedUser = useSelector(getLoggedUser);
  const isMerchantLoading = useSelector(getMerchantLoader);
  const getData = (limit, page, filterFormData) => {
    dispatch({
      type: 'GET_ALL_CUSTOMERS',
      postData: {
        empId: loggedUser.empid,
        limit: limit,
        page: page,
        filter: filterFormData,
      },
    });
  };
  return (
    <VStack mx={'auto'}>
      <FilterContainer
        mainComponent={All_MerchantList_Filter_Component}
        styleprops={{
          templateColumns: [
            'auto',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
          ],
        }}
      />
      <Flex w={'100%'} justifyContent={'flex-end'}>
        <Pagination
          paginationProps={{ count: merchantList?.count, getData: getData }}
        />
      </Flex>
      {merchantList?.rows?.length > 0 ? (
        <CustomTable
          tableData={{
            PropsData: merchantList?.rows,
            Header: TableHeader,
            Body: TableBody,
            isDataLoading: isMerchantLoading,
          }}
          styleprops={{
            w: '90vw',
            h: ['90vh', '50vh'],
            overflowY: 'auto',
          }}
        />
      ) : (
        <NoDataAvailable />
      )}
    </VStack>
  );
};

export default AllMerchantList;
