import {
  SimpleGrid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  Button,
  Image,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getLoggedUser } from '../../../redux/slices/UserDetailsSlice';
import {
  getCustomerInfo,
  getBankDetails,
  getQuixgoBankDetails,
  getIsBankingInfoLoading,
} from '../../../redux/slices/MerchantDetailsSlice';
import { getCustomerSetting } from '../../../redux/slices/CustomerSettingSlice';
import { dateCalc } from '../../../utils/utilsFunction';
import { useState } from 'react';
import TableLoader from '../../../components/Loader/TableLoader';
import { getSelectedShipmentIds } from '../../../redux/slices/ComponentFunctionSlice';
const PayBillForm = ({ close }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const customerInfo = useSelector(getCustomerInfo);
  const quixgoBank = useSelector(getQuixgoBankDetails);
  const customerBankDetails = useSelector(getBankDetails);
  const IsBankingInfoLoading = useSelector(getIsBankingInfoLoading);
  const customerSetting = useSelector(getCustomerSetting);
  const [defaultBankAcNo, setDefaultBankAcNo] = useState();
  const shipmentIds = useSelector(getSelectedShipmentIds);
  useEffect(() => {
    if (
      customerSetting?.bankAccount !== null &&
      customerSetting?.bankAccount !== undefined
    ) {
      const BankAcc =
        Array.isArray(customerBankDetails) &&
        customerBankDetails?.find(
          item => item?.accountNumber === customerSetting?.bankAccount
        );
      setDefaultBankAcNo(BankAcc);
    } else {
      setDefaultBankAcNo(customerBankDetails?.[0]);
    }
  }, [customerSetting, customerBankDetails]);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(values) {
    const params = {
      accountNumberTo: values.customerAccount,
      accountNumberFrom: values.quixAccount,
      amount: values.amount,
      transactionNumber: values.transactionId,
      transactionDate: values.date,
      comments: values.remarks,
      shipmentIds: shipmentIds,
      customerId: customerInfo?.customerId,
      empId: loggedUser?.empid,
    };
    dispatch({
      type: 'PAY_REMITTANCE',
      postData: params,
      dates: {
        startDate: dateCalc?.week,
        endDate: dateCalc?.today,
      },
    });
    close();
  }
  return (
    <>
      {IsBankingInfoLoading ? (
        <TableLoader />
      ) : (
        <>
          <Text as="span" color="black">
            Customer Account Details
          </Text>
          <Image
            id="docksImg"
            w={['100vw', '70vw', '100%']}
            src={`data:image/jpeg;base64,${defaultBankAcNo?.cancelCheck}`}
            alt={'logo'}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={[1]} columnGap={2}>
              <FormControl isInvalid={errors.quixAccount} isReadOnly={true}>
                <FormLabel htmlFor="quixAccount">
                  Quixgo Account Number(From){' '}
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  value={quixgoBank?.[0]?.accountNumber}
                  id="quixAccount"
                  placeholder="Account Number"
                  {...register('quixAccount', {
                    required: 'This is required',
                  })}
                />

                <FormErrorMessage>
                  {errors.quixAccount && errors.quixAccount.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.customerAccount} isReadOnly={true}>
                <FormLabel htmlFor="customerAccount">
                  Customer Account Number(To){' '}
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  value={defaultBankAcNo?.accountNumber}
                  id="customerAccount"
                  placeholder="Account Number"
                  {...register('customerAccount', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.customerAccount && errors.customerAccount.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.transactionId}>
                <FormLabel htmlFor="transactionId">
                  Transaction ID{' '}
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  id="transactionId"
                  placeholder="Transaction Id"
                  {...register('transactionId', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.transactionId && errors.transactionId.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.amount}>
                <FormLabel htmlFor="amount">
                  Amount{' '}
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  id="amount"
                  placeholder="Amount"
                  {...register('amount', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.amount && errors.amount.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.date}>
                <FormLabel htmlFor="date">
                  Date{' '}
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  id="date"
                  type={'datetime-local'}
                  {...register('date', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.date && errors.date.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.remarks}>
                <FormLabel htmlFor="remarks">
                  Remarks{' '}
                  <Text as="span" color="red">
                    *
                  </Text>
                </FormLabel>
                <Input
                  id="remarks"
                  placeholder="Remarks"
                  {...register('remarks', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.remarks && errors.remarks.message}
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <Button colorScheme="blue" isLoading={isSubmitting} type="submit">
              Submit
            </Button>
          </form>
        </>
      )}
    </>
  );
};

export default PayBillForm;
